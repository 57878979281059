@import ../../styles/helpers

.loader
    height: 80vh
    display: flex
    justify-content: center
    align-items: center

.appDetails
    display: flex
    +t
        display: block
    .appOverview
        flex: 0 0 calc(100% - 0px)
        width: calc(100% - 0px)
        padding-right: 0px
        +x
            flex: 0 0 calc(100% - 0px)
            width: calc(100% - 0px)
        +t
            width: 100%
            margin-bottom: 8px
            padding-right: 0

.row
    display: flex
    gap: 10px
    .col
        &:first-child
            z-index: 1
            flex: 0 0 calc(100% - 340px)
            width: calc(100% - 340px)
            +x
                flex: 0 0 calc(100% - 350px)
                width: calc(100% - 350px)
        .card
            &:nth-child(3)
                z-index: 1
                height: 330px
                +w
                    height: 345px
                +x
                    height: 345px
            &:nth-child(4)
                z-index: 1
                height: 330px
                +w
                    height: 345px
                +x
                    height: 345px
        &:nth-child(2)
            z-index: 1
            flex-shrink: 0
            width: 340px
            padding-right: 10px
            +x
                width: 350px
            .card
                &:first-child
                    height: 415px
                &:nth-child(2)
                    height: 442px
                    +w
                        height: 448px !important
                    +x
                        height: 448px !important
        .build
            width: 100%
            gap: 10px
            display: flex
            .card
                &:first-child
                    z-index: 1
                    height: 345px
                    flex: 0 0 calc(100% - 50%)
                    width: calc(100% - 50%)
                    flex: 1
                &:nth-child(2)
                    z-index: 1
                    height: 345px
                    flex-shrink: 0
                    width: 50%
                    flex: 1

.card
    margin-bottom: 10px !important