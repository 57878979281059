.qb-container{
    background: #F4F4F4 !important;
    padding: 0px 0px 10px 0px;
    border-radius: 12px !important;
    .rule-group{
        background-color: #F4F4F4;
        border-radius: 12px !important;
        .ruleGroup-header{
            padding: 10px 20px !important;
            display: flex;
            align-items: center;
            position: relative;
            .ruleGroup-remove{
                // display: none;
                font-size: 18px;
                position: absolute;
                top : 50%;
                right : 2%;
                transform: translateY(-50%);
            }
        }
        .rule-group{
            background-color: #dcdcdc !important;
            border-radius: 12px !important;
            margin : 0px 20px 20px 20px
        }
    }
    .rule{
        position: relative;
        padding: 20px !important;
        // background-color: #F4F4F4 !important;
        .remove{
            font-size: 18px;
            position: absolute;
            top : 50%;
            right : 2%;
            transform: translateY(15%);
        }
    }
}