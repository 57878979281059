@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:last-child
        padding-right: 50px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .itemName
        font-size: $table-content
        width: 120px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        line-height: 16px
    .itemId
       color: $n4 
       font-size: $table-grey-content
    .type
        display: flex
        align-items: center
        .stackable
            padding: 0px 5px
            background: $IAP-badge-bg
            color: $IAP-badge-color
            font-size: 13px
            line-height: 22px
            font-weight: 600
            text-align: center
            border-radius: 4px
            width: 120px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1

    .preview
        display: flex
        width: $icon-width
        height: $icon-height
        background: #EFEFEF
        margin-right: 0px
        border-radius: 8px
        position: relative
        +dark
            background: rgba(167, 170, 247, 0.15)
        +t
            width: $icon-width
            height: $icon-height
        img
            width: $icon-width
            height: $icon-height
            border: 2px solid #EFEFEF
            border-radius: 12px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            object-fit: cover
    .playerDetails
        display: inline-block

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: visible
    opacity: 1
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px