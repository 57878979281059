@import ../../../../styles/helpers

.personalInfo
    .formContainer
        .form
            position: absolute
            left: 20%
            top: 50%
            transform: translate(-20%, -50%)
            width: 100%
            max-width: 520px
            +x
                left: 20%
                top: 65%
                transform: translate(-20%, -65%)
            .formHeader
                .formTitle
                    font-size: 48px
                    line-height: 48px
                    font-weight: 600
                    color: #1A1D1F
                    .blueText
                        color: #2A85FF
                .formContent
                    font-size: 18px
                    line-height: 24px
                    font-weight: 600
                    color: #6F767E
                    padding: 20px 0px 40px 0px
                    +x
                        padding: 20px 0px
            .formBody
                .group
                    display: flex
                    flex-wrap: wrap
                    margin: 25px -6px -12px
                    +m
                        display: block
                        margin: 0
                    .fields
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
                .single
                    margin: 25px -6px 0px
                    .fields
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
                .rules
                    font-size: 13px
                    .passwordRule
                        .heading
                            margin-top: 5px
                        .ruleGroup
                            margin-top: 5px
                            display: flex
                            align-items: center
                            justify-content: space-between
                            flex-wrap: wrap
                            .rule
                                margin-top: 5px
                                flex: 0 0 calc(50% - 12px)
                                width: calc(50% - 12px)
                                .error
                                    fill: #F44336
                                    margin-right: 5px
                                .success
                                    margin-right: 5px
                                    fill: #4CAF50
                            .ruleFull
                                margin-top: 5px
                                flex: 0 0 calc(100% - 12px)
                                width: calc(100% - 12px)
                                .error
                                    margin-right: 5px
                                    fill: #F44336
                                .success
                                    margin-right: 5px
                                    fill: #4CAF50
                                    
            .formFooter
                display: flex
                align-items: center
                justify-content: space-between
                text-align: right
                margin-top: 30px
                .skip
                    cursor: pointer
                    text-decoration: underline
                    color: #1A1D1F
                .action
                    .Back
                        padding: 10px 24px
                        font-weight: 700
                        background: #fff 
                        color: #1A1D1F
                        border-radius: 8px
                        border: 2px solid #EFEFEF
                        margin-right: 10px
                        &:hover
                            border: 2px solid #1A1D1F
                    .Next
                        padding: 10px 24px
                        font-weight: 700
                        background: #2A85FF 
                        border: 2px solid #2A85FF 
                        color: #fff
                        border-radius: 8px
                        &:hover
                            background: darken($p1, 10)