@import '../../../styles/helpers'

.custom
    cursor: pointer
    position: relative
    width: 100%
    &.active
        .tooltip
            visibility: visible
            opacity: 1
            transform: translate(-50%, 0px)
        .tooltipContainer
            width: 80%
            visibility: visible
            opacity: 1
            transform: translate(-50%, -5px)
    .children
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 12px
        
.tooltipContainer
    z-index: 1
    position: absolute
    overflow: hidden
    left: 50%
    padding: 8px 8px 8px 16px
    border-radius: 8px
    border: 1px solid transparent
    box-shadow: 0px 40px 48px -12px rgba(0, 0, 0, 0.15), 0px 0px 14px -4px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px rgba(0, 0, 0, 0.15)
    background: $n
    visibility: hidden
    opacity: 0
    transition: all 0.3s