@import ../../../styles/helpers

.wrapper
    margin: 0px -12px 24px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: none

.col
    position: relative
    display: table-cell
    padding: 5px 12px
    vertical-align: middle
    +caption1
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 46px
        &:after
            left: 12px
    .id
        margin-top: 5px
        &:after
            right: 12px

.rowContent
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6

.colContent
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 46px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    // &:last-child
    //     padding-right: 164px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none


.control
    display: flex !important
    justify-content: center
    align-items: center !important
    gap: 20px
    .action
        margin: 0px 10px