@import ../../../styles/helpers

.form
    position: relative
    width: 360px

.formSm
    position : relative
    width : 298px

.input
    width: 100%
    height: 48px
    padding: 0 20px 0 44px
    background: $n2
    border-radius: 12px
    +base1-s
    color: $n7
    +dark
        background: $n6
        color: $n1
    &::placeholder
        color: $shades1

.result
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 44px
    padding-right: 4px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.body
    height: 300px
    overflow-y: scroll
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 12px
    background: $n
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgba($n8, .1)
    &.bodyUp
        top: auto
        bottom: calc(100% + 2px)

.options
    position: absolute
    z-index: 99
    overflow-y: scroll
    max-height: 300px
    margin: 5px 0px 0px 0px
    width: 75%
    left: 5%
    right: 0
    padding: 12px
    border-radius: 12px
    background: $n
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1)
    transform: translateY(3px)
    transition: all .2s
    &::-webkit-scrollbar 
        width: 0 !important
        -ms-overflow-style: none
        scrollbar-width: none
    .rewards
        display : flex
        align-items : center
        margin : 0px 0px 10px 0px
        .add
            width: 25px
            margin-right: 10px
            // flex-grow: 1
            .icon
                cursor : pointer
        .rewardsIcon
            // flex-grow: 1
            width: 54px
            height: 54px
            img
                border-radius: 10px
                width: 54px
                height: 54px
        .rewardsDetails
            margin-left: 10px
            // flex-grow: 8
            .rewardName
                color: #1A1D1F
                font-size: 15px
                font-weight: 700
            .rewardId
                font-size: 13px
                color: #9A9FA5
                font-weight: 600
    .divider
        .border
            border: 1px solid #EFEFEF
