@import ../../../styles/helpers

.label
    margin-bottom: 8px
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1

.main
    width : 100%
    .dropdown
        position: relative
        z-index: 3
        &.active
            z-index: 10
            .head
                box-shadow: inset 0 0 0 2px $shades1
                background: $n
                +dark
                    box-shadow: inset 0 0 0 2px $n5
                    background: $n8
                    color: $n1
                &:before
                    transform: rotate(180deg)
            .body
                visibility: visible
                opacity: 1
                transform: translateY(0)
        &.small
            .head
                height: 36px
                padding: 0 36px 0 16px
                line-height: 40px
                &:before
                    width: 40px
                    background-size: 16px auto
            .option
                padding: 2px 16px
        &.disabled
            .head
                border-color: $n3
                opacity: 0.8
                cursor: default
                &:hover
                    box-shadow: inset 0 0 0 2px $n3

.head
    position: relative
    padding: 0 36px 0 8px
    border-radius: 8px
    box-shadow: inset 0 0 0 2px $n3
    font-weight: 600
    color: $n7
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: all .2s
    height: $height_36
    line-height: $line_height_36
    font-size: $font_12
    &:before
        content: ""
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 48px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        transition: transform .2s
    &:hover
        box-shadow: inset 0 0 0 2px $shades1
    +dark
        box-shadow: inset 0 0 0 2px $n6
        background: none
        color: $n4
        &:hover
            box-shadow: inset 0 0 0 2px $n5

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    .pills
        background: #E4E4E4
        color: #33383F
        padding: 4px 6px
        margin: 0px 12px 0px 0px
        border-radius: 8px
        font-size: 12px
        font-weight: 600
    .remove
        margin-left: 8px

.body
    max-height: 300px
    overflow-y: auto
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 8px
    background: $n
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgba($n8, .1)
    &.bodyUp
        top: auto
        bottom: calc(100% + 2px)

.option
    padding: 3px 12px
    font-size: $font_12
    color: $n4
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &:hover
        background: $grey_hover
        color: $n7
        +dark
            color: $n1
    &.selectioned
        color: $p1
        +dark
            color: $p1

.checkbox
    display: block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &.reverse
        .inner
            flex-direction: row-reverse
        .text
            padding: 0 16px 0 0

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0 
    &:checked + .inner 
        .tick
            background: $p1
            border-color: $p1
            &:before
                opacity: 1

.inner
    display: flex
    align-items: center
    &:hover
        .tick
            border-color: $p1
    .optionLabel
        margin-left: 8px  

.tick
    position: relative
    flex-shrink: 0
    width: 16px
    height: 16px
    border-radius: 4px 
    border: 2px solid $shades3
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        width: 14px
        height: 11px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        opacity: 0 
        transition: opacity .2s

.text
    flex-grow: 1
    padding-left: 16px
    font-size: 15px
    line-height: (24/14)
    font-weight: 600
    color: $n7
    +dark
        color: $n1

.placeholder
    color:#6F767E