@import ../../../styles/helpers

.custom
    cursor: pointer
    width: 100%
    font-size: 10px
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            // transform: translate(28px, -50%)

.tooltipContainer
    z-index: 9999
    position: fixed
    // top: 50%
    // left: 0px
    min-width: 50px
    max-width: 150px
    padding: 3px 6px
    border-radius: 8px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    // transform: translate(0px, -50%)
    transition: all 0.2s ease-in
    &:before
        content: ""
        position: absolute
        left: -10px
        top: 50%
        width: 20px
        height: 10px
        transform: translate(0px, -50%) rotate(-90deg)
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    .text
        max-width: 145px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap