
.wrapper
    .container
        .tableWrapper
            max-height: 600px
            overflow: scroll
            padding: 1px 0px
            .table
                width: 2500px
                height: 410px
                .collapsableRow
                    .tabularDataOutput
                        text-align: left
                        border-collapse: separate
                        border: 1px solid #B7B9BB
                        box-shadow: 0px 0px 5px 0px #B7B9BB inset
                        border-radius: 8px
                        .tableHead
                            .heading
                                font-size: 14px
                                font-weight: 700
                                padding: 5px 10px
                                border-left: 1px solid #B7B9BB
                                &:first-child
                                    border-left: none
                        .tableBody
                            .tableRow
                                font-size: 12px
                                font-weight: 400
                                cursor: pointer
                                .tableCol
                                    padding: 5px 10px
                                    border-left: 1px solid #B7B9BB
                                    border-top: 1px solid #B7B9BB
                                    &:first-child
                                        border-left: none
                                        width: 40px
                                    &:nth-child(2)
                                        width: 40px
                                    .pricing
                                        display: flex
                                        align-items: center
                                        justify-content: space-between
                                    .icon
                                        width: 32px
                                        height: 32px
                                        background: #EFEFEF
                                        border-radius: 8px
                                        img
                                            width: 32px
                                            height: 32px
                                            border: 2px solid #EFEFEF
                                            border-radius: 8px
                                    .error
                                        display: flex
                                        align-items: center
                                        justify-content: space-between
                                        .save
                                            color: #2A85FF
                                            font-weight: 600
                                        .input
                                            width: 100%
                                            padding: 5px 10px
                                            border-radius: 4px
                                            background: #F4F4F4
                                        .title
                                            color: #FF6A55
                                            font-weight: 700
                                        .editIcon
                                            position: relative
                                            width: 18px
                                            height: 18px
                                            background: #EFEFEF
                                            border-radius: 8px
                                            .edit
                                                position: absolute
                                                top: 50%
                                                left: 50%
                                                transform: translate(-50%, -50%)
                                .accordianTable
                                    background: rgba(217, 217, 217, 0.3)
                                    box-shadow: 0px 0px 2px 0px #000 inset
                                    .accordianTableHead
                                        .accordianTableHeading
                                            padding: 5px 10px
                                            border-left: 1px solid #B7B9BB 
                                            &:first-child
                                                width: 40px 
                                                border-left: none
                                            &:nth-child(2)
                                                width: 40px
                                                border-left: none
                                    .accordianTableBody
                                        .accordianTableRow
                                            .accordianTableCol
                                                padding: 5px 10px
                                                border-left: 1px solid #B7B9BB
                                                border-top: 1px solid #B7B9BB
                                                &:first-child
                                                    border-top: none
                                                .error
                                                    display: flex
                                                    align-items: center
                                                    justify-content: space-between
                                                    .save
                                                        color: #2A85FF
                                                        font-weight: 600
                                                    .input
                                                        width: 100px
                                                        padding: 5px 10px
                                                        border-radius: 4px
                                                        background: #fff
                                                    .title
                                                        color: #FF6A55
                                                        font-weight: 700
                                                    .editIcon
                                                        position: relative
                                                        width: 18px
                                                        height: 18px
                                                        background: #EFEFEF
                                                        border-radius: 8px
                                                        .edit
                                                            position: absolute
                                                            top: 50%
                                                            left: 50%
                                                            transform: translate(-50%, -50%)
                    .accordionItem
                        font-size: 14px
                        line-height: 24px
                        font-weight: 500
                        .row
                            display: flex
                            align-items: center 
                            flex-wrap: wrap
                            cursor: pointer
                            .col
                                height: 44px
                                border: 1px solid #B7B9BB
                                padding: 5px 20px
                                flex: 0 0 calc(24% - 12px)
                                width: calc(24% - 12px)
                                .preview
                                    display: flex
                                    align-items: center
                                    justify-content: left
                                    gap: 10px
                                    .icon
                                        width: 32px
                                        height: 32px
                                        background: #EFEFEF
                                        border-radius: 8px
                                        img
                                            width: 32px
                                            height: 32px
                                            border: 2px solid #EFEFEF
                                            border-radius: 8px
                                    .name
                                        font-size: 14px
                                        font-weight: 600
                        .accordionContent
                            background: #EFEFEF
                            padding: 8px 0px
                            .accordionTable
                                .row
                                    display: flex
                                    align-items: center 
                                    justify-content: space-between
                                    flex-wrap: wrap
                                    .colHead
                                        padding: 0px 0px 0px 20px
                                        flex: 0 0 calc(12% - 12px)
                                        width: calc(12% - 12px)
                                        font-weight: 500
                                        color: #6F767E
                                        font-size: 8px
                                    .col
                                        padding: 0px 0px 0px 20px
                                        flex: 0 0 calc(12% - 12px)
                                        width: calc(12% - 12px)
                                        font-weight: 500
                                        color: #33383F
                                        font-size: 10px

        .modalFooter
            margin-top: 5px
            display: flex
            justify-content: left