.control
    margin: 32px 0px
    font-size: 12px
    font-weight: 600
    display: flex
    align-items: center
    justify-content: center
    background: #F4F4F4
    padding: 2px 5px
    cursor: pointer
    border-radius: 4px
    width: 56px
    text-align: center
    .addIcon
        cursor: pointer
        margin-left: 8px