@import ../../../styles/helpers

.addContentModal
    padding : 0px 24px
    .addContent
        .modalHeading
            display: flex
            flex: 1
            gap: 10px
            margin: 0px 0px 32px 0px
            font-weight: 600
            font-size: 20px
            line-height: 32px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width : 16px 
                height : 32px
                background : #FFBC99
                border-radius : 4px
        .modalBody
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            .modalContent
                flex: 0 0 calc(100% - 5px)
                width: calc(100% - 5px)
                margin: 0 6px 12px
                .modalSearch
                    .form
                        width: 100%
                .contentBody
                    .wrap
                        overflow: hidden
                    .pagination
                        display : flex
                        align-items : center
                        justify-content : center
                        gap : 25px
                        margin : 20px 0px 0px 0px
                        .icons
                            .previous, .next
                                margin: 0px 5px
                                padding : 5px
                                border : 2px solid #EFEFEF
                                border-radius : 50%
                                cursor : pointer
                    .toggle
                        display: flex
                        flex-wrap: wrap
                        margin-left: auto
                        margin-top: 20px
                        background: #F4F4F4
                        border-radius: 8px
                        padding: 5px
                        .link
                            flex-shrink: 0
                            width: 33%
                            height: 40px
                            border-radius: 8px
                            p
                                transition: fill .2s
                            &:hover
                                p
                                    font-weight: bold

                            &.active
                                background: $n1
                                font-weight: bold
                                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

                            &:not(:last-child)
                                margin-right: 0px

                        .links
                            flex-shrink: 0
                            width: 25%
                            height: 40px
                            border-radius: 8px
                            p
                                transition: fill .2s
                            &:hover
                                p
                                    font-weight: bold

                            &.active
                                background: $n1
                                font-weight: bold
                                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

                            &:not(:last-child)
                                margin-right: 0px


            .modalFilter
                flex: 0 0 calc(25% - 5px)
                width: calc(25% - 5px)
                margin: 0 6px 12px
                .filterContent
                    .filter
                        .filterHead
                            color: var(--neutral-07, #1A1D1F)
                            font-family: Inter
                            font-size: 15px
                            font-style: normal
                            font-weight: 600
                            line-height: 24px
                            letter-spacing: -0.15px
                    .filterType, .filterSubType, .filterTags
                        margin: 15px 0px