@import ../../../styles/helpers

.custom
    position: relative
    display: inline-block
    cursor: pointer
    .tooltip 
        width: 100%
        .eventTooltip
            position: absolute
            z-index: 10 !important
            cursor: pointer !important
            background-color: #272B30
            color: #fff
            padding: 12px 8px 8px 12px
            border-radius: 8px
            white-space: nowrap
            z-index: 9 !important
            top: 100%
            left: 50%
            transform: translateX(-50%)
            opacity: 1
            width: 180px
        .defaultParams
            margin : 5px 0px
            .groupHeading
                margin-bottom: 4px
                .heading
                    color: #EFEFEF
                    font-weight: 500
                    font-size: 12px
                    line-height: 12px
            .defaults
                width: fit-content
                background: #6F767E
                color: #fff
                margin: 0px 0px 7px 0px
                font-weight: 600
                padding: 3px 8px
                font-size: 12px
                line-height: 12px
                border-radius: 4px
            .optional
                width: fit-content
                background: $Real-badge-bg
                color: $Real-badge-color
                margin: 0px 0px 7px 0px
                font-weight: 600
                padding: 3px 8px
                font-size: 12px
                line-height: 12px
                border-radius: 4px
            .specter
                width: fit-content
                background: #FFD88D
                color : #d89e31
                margin: 0px 0px 7px 0px
                font-weight: 600
                padding: 3px 8px
                font-size: 12px
                line-height: 12px
                border-radius: 4px
    .customParams
        margin : 5px 0px
        .groupHeading
            margin-bottom: 4px
            .heading
                color: #EFEFEF
                font-weight: 500
                font-size: 12px
                line-height: 12px
        .customs
            width: fit-content
            background: $IAP-badge-bg
            color: $IAP-badge-color
            margin: 0px 0px 5px 0px
            font-weight: 600
            padding: 3px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px