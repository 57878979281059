@import '../../../../../styles/helpers'

.icon
    display: flex
    width: 100%
    justify-content: right
    .edit
        cursor: pointer
        border: 2px solid #EFEFEF
        border-radius: 8px
        padding: 2px 5px 4px

.overview
    .group
        display: flex
        flex-wrap: wrap
        align-items: center
        margin: 16px -6px -12px
        +m
            display: block
            margin: 0
        svg
            fill: #6F767E !important
        .field, .fieldRadio
            flex: 0 0 calc(50% - 12px)
            width: calc(50% - 12px)
            margin: 0 6px 12px
            +m
                width: 100%
                margin: 12px 0 0
            .radioLabel
                padding: 20px 0px 0px 0px
                font-size: 14px
                font-weight: 600
                color : #33383F
            .variants
                display: flex
                flex-direction: row
                margin: 8px 0px 12px 0px
                .radio
                    margin: 0px 34px 0px 0px
        .cardAction
            display: flex
            flex-direction: row
            align-items: center
            gap: 10px
            .Save
                padding: 12px 20px
                font-weight: 700
                background: #2A85FF 
                color: #fff
                border-radius: 12px
                width: 76px
                &:disabled
                    opacity: 0.5
                &:hover
                    background: darken($p1, 10)
            .Cancel
                padding: 12px 20px
                font-weight: 700
                background: #FCFCFC 
                color: #1A1D1F
                border-radius: 12px
                width: 91px
                border: 2px solid #EFEFEF
                &:hover
                    box-shadow: 0 0 0 2px #1A1D1F inset

.addAccount
    margin: 32px 0px
    font-size: 14px
    font-weight: 500
    line-height: 24px
    .add
        cursor: pointer
        svg
            margin-left: 2px
            width: 20px
            height: 20px