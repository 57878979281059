
@import ../../../../../../styles/helpers

.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset