@import ../../../../styles/helpers

.product
    background: transparent 
    border-radius: 8px
    cursor: pointer
    +dark
        background: $n6
    &:hover
        .control
            visibility: visible
            opacity: 1


.control
    visibility: hidden

.card
    padding: 10px 12px
    padding: 12px 8px
    border: 2px solid #F4F4F4
    border-radius: 8px
    +x
        padding: 10px 12px
    .createdAt
        color: #6F767E
        font-size: $table-grey-content
        font-weight: 500
        margin-bottom: 5px
        .title
            font-weight: 500

.cardHeader
    display: flex
    justify-content: left
    align-items: center
    gap: 10px
    .itemDetails
        flex: 2
        +x
            flex: 1
            width: 120px
        .itemContent
            .itemName 
                font-style: normal
                font-weight: 600
                font-size: $table-content
                line-height: 16px
                letter-spacing: -0.02em
                margin: 5px 0px 0px 0px
                color: #000
                width: 150px
                .overflow
                    width: 100%
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
    .cardItemImage
        width: 44px
        height: 44px
        img 
            width: 44px
            height: 44px
            border-radius: 8px
            object-fit: cover
            border: 2px solid #EFEFEF
            +dark
                border: 2px solid rgba(167, 170, 247, 0.35)