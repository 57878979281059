@import ../../../styles/helpers

.product
    background : $n
    border-radius : 20px
    cursor : pointer
    &:hover
        .control
            visibility: visible
            opacity: 1
    &.active
        .checkbox
            visibility: visible
            opacity: 1
        .preview
            &:before
                border-color: $p1

.preview:after,
.control,
.checkbox
    opacity: 1
    visibility: visible
    transition: all .2s

.preview
    position: relative
    height: 200px
    margin-bottom: 16px
    border-radius: 12px
    overflow: hidden
    &:after,
    &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transition: all .2s
    &:after
        background: rgba($n8, .8)
    &:before
        z-index: 2
        border: 2px solid transparent
        border-radius: 12px
        pointer-events: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
    .checkbox
        position: absolute
        top: 12px
        left: 12px
        z-index: 3
        +t
            display: none 
        .checkboxTick
            border-color: $p1

.control
    visibility: hidden


.line
    display: flex
    align-items: flex-start

.title
    margin-right: auto

.price,
.empty
    flex-shrink: 0
    display: inline-block
    margin-left: 32px
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        background: #EAFAE4
        color: #83BF6E

.virtual
    color: $Virtual-badge-color
    background: $Virtual-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $Virtual-badge-color
        background: $Virtual-badge-bg

.IAP
    color:  $IAP-badge-color
    background: $IAP-badge-bg 
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    padding: 3px 8px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color:  $IAP-badge-color
        background: $IAP-badge-bg 

.real
    color:  $Real-badge-color
    background: $Real-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color:  $Real-badge-color
        background: $Real-badge-bg


.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.date
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    color: $n4
    +m
        margin-top: 2px
    svg
        margin-right: 8px
        fill: $n4

.rating
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    +m
        margin-top: 4px
    svg
        margin-right: 8px
        fill: #FFC554
    span
        margin-left: 4px
        color: $n4

.ratingEmpty
    color: $n4
    svg
        fill: $n4

.card
    background : #FCFCFC
    border-radius : 20px
    padding : 20px
    +dark
        background: #272B30

.cardHeader
    display : flex
    align-items : center
    gap : 15px
    margin-bottom : 15px


.cardImage
    width : 80px
    height : 80px
    img
        width : 80px
        height : 80px
        border-radius : 24px
        border : 2px solid #EFEFEF
        object-fit: cover


.cardContent
    .title
        margin-bottom : 8px

.cardBody
    padding : 10px 0px


.active
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        background: #EAFAE4
        color: #83BF6E

.archive
    color: #FF6A55
    background: #FFBC99
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $n7

.content
    display : flex
    justify-content : space-between
    margin : 16px 0px

.label
    color: #6F767E
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px

.horizontalBreak
    margin : 10px 0px
    color : $horizontal-Break