@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 46px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            // .control
            //     visibility: visible
            //     opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 12px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:last-child
        padding-right: 164px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .itemName
        width: 200px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        color: #1A1D1F
        line-height: 16px

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .details,
    .price,
    .product,
    .empty,
    .date
        display: none
    .details,
    .price,
    .empty,
    .product
        +t
            margin : 0px 5px 0px 0px
            text-align : left
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1

.preview
    flex-shrink: 0
    width: 90px
    height: 90px
    background : #EFEFEF
    margin-right: 20px
    border-radius: 8px
    // overflow: hidden
    position : relative
    +dark
        background: rgba(167, 170, 247, 0.15)
    +t
        width: 104px
        height: 116px
    img
        width: 90px
        height: 90px
        border: 2px solid #EFEFEF
        border-radius: 8px
        // width : 75px
        // height : auto
        position : absolute
        top : 50%
        left : 50%
        transform : translate(-50%, -50%)

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 44px
        +title2

.link
    +caption1
    color: $shades1
    +t
        display: none

.price,
.empty,
.archive
    flex-shrink: 0
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        background: #EAFAE4
        color: #83BF6E
        
.status
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

.archive
    color: #FF6A55
    background: #FFBC99
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.15px
    border-radius: 4px
    text-align: center
    padding: 2px 4px

.active
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 500
    line-height: 20px
    letter-spacing: -0.15px
    border-radius: 4px
    text-align: center
    padding: 2px 4px

.icons
    display: flex
    gap: 5px
    align-items: center
    justify-content: center
    margin-top: 5px
    .icon
        width: 12px
        height: 12px
        cursor: pointer
        &.disabled
            opacity: 0.6
            pointer-events: none

.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    // visibility: hidden
    // opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px

.number
    color: #6F767E
    font-weight: 500