.address
    display: block
    margin-left: auto
    a
        font-size: 13px
        text-decoration: underline
        color: #2A85FF
        &:visited
            color: #2A85FF
.container
    margin: 32px 0px 0px
    .wrapper
        .action
            padding-top: 5px
        .paymentList
            display: flex
            flex-wrap: wrap
            .payment
                display: flex
                align-items: center
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
                border: 1.5px solid #EFEFEF
                padding: 31px 12px
                border-radius: 12px
                gap: 12px
                position: relative
                cursor: pointer
                &.active
                    border-color: #2A85FF
                    .radio
                        background: #2A85FF
                    .select
                        position: absolute
                        background: #FFFFFF
                        width: 8px
                        height: 8px
                        border-radius: 50%
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                .cardDetail
                    .cardNumber
                        color: #11142D
                        font-size: 16px
                        font-weight: 600
                        line-height: 20px
                    .date
                        color: #808191
                        font-size: 13px
                        font-weight: 600
                        line-height: 18px
                        margin-top: 3px
                .radio
                    position: absolute
                    top: 5px
                    right: 5px
                    background: #E4E4E4
                    width: 16px
                    height: 16px
                    border-radius: 50%
                    