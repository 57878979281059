@import ../../../../styles/helpers

.loading
    margin: 30px 0px
    .loader
        margin: 0px auto            

.createButton
    img 
        margin : 0px 5px 0px 0px

.head
    +t
        margin-bottom: 32px
    +m
        flex-wrap: wrap
        margin-bottom: 24px

.title
    font-size: $card-heading
    margin-right: 24px !important

.form
    width: 220px

.add
    display: flex
    margin-left: auto

.sorting
    display: flex
    margin-left: auto

.link
    flex-shrink: 0
    width: 40px
    height: 40px
    border-radius: 8px
    svg
        fill: $shades1
        transition: fill .2s
    &:hover
        svg
            fill: $n7
            +dark
                fill: $n1
    &.active
        background: $n1
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        svg
            fill: $n7
        +dark
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.05)
            background: $n8
            svg
                fill: $n1
    &:not(:last-child)
        margin-right: 12px

.list
    display: flex
    justify-content : center
    flex-wrap: wrap
    margin: -32px -12px 0
    +a
        display: block
        margin: 0 -8px

.product
    flex: 0 0 calc(33.33% - 24px)
    width: calc(33.33% - 24px)
    margin: 32px 12px 0
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px

.divider
    margin : 150px 0px

.wrapper
    height: 632px
    overflow: hidden
    .seprator
        border-top: 1px solid #EFEFEF
        margin: 16px 0px 10px
    .options
        display: flex
        justify-content: space-between
        align-items: center
        .filters
            display: flex
            align-items: center
            gap: 15px
            .buttons
                padding: 4px 8px
                border: 2px solid #EFEFEF
                border-radius: 8px
                font-size: 12px
                color: #6F767E
                cursor: pointer
                .icon
                    margin-right: 5px
                    fill: #6F767E                
    .toggle
        display: flex
        margin-left: auto
        margin-top: 20px
        background: #F4F4F4
        border-radius: 8px
        padding: 5px
        .link
            flex-shrink: 0
            width: 50%
            height: 40px
            border-radius: 8px
            font-weight: bold
            p
                transition: fill .2s

            &.active
                background: $n1
                font-weight: bold
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

            &:not(:last-child)
                margin-right: 0px

.pagination
    display : flex
    align-items : center
    justify-content : center
    gap : 25px
    margin : 10px 0px 0px 0px
    .icons
        .previous, .next
            margin: 0px 5px
            padding : 5px
            border : 2px solid #EFEFEF
            border-radius : 50%
            cursor : pointer

.msg
    text-align: center
    .alert
        color: #6F767E
        font-size: 15px
        font-weight: 600
        line-height: 24px
        .infoLink
            color: #33383F
            font-size: 15px
            font-weight: 600
            line-height: 24px
            font-size: 15px
            font-weight: 700
            line-height: 24px
            text-decoration: underline
            a
                color: #33383F
                font-size: 15px
                font-weight: 600
                line-height: 24px
                font-size: 15px
                font-weight: 700
                line-height: 24px
                text-decoration: underline