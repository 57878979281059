@import '../../../styles/helpers'

.containerQuickAction
    position: relative
    .quickAction
        .action
            padding: 5px
            &.active
                background: #404040
                border-radius: 5px
                .body
                    visibility: visible
                    opacity: 1
                    transform: translateY(0)
            .bentoMenu
                cursor: pointer
                display: grid
                grid-template-columns: repeat(3, 1fr)
                grid-template-rows: repeat(3, 1fr)
                grid-column-gap: 2px
                grid-row-gap: 2px
                height: 18px
                width: 18px
                place-items: center
                .bentoDot
                    width: 4px
                    height: 4px
                    border-radius: 1px
                    background:#fff
                    overflow: hidden

.body
    display: flex
    flex-wrap: nowrap
    position: absolute
    top: calc(100% + 10px)
    right: -10px
    width: 230px
    padding: 16px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-5)
    transition: all .2s
    +x
        top: calc(100% + 10px)
        right: -10px
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    // &:before
    //     content: ""
    //     position: absolute
    //     right: 33px
    //     bottom: 100%
    //     width: 20px
    //     height: 10px
    //     background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    //     +x
    //         right: 13px
    //     +m
    //         right: 21px

.menu
    display: flex
    flex-wrap: wrap
    text-align: center
    .options
        flex: 0 0 calc(33.33% - 10px)
        width: calc(33.33% - 10px)
        margin: 0px 10px 15px 0px
        &:hover
            .icon
                background: rgba(249, 198, 38, 0.7)
        .icon
            display: block
            margin: 0px auto
            width: 40px
            height: 40px
            border: 2px solid #F4F4F4
            border-radius: 8px
            position: relative
            svg
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                width: 16px
                height: 16px
        .title
            margin-top: 5px
            font-size: 10px
            font-weight: 500
            color: #6F767E


.item
    display: flex
    align-items: center
    width: 100%
    height: 48px
    padding: 0 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n2
        +dark
            background: transparent
    &.color
        color: $p4
        svg
            fill: $p4
        +dark
            color: $p4
            svg
                fill: $p4
        &:hover
            opacity: .8