@import ../../styles/helpers

.warningHeader
    position: fixed
    top: 100px
    width: 330px
    left: 50%
    z-index: 10
    // display: flex
    // align-items: center
    // justify-content: center
    // left: 340px
    // right: 0
    // box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    font-size: 13px
    +x
        top: 90px
        width: 330px
        left: 50%
    +d
        top: 90px
        width: 330px
        left: 50%
    +m
        top: 90px
        width: 330px
        left: 50%
    .view
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background: #FCFCFC
        color: #33383F
        // background: #F5e050
        // color: #33383F
        padding: 5px 15px
        border-radius: 20px
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
        .warning
            font-size: 13px
            font-weight: 600
            line-height: 18px
            &.active
                margin: 0px auto
        .icon
            background: #2A85FF
            border-radius: 50%
            text-align: center
            cursor: pointer
            width: 24px
            height: 24px
            svg
                fill: #fff
    .edit
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        background: #FCFCFC
        color: #33383F
        // background: #F5e050
        // color: #33383F
        padding: 5px 15px
        border-radius: 20px
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
        .warning
            font-size: 13px
            font-weight: 600
            line-height: 18px
        .icon
            background: #2A85FF
            border-radius: 50%
            text-align: center
            cursor: pointer
            width: 24px
            height: 24px
            svg
                fill: #fff