@import '../../../../../styles/helpers'

.wrapper
    padding: 10px 32px 0px 32px

.actionButtons
    display: flex
    gap: 12px
    margin-top: 10px
    
.groups
    margin: 0px 0px 0px 0px
    .playerInformation
        .preview
            .primaryInfo
                display: flex
                gap: 20px
                align-items: center
                .profile
                    position: relative
                    width: 80px
                    height: 80px
                    border-radius: 50%
                    img
                        object-fit: cover
                        width: 80px
                        height: 80px
                        border-radius: 50%
                    .edit
                        position: absolute
                        bottom: 5px
                        right: 5px
                        background: #2A85ff
                        width: 16px
                        height: 16px
                        border-radius: 50%
                        cursor: pointer
                        svg
                            fill: #fff
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)
                .otherInfo
                    flex-grow: 1
                    .orgName
                        .userName
                            font-size: 24px
                            font-weight: 600
                            line-height: 28px
                            letter-spacing: -0.03em
                            display: flex
                            align-items: center
                    .secondaryInfo
                        display: flex
                        justify-content: space-between
                        .playerId
                            flex-grow: 3
                            .ids
                                .label
                                    color: #1A1D1F
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 16px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color: #6F767E
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 16px
                                    letter-spacing: -0.01em
                                    .copyToClipboard
                                        cursor: pointer
                                        margin-left: 5px
                        .playerLocation
                            flex-grow: 1
                            .ids
                                .label
                                    color: #1A1D1F
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 16px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color: #6F767E
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 16px
                                    letter-spacing: -0.01em

.preview
    .previewImage
        position: relative
        margin: 0px auto
        width: 100px
        height: 100px
        border-radius: 16px
        img
            width: 100px
            height: 100px
            border-radius: 16px
            object-fit: cover
        .edit
            position: absolute
            bottom: -5px
            right: -5px
            background: #2A85ff
            width: 20px
            height: 20px
            border-radius: 50%
            cursor: pointer
            svg
                fill: #fff
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
    .actions
        display: flex
        justify-content: center
        gap: 8px
        margin-top: 16px

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important