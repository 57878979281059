.snapShot
    border-radius: 8px
    position: relative
    width: 100%
    height: 100%
    .images
        display: none
        border: 2px solid #EFEFEF
        border-radius: 8px
        &.active
            display: block