@import '../../styles/helpers'

.emptyStateContainer
    height: 500px
    .emptyStateContainerInner
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        text-align: center
        .emptyStateSvg
            margin: 20px 0px 0px 0px
            svg
                width: 300px !important
                height: 300px !important
        .emptyStateContent
            margin: 20px 0px 0px 0px
            .heading
                font-size: 18px
                color: #1A1D1F
                font-weight: 600
                line-height: 24px
                letter-spacing: -0.4px
            .content
                font-size: 13px
                color: #6F767E
                font-weight: 500
                line-height: 16px
                letter-spacing: -0.15px
                margin-top: 5px
        .emptyStateButtonGroup
            margin: 20px 0px 0px 0px
            display: flex
            align-items: center
            gap: 10px
            .createButton
                .addIcon
                    margin: 0px 5px 1px 0px