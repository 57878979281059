@import ../../../../styles/helpers

.scheduleModal
    .scheduleContainer
        .modalHeader
            .modalHeading
                display: flex
                justify-content: center
                flex: 1
                gap: 10px
                margin: 0px 0px 10px 0px
                font-weight: 600
                font-size: 20px
                line-height: 32px
                letter-spacing: -0.02em
                color: #1A1D1F
        .modalBody
            margin: 0px 10px
            .warningContent
                font-weight: 500
                font-size: 15px
                line-height: 24px
                letter-spacing: -0.02em
                color: #6F767E
                margin-top: 15px
            .name
                font-weight: 600
                color: #1A1D1F
        .modalFooter
            display: flex
            align-items: center
            gap: 10px
            margin: 20px 0px 0px 0px
            .fields
                width: calc( 50% - 8px )
                flex: calc( 50% - 8px )

.eventWarning
    text-align: center
    font-weight: 500
    font-size: 14px
    line-height: 20px
    letter-spacing: -0.02em
    color: #6F767E
    margin-top: 10px