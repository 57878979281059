.custom
    // position: relative
    display: inline-block
    cursor: pointer
    margin-bottom: 3px
    .tooltip 
        min-width: 100px
        max-width: 300px
        position: absolute
        background-color: #272B30
        color: #fff
        padding: 12px 8px 8px 12px
        border-radius: 8px
        white-space: nowrap
        z-index: 999
        font-size: 12px !important
        // top: 100%
        // left: 50%
        // transform: translateX(-50%)
        opacity: 0.9
        pointer-events: none
        overflow-x: hidden
        .groupHeading
            margin-bottom: 4px
            .heading
                color: #EFEFEF
                font-weight: 500
                font-size: 12px
                line-height: 12px
        .rewardsCurrency
            display: inline-block
            margin: 0px 0px 5px 0px
            background: #B1E5FC
            font-weight: 600
            padding: 3px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px
            .currencyContent
                color : #2A85FF
                font-weight: 600
                font-size: 12px
                line-height: 12px
            .currencyQuantity
                color : #2A85FF
                font-weight: 600
                font-size: 12px
                line-height: 12px
        .rewardsItem
            display: inline-block
            margin: 0px 0px 5px 0px
            background: #B5E4CA
            font-weight: 600
            padding: 3px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px
            .itemContent
                color: #83BF6E
                font-weight: 600
                font-size: 12px
                line-height: 12px
            .itemQuantity
                color: #83BF6E
                font-weight: 600
                font-size: 12px
                line-height: 12px
                