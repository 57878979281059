@import ../../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            //background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 56px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:last-child
        padding-right: 16px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
        .prices
            display: block
            margin: 5px 0px
            .virtual
                color: $Virtual-badge-color
                background: $Virtual-badge-bg
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                letter-spacing: -0.15px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                padding: 3px 8px
                +dark
                    color: $Virtual-badge-color
                    background: $Virtual-badge-bg
            .real
                color:  $Real-badge-color
                background: $Real-badge-bg
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                letter-spacing: -0.15px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                padding: 3px 8px
                +dark
                    color:  $Real-badge-color
                    background: $Real-badge-bg
            .IAP
                color:  $IAP-badge-color
                background: $IAP-badge-bg 
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                padding: 3px 8px
                letter-spacing: -0.15px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                +dark
                    color:  $IAP-badge-color
                    background: $IAP-badge-bg 
    .removeIcon
        cursor: pointer
    .inputWidth
        width: 100%
        height: 40px
        background: #F4F4F4
        padding: 0 10px
        border: 2px solid transparent
        border-radius: 8px
        font-family: "Inter", sans-serif
        font-size: 15px
        font-weight: 600
        line-height: 1.6
        color: #1A1D1F
        transition: all 0.2

.itemName
    color: #1A1D1F
    font-family: Inter
    font-size: $table-content
    font-weight: 700
    line-height: 16px
    letter-spacing: -0.01em
    margin: 0px 0px 5px 0px
    width: 250px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.itemId
    color : #808191
    font-family: Inter
    font-size: $table-grey-content
    font-weight: 600
    line-height: 16px
    letter-spacing: -0.01em
    width: 250px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.icon
    width: 24px
    height: 24px
    cursor: pointer

.editIcon
    width: 32px
    height: 32px
    cursor: pointer

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .details,
    .price,
    .product,
    .empty,
    .date
        display: none
    .details,
    .price,
    .empty,
    .product
        +t
            margin : 0px 5px 0px 0px
            text-align : left
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1

.preview
    flex-shrink: 0
    width: $icon-width
    height: $icon-height
    background: #EFEFEF
    margin-right: 20px
    border-radius: 8px
    overflow: hidden
    position: relative
    img
        width: $icon-width
        height: $icon-height
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 44px
        +title2

.link
    +caption1
    color: $shades1
    +t
        display: none

.price,
.empty,
.archive
    flex-shrink: 0
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        background: #EAFAE4
        color: #83BF6E

.virtual
    color: $Virtual-badge-color
    background: $Virtual-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $Virtual-badge-color
        background: $Virtual-badge-bg

.real
    color:  $Real-badge-color
    background: $Real-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color:  $Real-badge-color
        background: $Real-badge-bg

.IAP
    color:  $IAP-badge-color
    background: $IAP-badge-bg 
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    padding: 3px 8px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color:  $IAP-badge-color
        background: $IAP-badge-bg 

.archive
    color: #FF6A55
    background: #FFBC99
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $n7

.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px

.container
    padding: 0px 24px
    .head
        display: flex
        flex: 1
        gap: 10px
        margin: 0px 0px 32px 0px
        font-weight: 600
        font-size: 16px
        line-height: 24px
        letter-spacing: -0.02em
        color: #1A1D1F
        .block
            width: 12px 
            height: 28px
            background: #B1E5FC
            border-radius: 4px

.cardDescription
    .preview
        display: flex
        margin: 30px 0px 15px 0px
        align-items: center
        gap: 15px
        .previewImage
            width: $icon-width
            height: $icon-height
            img
                width: $icon-width
                height: $icon-height
                border-radius : 32px
                object-fit: cover
                border : 2px solid #EFEFEF
        .previewInput
            .button
                cursor : pointer
                font-size : 15px
                padding : 13px 20px
                font-weight : 700
                background : #2A85FF 
                color : #fff
                border-radius : 12px
                height : 48px
                border: 2px solid #2A85FF
                .addIcon
                    margin : 0px 8px 0px 0px
            input[type="file"]
                display: none !important
        .previewCanecl
            +m
                display : none
            .buttonCancel
                font-weight : 700
                background : #FCFCFC 
                font-size : 15px
                color : #1A1D1F
                border-radius : 12px
                width : 91px
                height : 48px
                border: 2px solid #EFEFEF
                &:hover
                    border: 2px solid #1A1D1F

    .createBundle
        width : 100%
        margin : 30px 0px
        .content
            .contentRow
                display: flex
                flex-wrap: wrap
                .contentCol
                    flex: 0 0 calc(25% - 12px)
                    width: calc(25% - 12px)

    .createItem
        width : 100%
        margin : 30px 0px
        .labelHeading
            margin-bottom: 10px
        .selectInput
            margin: 15px 0px
        .groupHeading
            margin : 0px 0px 20px 0px
            span
                margin : 0px 8px 0px 0px
            .addIcon
                cursor : pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -6px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
            .lockGroup
                width : 50%
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 6px
                .selectField
                    flex: 0 0 calc(100% - 12px)
                    width: calc(100% - 12px)
                    cursor : pointer
                    margin: 0 6px 12px
                    +m
                        width: 100%
                        margin: 15px 0 0
                    option
                        font-size: 14px
                        background-color: #ffffff
                .danger
                    margin: 0 0 0 12px
                    font-weight: bold
                    color: red
                    font-size: 12px 
            .variants
                display: flex
                flex-direction: row
                margin : 0px 0px
                .checkbox
                    flex: 0 0 calc(50% - 16px)
                    width: calc(50% - 16px)
                    margin: 16px 16px 0 0
                    +m
                        flex: 0 0 calc(50% - 16px)
                        width: calc(50% - 16px)
        .textArea
            .textAreaField
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 12px 0 0
        
        .groupByThree
            display: flex
            flex-wrap: wrap
            margin: 0 -10px -12px -6px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(32% - 12px)
                width: calc(32% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0 
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(31% - 12px)
                width: calc(31% - 12px)
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    option
                        font-size: 14px
                        background-color: #ffffff                    

            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none
            .variants
                display: flex
                flex-direction: row
                margin: 8px 0px 12px 0px
                .radio
                    margin : 0px 15px 0px 0px
        
        .groupByThrees
            display: flex
            flex-wrap: wrap
            margin: 0 -25px -12px 0px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(33.33% - 12px)
                width: calc(33.33% - 12px)
                margin: 0 6px 12px 0px
                +m
                    width: 100%
                    margin: 15px 0 0
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(31% - 12px)
                width: calc(31% - 12px)
                margin: 0 6px 12px
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .variants
                display: flex
                flex-direction: row
                margin: 8px 0px 12px 0px
                .radio
                    margin : 0px 15px 0px 0px
        
        .groupByFour
            display: flex
            flex-wrap: wrap
            margin: 0 -18px -12px -6px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(25% - 25px)
                width: calc(25% - 25px)
                margin: 0 7px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(25% - 12px)
                width: calc(25% - 12px)
                margin: 0 6px 12px
                +m
                    display: block
                    width: 100%
                    margin: 15px 0 0
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    width: 100%
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none
        .groupByFive
            display: flex
            flex-wrap: wrap
            margin: 0 -32px -12px -6px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(20% - 25px)
                width: calc(20% - 25px)
                margin: 0 7px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(20% - 12px)
                width: calc(20% - 12px)
                margin: 0 6px 12px
                +m
                    display: block
                    width: 100%
                    margin: 15px 0 0
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    width: 100%
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none

        .multiselect
            margin : 25px 0px
            .tagInput
                cursor: text !important
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
                span
                    font-size : 15px
                    color: #33383F
                    font-weight : 700
            .selectInput
                .field
                    flex: 0 0 calc(100% - 12px)
                    width: calc(100% - 12px)
                    margin: 0 0 12px
                    +m
                        width: 100%
                        margin: 12px 0 0
        
        .itemDropdown
            display : flex
            justify-content : right
            padding : 0px 0px 0px 20px
            margin: -35px 0px 0px 0px

        .list
            display: flex
            flex-wrap: wrap
            margin: -4px -16px 32px 0
            +d
                margin-bottom: 24px

        .checkbox
            flex: 0 0 calc(33.33% - 16px)
            width: calc(33.33% - 16px)
            margin: 16px 16px 0 0
            +m
                flex: 0 0 calc(50% - 16px)
                width: calc(50% - 16px)
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 8px)
                width: calc(48% - 8px)
                margin: 0 6px 8px
                font-size: 14px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none
                
.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding: 8px 4px
    font-weight: 700
    background: #2A85FF 
    border: 2px solid #2A85FF
    color: #fff
    border-radius: 8px
    width: 90px
    &:disabled
        opacity: 0.5

.Cancel
    padding: 8px 4px
    font-weight: 700
    background: #FCFCFC 
    color: #1A1D1F
    border-radius: 8px
    width: 90px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px