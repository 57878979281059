@import ../../../styles/helpers

.scheduleModal
    .scheduleContainer
        .modalHeader
            .modalHeading
                display: flex
                justify-content: center
                flex: 1
                gap: 10px
                margin: 0px 0px 20px 0px
                font-weight: 600
                font-size: 20px
                line-height: 32px
                letter-spacing: -0.02em
                color: #1A1D1F
        .modalBody
            margin: 0px 10px
            .warningContent
                font-weight: 500
                font-size: 15px
                line-height: 24px
                letter-spacing: -0.02em
                color: #6F767E
                margin-top : 15px
            .name
                font-weight : bold
                color: #1A1D1F
        .modalFooter
            margin: 20px 0px 0px 0px
            .buttonDanger,
            .buttonSecondary
                width: 45%
                padding: 15px 0px
                border-radius: 12px
                margin: 0px 10px
                letter-spacing: -0.01em
                +m
                    display: block
                    margin: 10px auto
            .buttonDanger 
                background: #FF6A55
                color: #fff
                font-weight: bold
            .buttonSecondary
                border: 2px solid #EFEFEF