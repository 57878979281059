.toggle
    display: flex
    margin-left: 16px
    background: #F4F4F4
    border-radius: 8px
    padding: 4px
    font-size: 13px
    .link
        flex-shrink: 0
        width: 25%
        height: 22px
        border-radius: 8px
        font-weight: bold
        p
            transition: fill .2s
        &.active
            background: #FCFCFC
            font-weight: bold
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        &:not(:last-child)
            margin-right: 0px

.tooltip 
    position: relative
    overflow: visible

.tooltiptext
    visibility: hidden
    background-color: #33383F
    color: #fff
    font-size:8px
    border-radius: 6px
    padding: 8px
    position: absolute
    top: -10%

.tooltip:hover .tooltiptext 
    visibility: visible

.preview
    display: flex
    margin: 20px 0px 28px 0px
    .previewImage
        width: 96px
        height: 96px
        img
            width: 96px
            height: 96px
            border-radius: 32px
            object-fit: cover
            border: 2px solid #EFEFEF

.container
  display: flex
  align-items: center
  flex-wrap: wrap
  flex-basis: 100
  .tenth
    width: calc(10% - 8px)
    flex: calc(10% - 8px)
    .arrows
      display: flex
      align-items: center
      justify-content: flex-end
  .half
    width: calc(40% - 8px)
    flex: calc(40% - 8px)
  .quater
    width: calc(25% - 8px)
    flex: calc(25% - 8px)


.fadeIn 
  animation: fadeInAnimation 0.5s ease-in-out forwards


@keyframes fadeInAnimation 
  0%
    opacity: 0
  
  100%
    opacity: 1
  

.slideInFromRight 
  animation: slideInFromRightAnimation 0.125s ease-in-out forwards


@keyframes slideInFromRightAnimation 
  0%
    transform: translateX(100%)
  
  100%
    transform: translateX(0)
  


.slideInFromLeft 
  animation: slideInFromLeftAnimation 0.125s ease-in-out forwards


@keyframes slideInFromLeftAnimation 
  0%
    transform: translateX(-100%)
  
  100%
    transform: translateX(0)