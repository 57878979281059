@import '../../../../styles/helpers'

.custom
    position: relative
    width: 100%
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 100px
        width: 100%
        display: flex
        justify-content: left
        .dropdown
            cursor: pointer
            padding: 0px 2px
            &.toggle
                border-top-right-radius: 8px
                border-bottom-right-radius: 8px
                height: 28px
                background: rgb(19, 87, 175)
                svg
                    margin-top: 3px
                    fill: #fff
                    transform: rotate(90deg)

.seprator
    margin: 5px 0px
    border-top: 2px solid #EFEFEF

.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% + 0px)
    left: 0px
    width: 90px
    padding: 10px 12px
    border-radius: 8px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    +x
        top: calc(100% + 0px)
        left: 0px
        border-radius: 8px
    .text
        padding: 2px 0px
        cursor: pointer
        color: #6F767E
        font-weight: 600
        font-size: 12px
        line-height: 16px
        &:hover
            color: #1A1D1F