@import '../../../../../styles/helpers'

.warning
    margin-top: 20px

.dropDown
    display: block
    margin-left: auto

.overview
    .groups
        display: flex
        flex-wrap: wrap
        align-items: center
        margin: 25px -6px -12px
        +m
            display: block
            margin: 0
        .field
            flex: 0 0 calc(50% - 12px)
            width: calc(50% - 12px)
            margin: 0 6px 12px

.tableWrapper
    .group
        .toggle
            display: flex
            margin-left: auto
            margin-top: 20px
            background: #F4F4F4
            border-radius: 8px
            padding: 5px
            .link
                flex-shrink: 0
                width: 50%
                height: 32px
                font-size: $font_12
                border-radius: 8px
                font-weight: bold
                p
                    transition: fill .2s

                &.active
                    background: $n1
                    font-weight: bold
                    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
                &:not(:last-child)
                    margin-right: 0px
        .pagination
            display : flex
            align-items : center
            justify-content : center
            gap : 25px
            margin : 20px 0px 0px 0px
            .icons
                .previous, .next
                    margin: 5px 5px
                    padding : 5px
                    border : 2px solid #EFEFEF
                    border-radius : 50%
                    cursor : pointer
        .warning
            margin-top: 20px