@import '../../../styles/helpers'

.custom
    position: relative
    width: 100%
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 100px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 7px
        
.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% + 18px)
    left: 0px
    width: 100px
    padding: 10px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    +x
        top: calc(100% + 15px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        left: 10px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +x
            right: 13px
        +m
            right: 21px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")
    .text
        padding: 2px 0px
        cursor: pointer
        color: #6F767E
        font-weight: 600
        font-size: 15px
        line-height: 26px
        &:hover
            color: #1A1D1F

        
.item
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 12px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        background : #272B30
        color : #F4F4F4
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)


.itemContainer
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 5px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s   
    margin: 5px 0px
    &.active
        background: #33383F
        p
            color: #fff !important