.fileInformation
    .container
        .progress
            display: flex
            align-items: center
            justify-content: center
            gap: 25px
            margin-bottom: 30px
            .progressContent
                display: flex
                align-items: center
                gap: 5px
                .progressStatus
                    width: 20px
                    height: 20px
                    background: #E4E4E4
                    border-radius: 50%
                .content
                    font-size: 12px
                    font-weight: 500
                .icon
                    margin-bottom: 3px
        .filePreview
            margin-top: 16px
            .errors
                display: flex
                flex-wrap: wrap
                .fileError
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin: 0 12px 12px 0px
                    .issues
                        cursor: pointer
                        font-size: 12px
                        font-weight: 500
                        color: #FF6A55
            .group
                display: flex
                flex-wrap: wrap
                align-items: center
                margin-top: 12px
                .files
                    background: #F4F4F4
                    display: flex
                    align-items: center
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin: 0 12px 12px 0px
                    padding: 8px 16px
                    border-radius: 12px
                    .fileInfo
                        flex: 2
                        padding-left: 10px
                        .fileName
                            color: #33383F
                            font-size: 14px
                            line-height: 24px
                            font-weight: 500
                        .fileDetail
                            color: #33383F
                            font-size: 10px
                            line-height: 24px
                            font-weight: 400
                    .actionIcons
                        display: flex
                        align-items: center
                        gap: 20px
                        .icons
                            background: #FCFCFC
                            width: 24px
                            height: 24px
                            border-radius: 50%
                            position: relative
                            cursor: pointer
                            .icon
                                position: absolute
                                top: 50%
                                left: 50%
                                transform: translate(-50%, -50%)