.session-log-viewer {
  background: #1E1E2E;
  margin: 0px;
  border-radius: 12px;
  width: calc(100% - 0px);
  height: 640px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.session-log-viewer-overlay{
  position: absolute;
  color: #FFFFFF;
  bottom: 24px;
  background: #1E1E2E;
  margin: 0px;
  border-radius: 12px;
  width: calc(100% - 48px);
  height: 640px;
  display: none;
  overflow: auto;
  opacity: 0.9;
  z-index: 2;
}

.session-log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 40px;
}

.session-log-title {
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
  text-align: center;
  color: #ffffff;
}

.expand-all-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.expand-all-button, .refresh-button {
  background: #2C2C3E;
  color: #FFFFFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px 10px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;
  /* box-shadow: 4px 4px 8px #1A1A1A, -4px -4px 8px #3E3E3E; */
  font-size: 12px;
}

.expand-all-button:hover, .refresh-button:hover {
  transform: translateY(-2px);
  /* box-shadow: 2px 2px 4px #1A1A1A, -2px -2px 4px #3E3E3E; */
}

.refresh-icon {
  width: 12px;
  height: 12px;
  filter: invert(1);
}

.log-entries {
  overflow-y: auto;
  font-family:  Courier, monospace;
  font-size: 10px;
  font-weight: 500;
  color: #BAC2DE;
  flex-grow: 1;
  background: #1E1E2E;
  padding: 10px;
  border-radius: 8px;
  /* box-shadow: inset 4px 4px 8px #1A1A1A, inset -4px -4px 8px #3E3E3E; */
}

.log-entry {
  margin-bottom: 10px;
  line-height: 1.1;
  white-space: pre-wrap;
  text-align: left;
}

.log-entry-header {
  display: grid;
  grid-template-columns: 1.75fr 3fr 5fr 0.75fr auto;
  align-items: center;
  cursor: pointer;
  gap: 15px;
}

.log-entry-header div {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.socket-log-entry-header {
  display: grid;
  grid-template-columns: 2fr 8fr auto;
  align-items: top;
  cursor: pointer;
  gap: 15px;
}

.socket-log-entry-header div {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.log-entry-details {
  margin-top: 5px;
  display: none;
}

.expand-icon {
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  text-align: center;
}

.log-entry-section-header {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
}

.log-entry-section-header .expand-icon {
  margin-right: 5px;
}

.log-copy-icon {
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
  filter: invert(1);
}

body .log-copy-success {
  font-size: 0.8em;
  margin-right: 8px;
  color: #53e111;
  padding: 3px;
}

.string-value {
  color: #0FDAF4; /* Blue for strings */
}

.boolean-value {
  color: #F30FFF; /* Green for booleans */
}

.number-value {
  color: #FFAA4F; /* Orange for numbers */
}



.params-inline {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  color: #BAC2DE;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom; /* Ensures alignment with other grid items */
}

.log-header {
  display: grid;
  grid-template-columns: 1.75fr 3fr 5fr 0.75fr auto;
  align-items: center; /* Adjust background color to match your theme */
  padding: 12px;
  font-weight: 600;
  color:#ffffff;
  font-family:  Courier, monospace;
  font-size: 10px;
  position: sticky;
  top: 0;
}

body.dark-mode .log-header {
  background-color: #2c2c2e; /* Adjust background color for dark mode */
}

.log-header > div {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}







.terminal_promt {
  display: flex;
}

.terminal_promt span {
  margin-left: 4px;
}

.terminal_user {
  color: #1eff8e;
}

.terminal_location {
  color: #4878c0;
}

.terminal_bling {
  color: #dddddd;
}

.terminal_cursor {
  display: block;
  height: 14px;
  width: 5px;
  margin-left: 10px;
  animation: curbl 1200ms linear infinite;
}

@keyframes curbl {

  0% {
    background: #ffffff;
  }

  49% {
    background: #ffffff;
  }

  60% {
    background: transparent;
  }

  99% {
    background: transparent;
  }

  100% {
    background: #ffffff;
  }
}




/* Enhanced fade-in effect for new log entries */
@keyframes fadeIn {
  0% {
    background-color: rgba(0, 255, 0, 0.25);
  }
  100% {
    background-color: transparent;
  }
}

.log-entry.new {
  animation: fadeIn 1s ease;
}
