@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 60px
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        padding-right: 10px
        border-radius: 0 8px 8px 0
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .taskId
        width: 120px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        color: #1A1D1F
    .name
        width: 150px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        color: #1A1D1F
    .id
        width: 150px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        font-size: $table-grey-content
        color: #6F767E

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1

    .preview
        display: flex
        width: $icon-width
        height: $icon-height
        background: #EFEFEF
        border-radius: 8px
        position: relative
        +dark
            background: rgba(167, 170, 247, 0.15)
        +t
            width: $icon-width
            height: $icon-height
        img
            border-radius: 8px
            width: $icon-width
            height: $icon-height
            border: 2px solid #EFEFEF
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px