@import ../../../../../../../../../styles/helpers

.groupByThree
    display: flex !important
    flex-wrap: wrap
    margin: 15px -10px -12px -6px
    +m
        display: block
        margin: 0
    .field, .fieldRadio
        flex: 0 0 calc(32% - 12px)
        width: calc(32% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 15px 0 0 
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px
        .radioLabel
            font-size: 14px !important
            font-weight: 600 !important