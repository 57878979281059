@import ../../styles/helpers


.label
    margin-bottom: 8px
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1

.wrapContainer
    margin-top: 25px
    position: relative

.wrap
    position: relative
    .textArea
        position: relative
        .icon
            display: flex
            align-items: center
            justify-content: center
            gap: 4px
            cursor: pointer
            background: #2A85ff
            position: absolute
            bottom: 6px
            right: 0px
            padding: 2px 8px
            border-top-left-radius: 8px
            border-bottom-right-radius: 8px
            .wand
                svg
                    width: 12px
                    height: 12px
                    fill: #fff
                    margin-bottom: 2px
            .title
                color: #fff
                font-size: 11px

        
// .prompt
//     width: 100%
//     .promptInput
//         .field
//             padding: 0px 12px
//             border-radius: 8px
//             width: 100%
//             height: 32px
//             background: red
//     .answer
//         .action
//             margin-top: 8px
//             display: flex
//             justify-content: right
//             gap: 10px

.input
    width: 100%
    padding: 10px 10px
    border-radius: 12px
    border: 2px solid transparent
    background: $n2
    +inter
    font-weight: 12px
    color: $n7
    transition: all .2s
    +dark
        border-color: $n6
        background: $n6
        color: $n1
    +placeholder
        color: $n4
        +dark
            color: $shades1
    &:focus
        border-color: $shades1
        background: $n
        +dark
            border-color: $n5
            background: $n8

.tagsWarning
    font-size: 12px
    color: #9A9FA5
    font-weight: 500
    margin-right:8px
    .tagBold
        font-size: 12px
        font-weight: 500
        color: #1A1D1F