@import ../../../../../styles/helpers

.container
    margin: 50px 30px 0px 30px

.wrapper
    margin-bottom: 10px

.head
    display: flex
    align-items: center
    justify-content: space-between
    .title
        display: flex
        align-items: center
        .block
            width: 12px
            height: 28px
            border-radius: 4px
            background: #FFBC99
        .name
            margin-left: 8px
    .calendar
        flex: 1

.pagination
    display: flex
    align-items: center
    justify-content: center
    gap: 25px
    margin: 10px 0px 0px 0px
    .icons
        .previous, .next
            margin: 0px 5px
            padding: 5px
            border: 2px solid #EFEFEF
            border-radius: 50%
            cursor: pointer