@import ../../../styles/helpers

.card
    margin: 16px auto
    cursor: pointer
    .cardWrapper
        padding: 10px 12px
        border: 2px solid #f4f4f4
        border-radius: 8px
        .cardHeader
            width: 100%
            height: 100px
            .progressBar 
                position: absolute
                top: 45%
                z-index: 10
                height: 8px
                width: calc( 96% - 28px )
                border-radius: 8px
                background-color: #E4E4E4
                margin-top: 10px
            .progress 
                height: 100%
                border-radius: 8px
                background-color: #2A85FF
                transition: width 0.4s ease
            .overlay
                position: absolute
                top: 0px
                left: 0px
                background: rgba(0, 0, 0, 0.7)
                visibility: hidden
                display: flex
                align-items: center
                justify-content: center
                gap: 10px
                width: 100%
                height: 100px
                border-radius: 8px
                .icon
                    width: 30px
                    height: 30px
                    border: 2px solid #f4f4f4
                    border-radius: 50%
                    text-align: center
                    svg
                        fill: #f4f4f4
            .cardImage
                overflow: hidden
                background: #F4F4F4
                border-radius: 8px
                width: 100%
                height: 100px
                img, video
                    object-fit: contain
                    border-radius: 8px
                    width: 100%
                    height: 100%
                    object-fit: cover
        &:hover
            .overlay
                visibility: visible
        .cardBody
            display: block
            margin: 8px 0px 0px 0px
            .content
                display: flex
                justify-content: space-between
                align-items: center
                .label
                    width: 80%
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                    color: #1A1D1F
                    font-family: Inter
                    font-size: $font_12
                    font-style: normal
                    font-weight: 600
                    line-height: 18px
                    letter-spacing: -0.15px
                    padding: 0px 5px 0px 0px
                .copy
                    display: flex
                    cursor: pointer
                    padding: 4px 8px
            .fileContent
                display: flex
                align-items: center
                gap: 5px
                .fileSize
                    font-size: 10px
                    font-weight: 600
                    color: #6F767E
                    padding-top: 2px
                .copy
                    margin-left: auto
                    font-size: 10px
                    svg
                        margin-left: 5px
                        fill: #6F767E
                .preview
                    cursor: pointer