@import ../../../styles/helpers


.wrapper
    padding: 10px 32px 0px 32px
    
.usage
    display: flex
    .apiUsage
        width: calc( 50% - 10px )
        margin-right: 20px
    .eventUsage
        width: calc( 50% - 10px )

.toggle
    display: flex
    align-items: center
    justify-content: end
    column-gap: 16px
    margin-left: auto

.noData
    font-size: 18px
    font-weight: 500
    text-align: center
    height: 320px
    align-content: center

.data
    width: 100%
    height: 100%
    padding: 16px
    .topText
        color: #33383F
        font-size: 13px
        line-height: 16px
        font-weight: 600
        letter-spacing: -1%
        svg
            vertical-align: top
            margin-left: 4px
    .headText
        color: #1A1D1F
        font-size: 32px
        line-height: 32px
        font-weight: 600
        letter-spacing: -3%
        margin-top: 8px
    .tab
        height: 24px
        border-radius: 4px
        padding: 4px
        gap: 4px
        background: #F4F4F4
        width: fit-content
        margin-top: 8px
        .tabText
            font-size: 12px
            line-height: 16px
            font-weight: 600
            letter-spacing: -1%
            vertical-align: top

.details
    display: flex
    margin-top: 32px
    .apis
        height: 104px
        width: calc( 34% - 12px )
        background: #fff
        padding: 8px 16px
        border: 1px solid #efefef
        border-radius: 12px
        margin-right: 20px
    .currentCost
        height: 104px
        width: calc( 34% - 12px )
        background: #fff
        padding: 8px 16px
        border: 1px solid #efefef
        border-radius: 12px
        margin-right: 20px
    .lastCost
        height: 104px
        width: calc( 34% - 12px )
        background: #fff
        padding: 8px 16px
        border: 1px solid #efefef
        border-radius: 12px


.chart
    width: auto
    height: 320px
    margin-left: -35px
    margin-bottom: -10px

.legend
    display: flex
    justify-content: space-between
    margin-top: 32px
    +m
        display: none

.indicator
    display: flex
    align-items: center
    //+caption2
    //    font-weight: 700

.color
    flex-shrink: 0
    width: 16px
    height: 16px
    margin-right: 8px
    border-radius: 4px


.tabStyle
    width: 16px
    height: 16px
    background-color: #FFCA48
    margin-right: 5px
    border-radius: 4px