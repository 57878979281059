@import ../../../styles/helpers

.introductionModal
    position : relative
    .introductionModalContainer
        .introductionModalInnerContainer
            padding : 65px 40px
            .bgCircle
                position : absolute
                top : 5%
                left : -9%
            .bgCross
                position : absolute
                bottom : 18%
                right : -6%
            .nav
                display : flex
                align-items : top
                gap : 35px
                .navLinksTitle
                    .navPillsContainer
                        position : relative
                        .checkbox
                            position : absolute
                            right : 5px
                        .navPills
                            border : 2px solid #EFEFEF
                            border-radius : 12px
                            cursor : pointer
                            margin : 12px 0px
                            width : 136px
                            height : 68px
                            .navBars
                                height : 100%
                                display : flex
                                align-items : center
                                justify-content : center
                                gap : 15px
                                .navTitle
                                    font-size : 14px !important
                .navLinksContain
                    margin : 10px 0px
                    .module
                        width : 100%
                        max-width : 490px !important
                        .moduleHeading
                            font-size : 40px
                            line-height : 48px
                            font-weight : 600
                            font-style: normal
                            .contentBlue
                                color : #2A85FF
                            .contentYellow
                                color : #F9C600
                        .moduleContent
                            margin : 25px 0px
                            font-size: 14px
                            font-style: normal
                            font-weight: 500
                            line-height: 24px
                            color : #6F767E
                            .moduleContentParagraph
                                margin : 20px 0px
                                .bold
                                    font-weight : 700
                        .moduleButton
                            width : 100%
                            max-width : 500px !important
                            text-align : right
                            padding-right : 20px
                            padding-top : 5px
                            .button
                                background: #2A85FF
                                border-radius: 12px
                                width : 160px
                                font-weight: 700
                                font-size: 13px
                                line-height: 24px
                                letter-spacing: -0.01em
                                color: #FCFCFC
                                padding : 12px 20px
                                margin : 0px 10px
                                border : 2px solid transparent
                                &:hover
                                    background: darken($p1, 10)
                            .buttonCancel
                                background: #FCFCFC
                                border-radius: 12px
                                width : 160px
                                font-weight: 700
                                font-size: 13px
                                line-height: 24px
                                letter-spacing: -0.01em
                                color: #1A1D1F
                                padding : 12px 20px
                                border : 2px solid #EFEFEF
                                margin : 0px 10px
                .navImages
                    position : absolute
                    top : 50%
                    right : 5%
                    transform : translate(0%, -50%)
                            