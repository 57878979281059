@import ../../../../../styles/helpers

.addContentModal
    .addContent
        .modalHeading
            display: flex
            gap: 10px
            margin: 0px 0px 20px 0px
            font-weight: 600
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 12px 
                height: 28px
                background: #CABDFF
                border-radius: 4px
        .modalFooter
            padding-top: 20px
            .btnGroup
                display: flex
                align-items: center
                gap: 10px
