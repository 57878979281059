@import ../../../styles/helpers

.item
    +d
        position: relative
    +m
        position: static
    &.active
        .head
            border-color: $p1
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05)
            svg
                fill: $n7
                +dark
                    fill: $n1
        .body
            visibility: visible
            opacity: 1
    &.disabled
        .head
            border-color: $n3
            opacity: 0.6
            cursor: default

.head
    display: flex
    align-items: center
    min-height: 36px
    padding: 6px 12px
    border-radius: 8px
    border: 2px solid $n3
    cursor: pointer
    transition: all .2s
    svg
        flex-shrink: 0
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    +dark
        border-color: $n6
    &:hover
        border-color: $p1
    
.category
    +caption2-m
    color: $n4
    font-size: 11px

.value
    font-size: 12px

.body
    width: 300px
    z-index: 10
    position: absolute
    top: calc(100% + 8px)
    left: 0
    background: $n1
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    border-radius: 16px
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        top: 100%
        left: 0
        right: 0
        z-index: 3
    +m
        top: 50%
        left: 12px
        right: 12px
        transform: translateY(-50%)
    +dark
        background: $n7
        +m
            box-shadow: inset 0 0 0 1px $n6