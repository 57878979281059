@import ../../../../styles/helpers

.productionInfo, .staggingInfo
    .url, .token, .key
        position: relative
        width: 75%
        margin: 16px 0px
        .inputIcon
            background: #fff
            padding: 2px 4px
            border-radius: 8px
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), 0px 2px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px rgba(0, 0, 0, 0.04) inset
            z-index: 1
            cursor: pointer
            position: absolute
            right: 5px
            top: 50%
            transform: translate(-5px, -50%)
.add
    display: flex
    margin-left: auto
    cursor: pointer
    border: 2px solid #EFEFEF
    padding: 4px 8px
    border-radius: 8px
    font-size: $font_12
    .addApiKey
        color: black
        svg
            color: black
            fill: black
            margin: 0px 5px 2px 0px
.group
    margin: 0px 0px 0px 0px
    .playerInformation
        .preview
            .primaryInfo
                display: flex
                gap : 20px
                align-items: center
                .profile
                    width: 72px
                    height: 72px
                    background: #EFEFEF
                    border-radius: 8px
                    position: relative
                    img
                        width: 72px
                        height: 72px
                        border: 2px solid #EFEFEF
                        border-radius: 8px
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                .otherInfo
                    flex-grow: 1
                    .playerName
                        margin-bottom: 5px
                        .userName
                            font-size: 18px
                            font-weight: 600
                            line-height: 24px
                            letter-spacing: -0.03em
                            display: flex
                            align-items: center
                            .profileInput
                                width: 200px
                                margin-bottom: 10px
                            .editIcon
                                margin-left: 10px
                                margin-bottom: 10px
                                cursor: pointer
                    .secondaryInfo
                        display: flex
                        justify-content: space-between
                        .playerId
                            flex-grow: 3
                            .ids
                                .label
                                    color : #1A1D1F
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 13px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color: #6F767E
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 13px
                                    letter-spacing: -0.01em
                                    .copyToClipboard
                                        cursor: pointer
                                        margin-left: 5px
                        .playerLocation
                            flex-grow: 1
                            .ids
                                .label
                                    color : #1A1D1F
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 13px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color : #6F767E
                                    font-size: $font_12
                                    font-weight: 600
                                    line-height: 13px
                                    letter-spacing: -0.01em