@import ../../../../styles/helpers

.container
    margin: 50px
    .specialOffer
        margin-bottom: 20px
        flex: 1
        border: 2px solid #2a85ff
        border-radius: 8px 
        .offer
            display: flex
            justify-content: space-between
            align-items: center
            padding: 16px
            .content
                .title
                    font-size: 16px
                    font-weight: 600
                    color: #11142D
                .description
                    font-size: 13px
                    font-weight: 600
                    color: #808191
            .button
                cursor: pointer
                padding: 10px 16px
                background: linear-gradient(60deg, #0538FF 13.4%, #70E3F5 86.6%)
                color: #fff
                border-radius: 8px
                .title
                    display: flex
                    align-items: center
                    gap: 4px

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    margin: 10px -6px 10px -6px
    padding: 15px 0px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        .title
            font-size: 14px
        .info
            color: #6F767E
            font-size: 10px      
    .table
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .buy
            margin-top: 15px
            .buyAddOn
                display: flex
                align-items: center
                justify-content: center
        .addOnsList
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            align-items: center
            gap: 12px
            padding: 0px
            .addOn
                cursor: pointer
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                border: 2px solid #E3E6E9
                border-radius: 8px
                padding: 10px 12px
                &.disabled
                    pointer-events: none
                    cursor: default
                    opacity: 0.5
                .addOnDetail
                    display: flex
                    justify-content: space-between
                    margin-bottom: 4px
                    .detail
                        .name
                            color: #11142D
                            font-size: 12px
                            font-weight: 600
                        .description
                            color: #808191
                            font-size: 12px
                            font-weight: 600
                .addOnPurchase 
                    display: flex
                    justify-content: space-between
                    align-items: end
                    height: 25px
                    .price
                        color: #2A85FF
                        font-weight: 600
                        font-size: 12px
                        border-radius: 6px
                    .quantity
                        display: flex
                        align-items: center
                        gap: 10px
                        font-size: 13px
                        color: #1A1D1F
                        height: 25px
                        .amount
                            font-size: 12px
                            border: 1px solid #E3E6E9
                            border-radius: 4px
                            padding: 0px 5px
                        .operation
                            display: flex
                            flex-direction: column
                            .add
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                height: 12px
                                svg
                                    transform: rotate(-90deg)
                            .remove
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                svg
                                    transform: rotate(90deg)

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background: rgba($n2, .5)
    visibility: hidden
    opacity: 0
    transition: all .2s
    &.active
        visibility: visible
        opacity: 1