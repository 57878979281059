@import '../../../../../../styles/helpers'

.icon
    display: flex
    width: 100%
    justify-content: right
    flex: 1
    .edit
        cursor: pointer

.container
    padding: 10px 32px 0px 32px

.overview
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    margin: 20px -6px 20px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px    
    .cardBody
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .info
            .group
                display: flex
                flex-wrap: wrap
                align-items: center
                margin: 16px -6px -12px
                +m
                    display: block
                    margin: 0
                svg
                    fill: #6F767E !important
                .field, .fieldRadio
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin: 0 6px 12px
                    +m
                        width: 100%
                        margin: 12px 0 0
                    .radioLabel
                        padding: 20px 0px 0px 0px
                        font-size: 14px
                        font-weight: 600
                        color : #33383F
                    .variants
                        display: flex
                        flex-direction: row
                        margin: 8px 0px 12px 0px
                        .radio
                            margin : 0px 34px 0px 0px
                .cardAction
                    display : flex
                    flex-direction: row
                    align-items : center
                    gap : 10px
                    .Save
                        padding: 12px 20px
                        font-weight: 700
                        background: #2A85FF 
                        color: #fff
                        border-radius: 12px
                        width: 76px
                        &:disabled
                            opacity: 0.5
                        &:hover
                            background: darken($p1, 10)
                    .Cancel
                        padding : 12px 20px
                        font-weight : 700
                        background : #FCFCFC 
                        color : #1A1D1F
                        border-radius : 12px
                        width : 91px
                        border: 2px solid #EFEFEF
                        &:hover
                            box-shadow: 0 0 0 2px #1A1D1F inset

.preview
    width: 180px
    display: block
    display: flex
    align-items: center
    gap: 20px
    margin-top: 8px
    .previewImage
        width: 100px
        height: 100px
        border-radius: 16px
        position: relative
        img
            width: 100px
            height: 100px
            border-radius: 16px
            object-fit: cover
        .edit
            position: absolute
            bottom: -5px
            right: -5px
            background: #2A85ff
            width: 20px
            height: 20px
            border-radius: 50%
            cursor: pointer
            svg
                fill: #fff
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
    .actions
        display: flex
        justify-content: center
        gap: 8px
        margin-top: 16px

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important

.buttonCancel
    padding: 12px 20px
    font-weight: 700
    background: #FCFCFC 
    font-size: 15px
    color: #1A1D1F
    border-radius: 12px
    height: 48px
    border: 2px solid #EFEFEF
    &:hover
        border: 2px solid #1A1D1F

.passWord
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    margin: 20px -6px 20px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px
    .cardBody
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .cardAction
            display: flex
            gap: 10px
            align-items: center
            margin-left: 6px
        .group
            display: flex
            align-items: center
            flex-wrap: wrap
            margin: 16px -6px -12px
            &:first-child
                margin: 0px -6px -12px
            .field
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
        .rules
            font-size: 13px
            .passwordRule
                margin-top: 15px    
                .ruleGroup
                    margin-top: 5px
                    display: flex
                    align-items: center
                    justify-content: left
                    flex-wrap: wrap
                    .rule
                        margin-top: 5px
                        flex: 0 0 calc(33% - 12px)
                        width: calc(33% - 12px)
                        .error
                            fill: #F44336
                            margin-right: 5px
                        .success
                            margin-right: 5px
                            fill: #4CAF50
                    .ruleFull
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin-top: 5px
                        .error
                            margin-right: 5px
                            fill: #F44336
                        .success
                            margin-right: 5px
                            fill: #4CAF50