@import ../../../styles/helpers

.preview
    display : flex
    margin-bottom : 30px
    align-items : center
    gap : 15px
    .previewImage
        width : 92px
        height : 92px
    img
        width : 92px
        height : 92px
        border-radius : 32px
        object-fit: cover
        border : 2px solid #EFEFEF
        +dark
            border : 2px solid rgba(167, 170, 247, 0.35)
    .previewInput
        .button
            cursor : pointer
            font-size : 15px
            padding : 13px 20px
            font-weight : 700
            background : #2A85FF 
            color : #fff
            border-radius : 12px
            height : 48px
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            .addIcon
                margin : 0px 8px 2px 0px


.filters
    display: flex
    align-items: center
    gap: 15px
    .zoom
        cursor: pointer
        font-size: 12px
        padding: 4px 8px
        border: 2px solid #EFEFEF
        border-radius: 8px
        font-weight: 600
        color: #272B30
        width: fit-content
        &:hover
            .zoomIn
                transform: scale(1.2)
            .zoomOut
                transform: scale(0.8)
        svg
            margin-left: 10px
            fill: #272B30  
        .zoomIn, .zoomOut
            transition: 0.2s ease-in-out
    .refresh
        cursor: pointer
        font-size: 12px
        padding: 4px 8px
        border: 2px solid #EFEFEF
        border-radius: 8px
        font-weight: 600
        color: #272B30
        width: fit-content
        &:hover
            .icon
                transform: rotate(180deg)
        svg
            margin-left: 10px
            fill: #272B30  
        .icon
            transition: 0.2s ease-in-out

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important
        
.label-warn
    font-size: 12px
        
.description
    margin : 25px 0px 0px 0px
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .dropdown
        margin: 0 6px 12px
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0

.buttonCancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    font-size : 15px
    color : #1A1D1F
    border-radius : 12px
    height : 48px
    border: 2px solid #EFEFEF
    &:hover
        border: 2px solid #1A1D1F


.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.cardDescription
    margin: 16px 0px
    .appInformation
        margin : 20px 0px
        .platformContainer
            margin : 15px 0px
            .platformHeading
                padding : 0px 0px 10px 5px
            .platformUrl
                padding : 5px 0px 10px 10px
                .bundleUrl
                    color: #262b40
                    font-size: 12px
                    text-decoration: underline
        .multiselect
            margin : 25px 0px
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
            .tagsInput
                cursor : text !important
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                font-size: 14px
                flex: 0 0 calc(48% - 8px)
                width: calc(48% - 8px)
                margin: 0 6px 8px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none
        .groupByThree
            display: flex
            margin: 0 5px -12px 0px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                position : relative
                flex: 0 0 calc(32% - 5px)
                width: calc(32% - 5px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0 
                .bundleLabel
                    font-size : 14px
                    color : #33383F
                    font-weight : 600
                    margin-bottom : 14px
                .uploadBundleButton
                    position : absolute
                    bottom : 0px
                    width : 100%
                    height : $height_36
                    display : flex
                    align-items : center
                    justify-content : space-between
                    border-radius: 8px
                    border: 2px solid #EFEFEF
                    padding : 11px 10px
                    .label
                        overflow: hidden
                        white-space: nowrap
                        text-overflow: ellipsis
                        color: #6F767E
                        font-family: Inter
                        font-size: 14px
                        font-style: normal
                        font-weight: 600
                        line-height: 24px
                        letter-spacing: -0.14px
                .uploadedFile
                    position : absolute
                    bottom : 0px
                    width : 100%
                    height : $height_36
                    display : flex
                    align-items : center
                    justify-content : space-between
                    border-radius: 8px
                    border: 2px solid #EFEFEF
                    padding : 11px 10px
                    .fileInput
                        width : 90%
                        overflow: hidden
                        white-space: nowrap
                        text-overflow: ellipsis
                        color: #6F767E
                        font-family: Inter
                        font-size: 14px
                        font-style: normal
                        font-weight: 600
                        line-height: 24px
                        letter-spacing: -0.14px
                        .label
                            overflow: hidden
                            white-space: nowrap
                            text-overflow: ellipsis
                            color: #6F767E
                            font-family: Inter
                            font-size: 14px
                            font-style: normal
                            font-weight: 600
                            line-height: 24px
                            letter-spacing: -0.14px
                    .fileRemove
                        width : 10%
            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none
            .close
                cursor: pointer
                fill : #6F767E
        .screenshot
            .line
                display: flex
                align-items: center
                margin: 32px 0px
            .info
                margin-right: auto
                +base2
                color: $n5
                +dark
                    color: #EFEFEF
            .screenshotPreview
                margin: 16px 0px
                .previewRow
                    display: flex
                    flex-wrap: wrap
                    align-items: center
                    justify-content: space-between
                    gap: 16px
                    .previewCol
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        background: #F4F4F4
                        border-radius: 8px
                        .previewContainer
                            display: flex
                            align-items: center
                            padding: 12px 10px
                            gap: 25px
                            cursor: pointer
                            .previewContent
                                .precentageContainer
                                    .percentage
                                        display: none
                                        width: 280px
                                        height: 8px
                                        background-color: #eee
                                        border-radius: 25px
                                        position: relative
                                        padding: 0px
                                        .successfull
                                            position: absolute
                                            left: 0
                                            width: 0px
                                            height: 10px
                                            background-color: #2A85FF
                                            border-radius: 25px
                                            padding: 0px
                                    .progress-bar-images, .progress-bar-videos
                                        margin: 5px 0px 0px 0px !important
                                        background-color: #fff !important
                                        color: #000 !important
                                        text-align: left !important
                                        font-size: 13px !important
                                        font-style: italic !important
                                        font-weight: bold !important
                                        display: block !important
                                    .view
                                        display: block !important
                                        color: #2A85FF
                                        font-family: Inter
                                        font-size: 14px
                                        font-style: normal
                                        font-weight: 600
                                        a
                                            color: #2A85FF
                                            text-decoration: none
                                            font-family: Inter
                                            font-size: 14px
                                            font-style: normal
                                            font-weight: 600
                            .close
                                display: block
                                margin-left: auto
                                padding: 5px 10px
                                border-radius: 50%
                                &:hover   
                                    background: #fff

.switch
    margin-left: 12px

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px