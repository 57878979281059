@import '../../../../../styles/helpers'

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    // gap: 20px
    margin: 10px -6px 20px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        // border-right: 2px solid #efefef
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px      
    .cardBody
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .general
            border-bottom: 2px solid #efefef
            &.border
                border: none
            .group
                display: flex
                align-items: center
                flex-wrap: wrap
                margin: 0px -6px 10px -6px
                .fieldAction
                    font-size: 13px
                    width: calc(10% - 24px)
                    flex: calc(10% - 24px)
                    margin: 0 12px 12px
                    .action
                        font-size: 12px
                        color: #fff
                        display: flex
                        align-items: center
                        gap: 5px
                        background: #2A85ff
                        padding: 4px 8px
                        border-radius: 6px
                        svg
                            fill: #fff
                .field
                    font-size: 13px
                    width: calc(30% - 24px)
                    flex: calc(30% - 24px)
                    margin: 0 12px 12px