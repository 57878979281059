@import ../../../styles/helpers

.createButton
    margin : 20px 0px

.wrapper
    margin: 20px 0px

.apps
    margin: 0px 0px

.appWrapper
    padding: 0px 22px
    .celebration
        width: 100%
        height: 100%
        // max-width: 900px
        position: fixed
        bottom: 0%
        left: 50%
        transform: translate(-50%, 0%)
        z-index: 20

.row
    display: flex
    +t
        display: block

.col
    &:first-child
        flex: 0 0 calc(100% - 400px)
        width: calc(100% - 400px)
        padding-right: 8px
        +d
            flex: 0 0 calc(100% - 312px)
            width: calc(100% - 312px)
        +t
            width: 100%
            margin-bottom: 8px
            padding-right: 0
    &:nth-child(2)
        flex-shrink: 0
        width: 400px
        +d
            width: 312px
        +t
            width: 100%

.row,
.card
    &:not(:last-child)
        margin-bottom: 8px