@import '../../../../styles/helpers'

.preview
    position: relative
    display: flex
    margin-bottom: 30px
    align-items: center
    gap : 15px
    .previewImage
        cursor: pointer
        max-width: 72px !important
        max-height: 72px !important
        width: 72px !important
        height: 72px !important
        object-fit: cover  
    .previewInput
        position: absolute
        bottom: -2px
        right: -2px
        .button
            display: block
            cursor: pointer
            font-weight: 700
            background: #2A85FF 
            width: 16px
            height: 16px
            border-radius: 50%
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            svg
                width: 12px
                height: 12px
                fill: #fff
                display: block

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important

.form
    width: 300px
    margin: 0px 0px 25px 0px
    +t
        width: 300px
    +m
        width: 100%
        margin-top: 16px
        order: 3

.group
    margin: 0px 0px 0px 0px
    .playerInformation
        .preview
            .primaryInfo
                display: flex
                gap: 20px
                flex-grow: 1
                align-items: center
                .profile
                    width: 72px
                    height: 72px
                    border-radius: 12px
                    position: relative
                    .profileImage
                        width: 72px
                        height: 72px
                        border: 2px solid #EFEFEF
                        border-radius: 12px
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                    +dark
                        background: rgba(167, 170, 247, 0.15)
                    +t
                        width: 80px
                        height: 80px                    
                .otherInfo
                    flex-grow: 1
                    .playerName
                        .userName
                            font-size: 20px
                            font-weight: 600
                            line-height: 20px
                            letter-spacing: -0.03em
                            width: 500px
                            overflow: hidden
                            text-overflow: ellipsis
                            white-space: nowrap
                            margin-bottom: 5px
                            .profileInput
                                width: 200px
                                margin-bottom: 10px
                            .Save
                                margin: 10px
                                padding: 7px 10px
                                font-weight: 700
                                background: #2A85FF 
                                color: #fff
                                border-radius: 8px
                                &:hover
                                    background: darken($p1, 10)
                            .editIcon
                                margin-left: 10px
                                margin-bottom: 10px
                                cursor: pointer
                    .secondaryInfo
                        display: flex
                        justify-content: space-between
                        .playerId
                            flex-grow: 3
                            .ids
                                .label
                                    color: #1A1D1F
                                    font-size: 12px
                                    font-weight: 600
                                    line-height: 14px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color: #6F767E
                                    font-size: 12px
                                    font-weight: 600
                                    line-height: 14px
                                    letter-spacing: -0.01em
                                    .copyToClipboard
                                        cursor: pointer
                                        margin-left: 5px
                        .playerLocation
                            flex-grow: 1
                            .ids
                                .label
                                    color : #1A1D1F
                                    font-size: 12px
                                    font-weight: 600
                                    line-height: 14px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color : #6F767E
                                    font-size: 12px
                                    font-weight: 600
                                    line-height: 14px
                                    letter-spacing: -0.01em
    .toggle
        display: flex
        flex-wrap: wrap
        margin-left: auto
        background: #FCFCFC
        border-radius: 8px
        padding: 2px 5px
        .link
            font-weight: 600
            color: #6F767E
            flex-shrink: 0
            width: 12%
            height: 32px
            border-radius: 8px
            font-size: $font_12
            p
                transition: fill .2s
            &:hover
                p
                    font-weight: 600
            &.active
                color: #1A1D1F
                background: #EFEFEF
                font-weight: 600
                line-height: 24px
            &:not(:last-child)
                margin-right: 0px
