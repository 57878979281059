@import ../../../styles/helpers

.progressionContainer
    .progression
        .emptyState
            padding: 60px 0px 90px 0px
            text-align: center
            .emptyFolder
                padding: 0px 0px 10px 0px
        .wrapper
            margin: 10px -12px
            min-height: 220px
            max-height: 220px
            padding-top: 5px
            overflow-y: scroll
            scroll-behavior: smooth
            scrollbar-width: none
            +t
                margin: 0
            .table
                display: table
                width: 100%
                +t
                    display: block
                .row
                    display: table-row
                    +t
                        display: none
                    .col
                        position: relative
                        display: table-cell
                        padding: 0 5px 5px
                        vertical-align: middle
                        font-size: 8px !important
                        color: #6F767E
                        font-weight: 500
                        &:after
                            content: ""
                            position: absolute
                            left: 0
                            bottom: 0
                            right: 0
                            height: 1px
                            background: $n3
                            +dark
                                background: $n6
                        &:first-child
                            width: 0px !important
                            &:after
                                left: 12px
                .tableRow
                    display: table-row
                    transition: background .2s
                    &:nth-child(even)
                        background: #F4F4F480    
                    +t
                        position: relative
                        display: block
                        margin-bottom: 24px
                        padding-bottom: 24px
                    .tableCol
                        position: relative
                        display: table-cell
                        vertical-align: middle
                        padding: 10px 5px
                        color: #33383F
                        font-size: 12px
                        &:nth-child(2)
                            width: 130px
                            +x
                                width: 100px
                        &:nth-child(3)
                            width: 120px
                            +x
                                width: 60px
                        +t
                            display: block
                            position: static
                            padding: 0   
                        .overflowTextPS
                            width: 120px
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                            +x
                                width: 110px
                        .overflowTextPM
                            width: 90px
                            white-space: nowrap
                            overflow: hidden
                            text-overflow: ellipsis
                            +x
                                width: 55px
                        img
                            width: 24px
                            height: 24px
                            border-radius: 5px
                        
        .viewAll
            text-align: center
            .all
                text-decoration: underline
                font-size: 12px
                color: #2A85FF