@import ../../../../styles/helpers
.addAppModal
    padding : 0px
    .addAppModalBody
        .modalHeading
            display : flex
            gap : 10px
            margin : 0px 0px 32px 0px
            font-weight: 600
            font-size: 20px
            line-height: 32px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width : 16px 
                height : 32px
                background : #CABDFF
                border-radius : 4px
        .preview
            display : flex
            margin : 0px 0px 32px 0px
            align-items : center
            gap : 15px 
            .previewImage
                width : 94px
                height : 94px
            img
                width : 92px
                height : 92px
                border-radius : 32px
                border : 2px solid #EFEFEF
                object-fit: cover
            input[type="file"]
                display: none !important
            .previewInput
                .save
                    font-size : 13px
                    color : #000
                    font-weight : 400
                    border : 2px solid #EFEFEF
                    padding : 12px 20px
                    border-radius : 12px
                    cursor : pointer
                    font-weight : 700
                    .icon
                        margin : 0px 8px 2px 0px
            .previewCancel
                .cancel
                    font-size : 13px
                    color : #000
                    font-weight : 400
                    border : 2px solid #EFEFEF
                    padding : 12px 20px
                    border-radius : 12px
                    cursor : pointer
                    font-weight : 700

.btns
    margin : 32px 0px 0px 225px
    .save
        width: 112px
        height : 48px
        margin : 0px 10px
        padding : 12px 20px
        font-size : 13px
        color : #000
        font-weight : 700
        background : #2A85FF
        border : 2px solid #2A85FF
        color : #fff
        border-radius : 12px
        cursor : pointer
        &:hover
            background: darken($p1, 10)

.group
    display: flex
    flex-wrap: wrap
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .dropdown
        margin: 0 6px 12px
    .podiumSystem
        margin : 0px 0px 15px 0px
        .podiumGroup
            display : flex
            flex-direction : row
            align-items : center
            gap : 15px
            .rankLabel
                .podiumLabelTxt, .podiumLabelImg
                    cursor : pointer
                    margin : 0px 5px
            .inputField
                width : 56px
                height : 26px
                background : #F4F4F4
                padding : 0px 0px 0px 10px
                border-radius : 4px
    .field, .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            font-size: 14px
            margin : 0px 0px 12px
    .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px