@import ../../../../../styles/helpers

.control
    display: flex
    align-items: center

.button
    display: flex
    justify-content : center
    align-items : center
    flex-shrink: 0
    width: 32px
    height: 32px
    padding : 8px
    background: #F4F4F4
    border-radius: 50%
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin-right: 24px


