@import ../../../styles/helpers

.user
    display: flex
    align-items: center
    position: relative
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    width: 48px
    height: 48px
    border: 2px solid #EFEFEF
    border-radius: 50%
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 15px
    font-weight: bold
    background: #B1E5FC
    letter-spacing: 1px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.headNotification
    width: 28px
    height: 28px
    color: #FFFFFF
    font-weight: 500
    .notificationIcon
        fill: #FFFFFF
    &:hover
        .notificationIcon
            animation: pendulum 0.25s normal ease-in-out 5
            animation-timing-function: linear

@keyframes pendulum
    25%
        transform: rotate(10deg)
    50%
        transform: rotate(0deg)
    75%
        transform: rotate(-10deg)
    100%
        transform: rotate(0deg)

.body
    position: absolute
    top: calc(100% + 10px)
    right: -20px
    width: 375px
    padding: 16px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(5px)
    transition: all .2s
    +x
        top: calc(100% + 5px)
        right: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px

.menu
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 8px
            padding-bottom: 8px
        +dark
            border-color: $n6
    
.item
    display: flex
    align-items: center
    width: 100%
    height: 48px
    padding: 0 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n2
        +dark
            background: transparent
    &.color
        color: $p4
        svg
            fill: $p4
        +dark
            color: $p4
            svg
                fill: $p4
        &:hover
            opacity: .8

.activityTable 
    width: 100%
    overflow: hidden
    p
        padding: 8px
        background: #f4f4f4
        color: #333
        display: inline-block
        border-radius: 8px
        width: 100%
        text-align: center

.activityList 
    list-style: none
    padding: 10px 0px 0px
    margin: 0

.activityItem 
    display: flex
    align-items: center
    padding: 5px
    border-bottom: 1px solid #efefef
    cursor: pointer
    &:last-child
        border-bottom: none
    &:hover
        border-radius: 8px
        background: rgba(249, 198, 38, 0.7)

.iconWrapper 
  margin-right: 10px
  svg
    &.create
        fill: #4D8F65
    &.edit
        fill: #2A85FF
    &.delete
        fill: #B3281D

.activityContent 
    flex: 1
    display: flex
    flex-direction: column

.performedBy 
    align-items: center
    font-size: $font_12
    .activity
        .userdetail
            display: flex
            align-items: center
            justify-content: space-between
            .name
                font-style: italic
                font-weight: 500
                color: #6F767E
            .timeStamp
                color: #6f767e
                font-size: 10px


.description 
    font-weight: 400
    font-size: $font_12
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.cardSave
    text-align: center
    margin-top: 8px

.save
    font-size: $font_12
    display: block
    margin: 0px auto
    color: #2A85FF
    &:hover
        text-decoration: underline