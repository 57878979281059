@import ../../../../styles/helpers

.personalInfo
    .formContainer
        .form
            position: absolute
            left: 20%
            top : 50%
            transform: translate(-20%, -50%)
            width: 100%
            max-width: 600px
            +x
                max-width: 530px
            .formHeader
                .formTitle
                    font-size: 48px
                    line-height: 48px
                    font-weight: 600
                    color: #1A1D1F
                    .blueText
                        color: #2A85FF
                .formContent
                    font-size: 18px
                    line-height: 24px
                    font-weight: 600
                    color: #6F767E
                    padding: 20px 0px 40px 0px
                    +x
                        padding: 20px 0px 20px 0px
            .formBody
                display: flex
                flex-wrap: wrap
                font-size: 14px
                width: 100%
                min-width: 600px
                +x
                    min-width: 530px
                .active
                    cursor: pointer
                    padding: 8px 20px
                    background: #4B6782
                    margin: 14px 12px 14px 0px
                    border-radius: 16px
                    color: #fff
                    +x
                        font-size: 12px
                        margin: 8px 12px 8px 0px
                .inActive
                    cursor: pointer
                    padding: 8px 20px
                    background: #F4F4F4
                    margin: 14px 12px 14px 0px
                    border-radius: 16px
                    color: #272B30
                    +x
                        font-size: 12px
                        margin: 8px 12px 8px 0px
            .formFooter
                display: flex
                align-items: center
                justify-content: space-between
                margin-top: 30px
                .skipButton
                    .Skip
                        color: #1A1D1F
                        font-size: 15px
                        text-decoration: underline
                        font-weight: 600
                .actionButton
                    .Next
                        padding: 10px 24px
                        font-weight: 700
                        background: #2A85FF 
                        border: 2px solid #2A85FF
                        color: #fff
                        border-radius: 8px
                        &:hover
                            background: darken($p1, 10)
                    .Back
                        padding: 10px 24px
                        font-weight: 700
                        background: #FCFCFC 
                        color: #fff
                        border: 2px solid #EFEFEF
                        border-radius: 8px
                        color: #1A1D1F
                        margin-right: 10px