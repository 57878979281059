@import  '../../../../styles/helpers'

.loader
    margin: 100px auto
        
.wrapper
    position: relative
    overflow-x: hidden
    .inner
        .topLeft
            position: absolute
            left: -2%
        .bottomLeft
            position: absolute
            left: -2%
            bottom: 0%
        .middleRight
            position: absolute
            top: 35%
            right: -3%
            transform: rotate(180deg)
        .crossLeftMiddle
            position: absolute
            top: 40%
            left: 5%
        .crossRightTop
            position: absolute
            top: 10%
            right: 5%
        .crossRightBottom
            position: absolute
            bottom: 20%
            right: 5%
        .fullCircle
            position: absolute
            top: 4%
            left: 30%
        .circleBottom
            position: absolute
            bottom: 1%
            left: 40%
        .intercept
            position: absolute
            left: 70%
            bottom: 0%
            .overlapCircle
                position: absolute
                left: -10%
                top: 50%
        .leftFilledCross
            position: absolute
            top: 45%
            left: 5%
        .rightBottomFilledCross
            position: absolute
            top: 7%
            right: 3%
        .rightTopFilledCross
            position: absolute
            bottom: 15%
            right: 7%
        .bottomOutline
            position: absolute
            bottom: 0%
            left: 25%
    .container
        border-radius: 8px
        box-shadow: 0px 0px 3px rgba(133, 133, 133, 0.3)
        margin: 20px 32px
        position: relative
        .billings
            border-radius: 8px
            background: #fff
            padding: 32px 0px
            .title
                text-align: center
                font-size: 22px
                font-weight: 600
                color: #1A1D1F
            .billingContainer
                margin: 20px 32px
                position: relative
                .control
                    .prev
                        height: 36px
                        width: 36px
                        position: absolute
                        top: 50%
                        left: -5%
                        font-size: 24px
                        background: #2A85FF
                        color: #fff
                        border-radius: 50%
                        svg
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)rotate(90deg)
                            fill: #fff
                    .next
                        height: 36px
                        width: 36px
                        position: absolute
                        top: 50%
                        right: -5%
                        font-size: 24px
                        background: #2A85FF
                        color: #fff
                        border-radius: 50%
                        svg
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)rotate(-90deg)
                            fill: #fff
                .plans
                    display: flex
                    justify-content: space-evenly
                    align-items: stretch
                    .planContainer
                        cursor: pointer
                        flex: 0 0 calc(31% - 16px)
                        width: calc(31% - 16px)
                        border: 2px solid #E3E6E9
                        border-radius: 16px
                        padding: 20px
                        &.active
                            border: 2px solid #2A85FF
                            background: #2A85FF
                            .planName, .planDescription, .price, .link, .title, .points, .period
                                color: #fff !important
                            .save
                                background: #FFFFFF !important
                                color: #292930 !important
                            .cube
                                svg
                                    path
                                        fill: #fff !important
                        .planWrapper
                            .planDetails
                                .planName
                                    display: flex
                                    align-items: center
                                    gap: 10px
                                    color: #292930
                                    font-size: 20px
                                    font-weight: 500
                                    margin-bottom: 8px
                                    .identifier
                                        svg
                                            width: 22px
                                            height: 22px
                                .planDescription
                                    color: #999FAE
                                    font-size: 13px
                                    font-weight: 400
                                    min-height: 60px
                                .planPricing
                                    .price
                                        color: #2A85FF
                                        font-size: 28px
                                        font-weight: 600
                                        line-height: 36px
                                    .period
                                        color: #999FAE
                                        font-weight: 500
                                        font-size: 14px
                                        line-height: 14px
                                .action
                                    font-size: 16px
                                    font-weight: 500
                                    text-align: center
                                    .save
                                        cursor: pointer
                                        border: 1px solid #E3E6E9
                                        border-radius: 8px
                                        padding: 8px 16px
                                        font-size: 14px
                                .features
                                    .feature
                                        min-height: 275px
                                        max-height: 400px
                                        overflow-y: scroll
                                        scroll-behavior: smooth
                                        scrollbar-width: none
                                    .link
                                        margin: 24px 0px 16px
                                        text-align: center
                                        color: #6F767E
                                        font-size: 16px
                                        text-decoration: underline
                                        font-weight: 500
                                    .title
                                        margin: 12px 0px 8px
                                        text-align: left
                                        color: #6F767E
                                        font-size: 14px
                                        text-decoration: underline
                                    .points
                                        display: flex
                                        gap: 8px
                                        margin-bottom: 8px
                                        color: #999FAE
                                        line-height: 24px
                                        .cube
                                            svg
                                                width: 20px
                                                height: 21px
                                        .detail
                                            font-size: 13px

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background: rgba($n2, .5)
    visibility: hidden
    opacity: 0
    transition: all .2s
    &.active
        visibility: visible
        opacity: 1