@import '../../../../../styles/helpers'

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    // gap: 20px
    margin: 10px -6px 10px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        // border-right: 2px solid #efefef
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px      
    .table
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .action
            padding: 10px 0px 0px
        .addOnsList
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            align-items: center
            gap: 8px
            margin: 0px 0px 12px 0px
            .addOn
                cursor: pointer
                flex: 0 0 calc(50% - 6px)
                width: calc(50% - 6px)
                border: 2px solid #E3E6E9
                border-radius: 8px
                padding: 10px 12px
                &.disabled
                    cursor: default
                    opacity: 0.5
                .addOnDetail
                    display: flex
                    justify-content: space-between
                    margin-bottom: 8px
                    .detail
                        .name
                            color: #11142D
                            font-size: 14px
                            font-weight: 600
                        .description
                            color: #808191
                            font-size: 12px
                            font-weight: 600
                .addOnPurchase 
                    display: flex
                    justify-content: space-between
                    align-items: end
                    .price
                        background: #2A85FF
                        color: #fff
                        font-weight: 600
                        font-size: 12px
                        padding: 3px 10px
                        border-radius: 6px
                    .quantity
                        display: flex
                        align-items: center
                        gap: 10px
                        font-size: 13px
                        color: #1A1D1F
                        height: 25px
                        .amount
                            font-size: 12px
                            border: 1px solid #E3E6E9
                            border-radius: 4px
                            padding: 0px 5px
                        .operation
                            display: flex
                            flex-direction: column
                            .add
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                height: 12px
                                svg
                                    transform: rotate(-90deg)
                            .remove
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                svg
                                    transform: rotate(90deg)