@import ../../../../../styles/helpers

.addAppModal
    padding : 0px
    .addAppModalBody
        .modalHeading
            display: flex
            gap: 10px
            margin: 0px 0px 20px 0px
            font-weight: 600
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 12px 
                height: 28px
                background: #CABDFF
                border-radius: 4px
        .preview
            display: flex
            margin: 0px 0px 32px 0px
            align-items: center
            gap: 15px 
            .previewImage
                width: 94px
                height: 94px
            img
                width: 92px
                height: 92px
                border-radius: 32px
                border: 2px solid #EFEFEF
                object-fit: cover
            input[type="file"]
                display: none !important
            .previewInput
                .save
                    font-size: 13px
                    color: #000
                    font-weight : 400
                    border: 2px solid #EFEFEF
                    padding: 12px 20px
                    border-radius: 12px
                    cursor: pointer
                    font-weight: 700
                    .icon
                        margin: 0px 8px 2px 0px
            .previewCancel
                .cancel
                    font-size: 13px
                    color: #000
                    font-weight: 400
                    border: 2px solid #EFEFEF
                    padding: 12px 20px
                    border-radius: 12px
                    cursor: pointer
                    font-weight: 700
        .field
            margin: 0px 0px 16px 0px
            .danger
                margin: 5px 0px 0px 0px
                color: red
                font-weight: bold
                font-size: 12px

.btns
    margin: 24px 0px 0px 0px
    display: flex
    align-items: center
    justify-content: left
    gap: 10px