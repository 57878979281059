@import  '../../../../styles/helpers'
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

    
.loading
    margin: 30px 0px
    .loader
        margin: 0px auto
        
.container
    margin: 32px 0px 16px
    .planDetails
        .details
            display: flex
            justify-content: space-between
            align-items: center
            .planAddOns
                .planName
                    font-size: 20px
                    font-weight: 600
                    color: #11142D
                .planDescription
                    color: #808191
                    font-size: 13px
                    font-weight: 500
                    line-height: 18px
        .planFeatures
            margin: 25px 0px 0px
            display: flex
            align-items: stretch
            gap: 24px
            .features
                display: flex
                align-items: center
                flex-wrap: wrap
                width: calc(50% - 12px)
                flex: 1
                position: relative
                .feature
                    display: flex
                    align-items: center
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin-bottom: 12px
                    .title
                        font-size: 16px
                        font-weight: 400
                        color: #808191
                        width: 100%
                        font-family: "Poppins", sans-serif
                .addOns
                    .buyAddOns
                        position: absolute
                        text-align: end
                        width: 250px
                        bottom: 10px
                        right: 24px
                        .addOnsBtn
                            font-size: 13px
                            color: #fff
                            font-weight: 600
                            background: #2A85FF
                            padding: 10px
                            border-radius: 6px
                            svg
                                fill: #fff
            .specter
                svg
                    width: 140px
                    height: 140px
            .seperator
                height: 140px
                width: 2px
                background: #EFEFEF
    .specialOffer
        margin: 32px 0px 0px
        border: 2px solid #EFEFEF
        border-radius: 8px
        .offer
            display: flex
            justify-content: space-between
            align-items: center
            padding: 24px
            .content
                .title
                    font-size: 16px
                    font-weight: 600
                    color: #11142D
                .description
                    font-size: 13px
                    font-weight: 600
                    color: #808191
    .actions
        display: flex
        align-items: center
        gap: 16px
        margin: 32px 0px 0px
    