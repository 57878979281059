@import '../../../../styles/helpers'

.notification
    position: fixed
    top: 40px
    right: 0
    bottom: 0
    z-index: 101
    display: flex
    flex-direction: column
    width: 380px
    padding: 24px 16px
    background: $n
    overflow: auto
    -webkit-overflow-scrolling: touch
    scrollbar-width: none
    transform: translateX(100%)
    transition: transform .3s
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
    +x
        width: 360px
        padding: 24px 16px
    +m
        width: 100%
    &.active
        transform: translateX(0)
        +d
            box-shadow: 4px 0 32px rgba($n8, .05)

.head
    display: flex
    align-items: center
    justify-content: space-between

.close
    cursor: pointer
    margin-left: auto
    svg
        fill: $n7
        transition: transform 0.25s ease
    &:hover
        svg
            transform: rotate(-90deg)
            fill: $p1

.activityTable 
    width: 100%
    .title
        font-size: 18px

.activityList 
    list-style: none
    padding: 20px 0px
    margin: 0
    .warning
        color: #777
        font-weight: 600
        text-align: center
        font-size: 13px
        background: none

.activityItem 
    display: flex
    align-items: center
    padding: 5px
    border-bottom: 1px solid #efefef
    cursor: pointer
    &:last-child
        border-bottom: none
    &:hover
        border-radius: 8px
        background: rgba(249, 198, 38, 0.7)

.iconWrapper 
  margin-right: 10px
  svg
    &.create
        fill: #4D8F65
    &.edit
        fill: #2A85FF
    &.delete
        fill: #B3281D

.activityContent 
    flex: 1
    display: flex
    flex-direction: column

.performedBy 
    align-items: center
    font-size: $font_12
    .activity
        .userdetail
            display: flex
            align-items: center
            justify-content: space-between
            .name
                font-style: italic
                font-weight: 500
                color: #6F767E
            .timeStamp
                color: #6f767e
                font-size: 10px

.description 
    font-weight: 400
    font-size: $font_12
    max-width: 275px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.notificationOverlay
    position: fixed
    top: 40px
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    &.active
        visibility: visible
        opacity: 1