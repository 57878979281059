@import '../../../styles/helpers'

.secondaryNavigation
    width: 100%
    height: 40px
    background: #FCFCFC
    z-index: 1
    .secondaryContainer
        .navigation
            display: flex
            align-items: center
            height: 40px
            padding: 0px 32px 0px 18px
            border-bottom: 2px solid #EFEFEF
            .wrapper
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                .list
                    display: flex
                    align-items: center
                    gap: 15px
                .actionDropdown
                    display: flex
                    align-items: center
                    gap: 20px
                    
.item
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    margin: 0px 6px
    max-width: 140px
    height: 40px
    padding: 0 8px
    font-size: 13px
    font-weight: 500
    white-space: nowrap
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        color: $custom-active-link
        &:after
            content: ''
            position: absolute
            bottom: 0
            left: 8px
            width: calc(100% - 16px)
            height: 2px
            background-color: $custom-active-link
            animation: progress 1s normal forwards ease-in
            animation-timing-function: linear

.button
    height: 28px
    display: flex
    align-items: center
    background: rgb(42, 133, 255)
    color: #fff
    font-size: 12px
    padding: 0px 12px
    border-radius: 8px
    &.dropdown
        padding: 0px 8px
        border-top-left-radius: 8px
        border-bottom-left-radius: 8px
        border-top-right-radius: 0px
        border-bottom-right-radius: 0px
    .add
        .addIcon
            width: 16px
            height: 18px
            padding-bottom: 2px

@keyframes progress
    0%
        width: 0%
    20%
        width: calc(100% - 110px)
    40%
        width: calc(100% - 75px)
    60%
        width: calc(100% - 40px)
    80%
        width: calc(100% - 16px)
    100%