@import ../../../../../../styles/helpers

.addStepsModal
    padding : 24px
    .addStepsContainer
        .addStepsHeader
            .modalHeading
                display : flex
                gap : 10px
                margin : 0px 0px 32px 0px
                font-weight: 600
                font-size: 20px
                line-height: 32px
                letter-spacing: -0.02em
                color: #1A1D1F
                .block
                    width : 16px 
                    height : 32px
                    background : #CABDFF
                    border-radius : 4px
        .addStepsBody
            .addStepsBodyContainer
                .group
                    margin: 15px 0px

