@import ../../styles/helpers

.help
    position: fixed
    top: 0
    right: 0
    bottom: 0
    z-index: 101
    display: flex
    flex-direction: column
    width: 410px
    padding: 24px 24px 0px 24px
    background: $n
    overflow: auto
    -webkit-overflow-scrolling: touch
    transform: translateX(100%)
    transition: transform .3s
    &::-webkit-scrollbar 
        width: 0 !important
        -ms-overflow-style: none
        scrollbar-width: none
    +x
        width: 410px
        padding: 24px 24px 0px 24px
    +m
        width: 100%
    +dark
        background: $n8
    &.active
        transform: translateX(0)
        +d
            box-shadow: 4px 0 32px rgba($n8, .05)
    .helpContainer
        height: 100vh
        position: relative

.head
    display: flex
    align-items: center
    margin-bottom: 0px
    padding: 0px
    & > svg
        margin-right: 12px
        fill: $n7
        +dark
            fill: $n1
    .header
        display : flex
        align-items : center
        gap : 15px
        .headingDash
            width : 16px
            height : 32px
            background : #FFBC99
            border-radius : 5px
        .heading
            font-size : 20px
            font-weight : 600
    
.body
    overflow-x: hidden
    .bodyContainer
        .fileUpload
            margin: 24px 0px
        .preview
            position: absolute
            width: 100%
            .toggle
                display: flex
                margin-left: auto
                margin-top: 20px
                background: #F4F4F4
                border-radius: 8px
                padding: 5px
                .link
                    flex-shrink: 0
                    width: 100%
                    height: 40px
                    border-radius: 8px
                    font-weight: bold
                    p
                        transition: fill .2s
                        width: 100%

                    &.active
                        background: $n1
                        font-weight: bold
                        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

                    &:not(:last-child)
                        margin-right: 0px
            
            .actions
                position: sticky
                padding: 20px 0px
                background: #fff
                bottom: 0px !important
                .buttons
                    text-align: end
                    .save
                        background: #2A85FF
                        color: #fff
                        padding: 8px 16px
                        font-size: 13px
                        font-weight: 700
                        border: 2px solid #2A85FF
                        border-radius: 8px
                        margin-right: 5px
                        &:hover
                            background: darken($p1, 10)
                    .cancel
                        background: #FCFCFC
                        color: #1A1D1F
                        padding: 8px 16px
                        font-size: 13px
                        font-weight: 700
                        border: 2px solid #EFEFEF
                        border-radius: 8px
                        margin-left: 5px
            .previewContainer
                display: flex
                flex-wrap: wrap
                gap: 24px 12px
                margin: 15px 0px
                height: calc(100vh - 384px)
                overflow: scroll
                justify-content: flex-start
                align-content: flex-start
                .previewBox
                    width: 112px
                    height: 112px
                    background-color: #F4F4F4
                    overflow: hidden
                    border-radius: 8px
                    position: relative
                    cursor: pointer
                img
                    width: 100%
                    height: 100%
                    object-fit: contain
                .checkbox
                    z-index: 10000
                    position: absolute
                    top: 5px
                    right: 5px
                    background: green
                    width: 15px
                    height: 15px
                    border-radius: 50%
                    padding: 1px
                    fill: #fff !important
            // .previewContainer
            //     padding: 20px 0px
            //     width: 100%
            //     columns: 3
            //     column-gap: 10px
            //     position: relative
            //     .previewBox
            //         position: relative
            //         max-width: 100%
            //         width: 100%
            //         height: 100%
            //         margin-bottom: 10px
            //         .checkbox
            //             position: absolute
            //             top: 0px
            //             right: -5px
            //             background: green
            //             width: 15px
            //             height: 15px
            //             border-radius: 50%
            //             padding: 1px
            //         svg
            //             fill: #fff !important
            //         img
            //             width: 100%
            //             cursor: pointer
            //             border-radius: 8px

.close
    margin-left: auto
    svg
        fill: $n7
        transition: fill .2s
        +dark
            fill: $n1
    &:hover
        svg
            fill: $p1

.list
    margin-bottom: auto
    padding: 24px 0
    border-top: 1px solid $n3
    +dark
        border-color: rgba($n4, .2)

.menu
    display: flex
    flex-direction: column

.link
    display: flex
    align-items: center
    height: 48px
    padding: 0 12px
    border-radius: 12px
    color: $n4
    transition: all .2s
    svg
        fill: $n4
        transition: fill .2s
    & > svg
        margin-right: 12px
    &:hover
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &:not(:last-child)
        margin-bottom: 0px
        +x
            margin-bottom: 0px

.arrow
    margin-left: auto
    svg
        fill
        
.counter
    flex-shrink: 0
    min-width: 24px
    margin-left: auto
    border-radius: 6px
    background: $s1
    text-align: center
    line-height: 24px
    color: $n7

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)
    &.active
        visibility: visible
        opacity: 1