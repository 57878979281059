@import ../../../../styles/helpers

.wrapper
    padding: 0px 10px

.info
    margin: 16px 0px
    font-weight: 500
    color: $n4

.list
    display: flex
    flex-wrap: wrap
    margin: -32px -16px 0
    +m
        display: block
        margin: 0

.item
    display: flex
    align-items: center
    flex: 0 0 calc(33.33% - 32px)
    width: calc(33.33% - 32px)
    margin: 28px 16px 0
    color: $n7
    cursor: pointer
    +m
        width: 100%
        margin: 0
    +dark
        color: $n1
    &:hover
        .icon
            background: $n3
            +dark
                background: $n6
    &:not(:last-child)
        +m
            margin-bottom: 24px

.icon
    overflow: hidden
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 44px
    height: 44px
    border-radius: 8px
    border: 2px solid $n3
    margin-right: 12px
    transition: background .2s
    img
        width: 100%
        height: 100%
        object-fit: cover
        overflow: hidden
    svg
        fill: $n6
    +dark
        border-color: $n6
        svg
            fill: $n1

.title
    font-size: $font_12
    margin-bottom: 4px
    width: 180px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    color: #000

.line
    display: flex
    align-items: center

.status
    font-size: 10px !important
    line-height: 13px !important
    border-radius: 4px
    padding: 1px 4px
    margin-right: 4px
    color: $n7

.user
    display: flex
    align-items: center
    padding: 1px 8px 1px 1px
    border: 1px solid $n3
    border-radius: 4px
    +dark
        border-color: $n6

.avatar
    flex-shrink: 0
    width: 20px
    height: 20px
    margin-right: 4px
    font-size: 0
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 2px

.action
    +caption2
    color: $n4
