.Toastify__toast{
    min-height: 10px !important;
    max-height: 80px !important;
    border-radius: 8px !important;
    padding: 0px 8px !important;
}

.Toastify__toast-container--top-right {
    width: 300px !important;
    transition: 0.5s ease-in;
}

.Toastify__toast-container--top-left {
    width: 300px !important;
    transition: 0.5s ease-in;
}

.Toastify__close-button{
    opacity: 1 !important;
    transform: translate(-5%, -50%);
    right: 5%;
    top: 50%;
    position: absolute;
    color: #000;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: 0.3s ease-in;
    align-self: flex-start;
}

.Toastify__close-button > svg {
    padding: 0px 0px 2px 1px;
    height: 12px;
    width: 12px;
}

.Toastify__toast--success .Toastify__close-button {
    color: #fff !important;
    background-color: #4D8E65 !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.Toastify__toast--error .Toastify__close-button {
    color: #fff !important;
    background-color: #E45A53 !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.Toastify__toast--warning .Toastify__close-button {
    color: #fff !important;
    background-color: #E9A73B !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.Toastify__toast--info .Toastify__close-button {
    color: #fff !important;
    background-color: #163B63 !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}