@import ../../../styles/helpers

.customTooltip
    position: relative
    display: inline-block
    cursor: pointer
    .tooltip
        cursor: pointer
        width: 100%
        .tooltips
            position: absolute
            top: 100%
            left: 50%
            transform: translateX(-50%)
            z-index: 10 !important
            cursor: pointer !important
            padding: 3px 6px
            opacity: 1
            min-width: 50px
            max-width: 400px
            background: $n
            overflow: hidden
            white-space: nowrap
            border-radius: 8px
            box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
            .text
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                font-size: 10px
                font-weight: 500
            .defaultParams
                display: flex
                flex-direction: column
                margin : 5px 0px
                .heading
                    margin: 0px 0px 8px 0px
                    font-weight: 500
                    font-size: 12px
                    line-height: 12px
                .defaults
                    text-overflow: ellipsis
                    overflow: hidden
                    white-space: nowrap
                    margin: 0px 0px 4px 0px
                    font-weight: 600
                    padding: 2px 4px
                    font-size: 10px
                    line-height: 10px


// @import ../../../styles/helpers

// .customTooltip
//     background: #272B30
//     position: relative
//     display: inline-block
//     cursor: pointer
//     .tooltip
//         cursor: pointer
//         width: 100%
//         .tooltips
//             position: absolute
//             top: 100%
//             left: 50%
//             background: $n
//             cursor: pointer !important
//             padding: 3px 6px
//             border-radius: 8px
//             z-index: 9 !important
//             min-width: 50px
//             max-width: 400px
//             transform: translateX(-50%)
//             border: 1px solid transparent
//             box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
//             .text
//                 text-overflow: ellipsis
//                 overflow: hidden
//                 white-space: nowrap
//                 font-size: 10px
//                 font-weight: 500
//             .defaultParams
//                 display: flex
//                 flex-direction: column
//                 margin: 5px 0px
//                 .heading
//                     margin: 0px 0px 8px 4px
//                     font-weight: 500
//                     font-size: 12px
//                     line-height: 12px
//                     background: red
//                     color: #fff
//                 .defaults
//                     margin: 0px 0px 7px 0px
//                     font-weight: 600
//                     padding: 2px 8px
//                     font-size: 11px
//                     line-height: 11px
//                     border-radius: 4px
