@import '../../../../../styles/helpers'

.warning
    margin-top: 20px

.head
    width: 100px
    margin-left: auto

.tableWrapper
    .group
        .warning
            margin-top: 20px

.pagination
    display: flex
    align-items: center
    justify-content: center
    gap: 25px
    margin: 20px 0px 0px 0px