@import ../../../../../../styles/helpers

.preview
    display: flex
    margin-bottom: 30px
    align-items: center
    gap : 15px
    .previewImage
        width: $preview_width
        height: $preview_height
        img
            width: $preview_width
            height: $preview_height
            border-radius: 16px
            object-fit: cover
            border: 2px solid #EFEFEF
    .previewInput
        .button
            display: flex
            align-items: center
            cursor: pointer
            gap: 8px
            font-size: 12px
            padding: 8px 16px
            font-weight: 600
            background: #2A85FF 
            color: #fff
            border-radius: 8px
            height: 32px
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            .addIcon
                width: 20px
                height: 20px
    .previewCancel
        .buttonCancel
            display: inline-block
            font-weight: 600
            background: #FCFCFC 
            font-size: 12px
            color: #1A1D1F
            border-radius: 8px
            width: 90px
            height: 32px
            border: 2px solid #EFEFEF
            &:hover
                border: 2px solid #1A1D1F

.previewCancel
    +m
        display : none

input[type="file"]
    display: none !important
        
.label-warn
    font-size: 12px
        
.description
    margin : 25px 0px 0px 0px
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px
    .pricing
        margin-top : 25px 
        .labelHeading
            font-size: 14px
            margin-bottom: 14px
        .selectInput
            margin: 15px 0px
    .createPD
        width : 100%
        margin : 30px 0px
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -6px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
            .fieldDummy
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
                background: #F4F4F4
                +m
                    width: 100%
                    margin: 15px 0 0
            .lockGroup
                width : 50%
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 0px
                .selectField
                    flex: 0 0 calc(100% - 12px)
                    width: calc(100% - 12px)
                    cursor : pointer
                    margin: 0 6px 12px
                    +m
                        width: 100%
                        margin: 15px 0 0
                    option
                        font-size: 14px
                        background-color: #ffffff    
                .danger
                    margin: 0 0 0 12px
                    font-weight: bold
                    color: red
                    font-size: 12px
            .variants
                display: flex
                flex-direction: row
                margin : 0px 0px
                .checkbox
                    flex: 0 0 calc(50% - 16px)
                    width: calc(50% - 16px)
                    margin: 16px 16px 0 0
                    +m
                        flex: 0 0 calc(50% - 16px)
                        width: calc(50% - 16px)  

.checkboxGroup
    display: flex
    flex-wrap: wrap
    margin: 10px -6px -12px
    +m
        display: block
        margin: 0
    .field
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 15px 0 0 

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .fieldDummy
        // height: 48px
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        font-size: 14px
        // background: #F4F4F4
        // border-radius: 12px
        +m
            width: 100%
            margin: 15px 0 0
        .sign
            height: $height_36
            font-size: 20px
            margin: 15px 0px 0px 0px
            background: #F4F4F4
            border-radius: 8px
            .infinity
                padding: 0px 10px
    .field, .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .fieldSelect
            width : 100%
        .variants
            display: flex
            flex-direction: row
            margin : 0px 0px
            .checkbox
                flex: 0 0 calc(50% - 16px)
                width: calc(50% - 16px)
                margin: 16px 16px 0 0
                +m
                    flex: 0 0 calc(50% - 16px)
                    width: calc(50% - 16px)
    .podiumSystem
        margin : 0px 0px 15px 0px
        .podiumGroup
            display : flex
            flex-direction : row
            align-items : center
            gap : 15px
            .rankLabel
                .podiumLabelTxt, .podiumLabelImg
                    cursor : pointer
                    margin : 0px 5px
                .podiumLabelImg
                    svg
                        transition: 0.25s ease-in-out
                        &:hover
                            transform: rotate(180deg)
            .inputField
                width : 56px
                height : 26px
                background : #F4F4F4
                padding : 0px 0px 0px 10px
                border-radius : 4px
    .field, .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            font-size: 14px
            margin : 0px 0px 12px 0px
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px
    .pdSystem
        margin : 25px 0px 15px 5px
        .pdSystemGroup
            display: flex
            flex-direction: row
            align-items: center
            gap: 15px
            .pdLabel
                .pdLabelTxt, .pdLabelImg
                    cursor: pointer
                    margin: 0px 5px
                .pdLabelImg
                    svg
                        transition: 0.25s ease-in-out
                        &:hover
                            transform: rotate(180deg)
            .pdUpload
                font-size: 12px
                font-weight: 600
                display: flex
                align-items: center
                justify-content: center
                background: #F4F4F4
                padding: 2px 5px
                cursor: pointer
                border-radius: 4px
                width: 70px
                text-align: center
                label
                    cursor: pointer
                
            .inputField
                width : 56px
                height : 26px
                background : #F4F4F4
                padding : 0px 0px 0px 20px
                border-radius : 4px

.pdGroup
    display: flex
    flex-wrap: wrap
    margin: 32px -6px -12px
    +m
        display: block
        margin: 0
    .fields
        flex: 0 0 calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            font-size: 14px
            margin : 0px 0px 12px 6px
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px

.extra
    font-size: 12px
    margin-left: 2px

.groupByThree
    display: flex
    flex-wrap: wrap
    justify-content : center
    align-items : center
    margin: 15px 15px -12px 50px
    gap : 12px
    .iconEdit
        width: 32px
        height: 32px
    .icon
        cursor: pointer
        transition: 0.25s ease-in-out
        &:hover
            transform: rotate(180deg)
    +m
        display: block
        margin: 0
    .index
        width: 10px
        margin-bottom: 5px
        margin-right : 5px
        .removeIcon
            cursor: pointer
            margin: 0px 10px 0px 0px
    .fieldTitle
        flex: 0 0 calc(30% - 12px)
        width: calc(30% - 12px)
        margin: 0 3px 12px
        &:nth-child(2)
            padding: 0 0px 12px
        &:nth-child(3)
            padding: 0 0px 12px
        &:nth-child(4)
            padding: 0 0px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldDummy
        height: $height_36
        border-radius: 8px
        background: #F4F4F4
        font-size: 20px
        flex: 0 0 calc(29% - 12px)
        width: calc(29% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .sign
            margin: 0px 10px
    .field, .fieldRadio
        flex: 0 0 calc(29% - 12px)
        width: calc(29% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .levelRewards
            display: flex
            align-items: center
            .reward
                flex-grow: 3
                overflow: hidden
                white-space: nowrap
                text-overflow: ellipsis
                font-size: 13px
                font-weight: 600
                color: #33383F
            .editIcon
                cursor: pointer
                margin: 0px 5px 0px 5px
    .remove
        margin: 0px 0px 11px 0px
        .removeIcon
            cursor: pointer
            
.hosting
    margin: 10px 0px

.groupRanking
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: left
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .fieldBlank
        flex: 0 0 calc(20% - 12px)
        width: calc(20% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRank
        flex: 0 0 calc(30% - 12px)
        width: calc(30% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .field
        flex: 0 0 calc(45% - 12px)
        width: calc(45% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRemove
        flex: 0 0 calc(5% - 12px)
        width: calc(5% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0

.competitionEntryRule
    width : 100%
    margin : 30px 0px
    .groupHeading
        margin : 0px 0px 20px 0px
        span
            margin : 0px 8px 0px 0px
        .addIcon
            cursor : pointer
            svg
                transition: 0.25s ease-in-out
                &:hover
                    transform: rotate(180deg)
            
    .groupByVirtualCurrency
        display: flex
        flex-wrap: wrap
        margin: 0 -10px -12px -6px
        +m
            display: block
            margin: 0
        .field, .fieldRadio
            flex: 0 0 calc(32% - 12px)
            width: calc(32% - 12px)
            margin: 0 6px 12px
            +m
                width: 100%
                margin: 15px 0 0 
        .currencies
            display : flex
            flex-direction : column
            flex: 0 0 calc(31% - 12px)
            width: calc(31% - 12px)
            label
                margin-bottom: 14px
                font-size: 14px
                font-weight: 600
            .selectField
                cursor : pointer
                height : 48px
                padding: 0 10px
                border-radius: 12px
                border: 2px solid transparent
                background: #F4F4F4
                option
                    font-size: 14px
                    background-color: #ffffff                    

        .remove
            margin: 0 16px 12px
            position : relative
            .icon
                cursor : pointer
                position : absolute
                left : 25%
                top : 60%
                transform : translate(-60%)
            +m
                display : none
        .variants
            display: flex
            flex-direction: row
            margin : 25px 0px
            .radio
                margin : 0px 15px 0px 0px

    .groupByIAP
        display: flex
        flex-wrap: wrap
        margin: 0 -18px -12px -6px
        +m
            display: block
            margin: 0
        .field
            flex: 0 0 calc(25% - 25px)
            width: calc(25% - 25px)
            margin: 0 7px 12px
            +m
                width: 100%
                margin: 15px 0 0          
        .currencies
            display : flex
            flex-direction : column
            flex: 0 0 calc(25% - 12px)
            width: calc(25% - 12px)
            margin: 0 6px 12px
            +m
                display: block
                width: 100%
                margin: 15px 0 0
            label
                margin-bottom: 14px
                font-size: 14px
                font-weight: 600
            .selectField
                cursor : pointer
                height : 48px
                padding: 0 10px
                border-radius: 12px
                border: 2px solid transparent
                background: #F4F4F4
                width: 100%
                option
                    cursor : pointer
                    font-size: 14px
                    background-color: #ffffff  
        .remove
            margin: 0 16px 12px
            position : relative
            .icon
                cursor : pointer
                position : absolute
                left : 25%
                top : 60%
                transform : translate(-60%)
            +m
                display : none
            
    .groupByRMG
        display: flex
        flex-wrap: wrap
        margin: 0 -18px -12px -6px
        +m
            display: block
            margin: 0
        .field
            flex: 0 0 calc(20% - 25px)
            width: calc(20% - 25px)
            margin: 0 7px 12px
            +m
                width: 100%
                margin: 15px 0 0         
        .currencies
            display : flex
            flex-direction : column
            flex: 0 0 calc(20% - 12px)
            width: calc(20% - 12px)
            margin: 0 6px 12px
            +m
                display: block
                width: 100%
                margin: 15px 0 0
            label
                margin-bottom: 14px
                font-size: 14px
                font-weight: 600
            .selectField
                cursor : pointer
                height : 48px
                padding: 0 10px
                border-radius: 12px
                border: 2px solid transparent
                background: #F4F4F4
                width: 100%
                option
                    cursor : pointer
                    font-size: 14px
                    background-color: #ffffff  
        .remove
            margin: 0 16px 12px
            position : relative
            .icon
                cursor : pointer
                position : absolute
                left : 25%
                top : 60%
                transform : translate(-60%)
            +m
                display : none

.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.cardDescription
    margin: 16px 0px
    .createCurrency
        margin : 20px 0px
        .multiselect
            margin : 25px 0px
            .tagsInput
                cursor: text !important
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
        .groupHeading
            .addIcon
                cursor : pointer
                margin : 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 25px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 12px)
                width: calc(48% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    right : -45px
                    top : 50%
                    transform : translate(-45%, -70%)
                +m
                    display : none

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px