@import ../../../../styles/helpers

.header
    position: sticky
    top: 0
    z-index: 10
    display: flex
    align-items: center
    padding: 10px 40px
    background: $n1
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3

.mainHeader
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between

.headContent
    display: flex
    align-items: center
    gap: 20px
    padding-right: 10px

.control
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.control .button,
.control > div
    &:not(:last-child)
        margin-right: 24px

.headHelp
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid #EFEFEF
    color: #1A1D1F
    font-weight: 500