@import ../../../../../styles/helpers

.addApps
    .control
        display: flex
        align-items: center
        background: #2a85ff
        color: #fff
        font-size: 12px
        height: 28px
        padding: 0px 12px
        gap: 6px
        border-radius: 8px
        &.small
            height: 36px
        &.tiny
            height: 28px
    &:hover
        svg
            transform: rotate(90deg)
    svg
        width: 16px
        height: 16px
        fill: none !important
        transition: 0.25s ease
            