@import ../../../../../styles/helpers

.addContentModal
    .addContent
        .modalHeading
            display : flex
            gap : 10px
            margin : 0px 0px 32px 0px
            font-weight: 600
            font-size: 20px
            line-height: 32px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width : 16px 
                height : 32px
                background : #CABDFF
                border-radius : 4px
        .modalBody
            .contentBody
                .payout
                    margin : 30px 0px
                    .group
                        display: flex
                        flex-wrap: wrap
                        margin: 0 -6px -12px
                        +m
                            display: block
                            margin: 0
                        .field, .fieldRadio
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                            +m
                                width: 100%
                                margin: 15px 0 0
                            .radioLabel
                                margin : 0px 0px 27px 6px
                                font-size: 14px
                                font-weight: 600
                                color: #33383F
                            .variants
                                display: flex
                                flex-direction: row
                                margin : 0px 0px
                            .radio
                                margin : 0px 15px 0px 0px
        .modalFooter
            padding-top: 32px
            .btnGroup
                display: flex
                align-items: center
                .save
                    text-align : center
                    background : $custom-active-link
                    width: 80px
                    height: 36px
                    border-radius: 8px
                    font-weight: 500
                    color: #FCFCFC
                    font-family: Inter
                    font-size: 13px
                .cancel
                    text-align: center
                    background: #FCFCFC
                    width: 80px
                    height: 36px
                    border-radius: 8px
                    font-weight: 500
                    color: #1A1D1F
                    font-family: Inter
                    font-size: 13px
                    margin: 0px 10px
                    border : 2px solid #EFEFEF
