@import ../../../../../styles/helpers

.container
    .deposit
        .modalHeading
            display: flex
            flex: 1
            gap: 10px
            margin: 0px 0px 32px 0px
            font-weight: 600
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 12px 
                height: 28px
                background: #FFBC99
                border-radius: 4px
        .modalBody
            .toggle
                display: flex
                flex-wrap: wrap
                margin-left: auto
                margin-top: 12px
                background: #F4F4F4
                border-radius: 8px
                padding: 4px
                .link
                    font-size: 14px
                    flex-shrink: 0
                    width: 100%
                    height: 32px
                    border-radius: 8px
                    padding: 0px 4px
                    p
                        transition: fill .2s
                    &:hover
                        p
                            font-weight: bold
                    &.active
                        background: $n1
                        font-weight: bold
                        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
                    &.disabled
                        opacity: 0.5
                        pointer-events: none
                        cursor: not-allowed
                    &:not(:last-child)
                        margin-right: 0px
            .wrapper
                min-height: 350px
                max-height: 400px
                overflow-y: scroll
                scroll-behavior: smooth
                scrollbar-width: none
                margin: 16px 4px
                // box-shadow: 0px 0px 3px rgba(133, 133, 133, 0.3)
                border: 1px solid rgba(133, 133, 133, 0.3)
                border-radius: 8px
                padding: 12px
                .group
                    display: flex
                    flex-wrap: wrap
                    align-items: flex-end
                    margin: 25px -6px -12px
                    .switch
                        margin-left: 12px
                    .info
                        margin-bottom: 12px
                        font-size: 14px
                        color: #33383f
                        font-weight: 600
                    .switchField
                        display: flex
                        align-items: center
                        flex: 0 0 calc(50% - 20px)
                        width: calc(50% - 20px)
                        margin: 0 10px 12px
                    .field
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 6px 12px

.modalFooter
    display: flex
    gap: 12px