
  .ReactTags__tags {
    position: relative;
    padding: 0px 0px !important;
    border: 1px solid #EFEFEF !important;
    background-color: #FCFCFC !important;
  }
  
  .ReactTags__clearAll {
    display: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;

  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    margin: 0;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    padding: 0px 15px;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag:nth-child(3n){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #FFBC99 !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected span.ReactTags__tag:nth-child(3n+1){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #E4E4E4 !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected span.ReactTags__tag:nth-child(3n+2){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #B5E4CA !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    width: 24px;
    height: 24px;
    margin: 0 0 0 8px;
    border: none;
    cursor: pointer;
    background: none;
    background: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 fill=%27none%27 viewBox=%270 0 24 24%27%3E%3Cpath d=%27M6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293z%27 fill=%27%23fcfcfc%27/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto;
    font-size: 0;
    color: #FFFFFF;
    transition: transform 0.2s;
  }