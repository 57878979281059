@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 46px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            // .control
            //     visibility: visible
            //     opacity: 1

.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:last-child
        padding-right: 164px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .account
        width: 130px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        font-size: $table-content
        .linkedAccount
            background: #FFFFFF
            padding: 2px 8px
            border-radius: 4px
            display: flex
            align-items: center
            gap : 5px
            .account
                width: 80px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
    .itemName
        font-size: $table-content
        width: 180px
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        line-height: 16px
        

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .preview
        display: flex
        width: $icon-width
        height: $icon-height
        background : #EFEFEF
        margin-right: 10px
        border-radius: 8px
        position : relative
        +t
            width: $icon-width
            height: $icon-height
        img
            width: $icon-width
            height: $icon-height
            border: 2px solid #EFEFEF
            border-radius: 8px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
    .playerDetails
        display: inline-block
        .id
            font-weight: 500
            width: 175px
            font-size: $table-grey-content
            color: #6F767E
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    // visibility: hidden
    // opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px