@import '../../../styles/helpers'

.custom
    cursor: pointer
    position: relative
    width: 100%
    &.active
        .tooltip
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 200px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 12px
        
.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% + 5px)
    left: 0px
    width: 150px
    padding: 8px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-5px)
    transition: all 0.3s
    +x
        top: calc(100% + 5px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    .groupHeading
        margin-bottom: 4px
        .heading
            color: #EFEFEF
            font-weight: 500
            font-size: 12px
            line-height: 12px
    .production
        position: relative
        width: 20px
        height: 20px
        background: #33383F
        border-radius: 4px
        .logo
            width: 12px
            width: 12px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #00D59C
    .staging
        position: relative
        width: 20px
        height: 20px
        background: #33383F
        border-radius: 4px
        .logo
            width: 12px
            width: 12px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #9747FF
    .develop
        position: relative
        width: 20px
        height: 20px
        background: #33383F
        border-radius: 4px
        .logo
            width: 12px
            width: 12px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #FFD944
    .appName
        cursor: pointer
        flex: 2
        .logoText
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            font-size: 15px
            font-weight: 600
            line-height: 24px
            color: #272B30
            +x
                font-size: 14px
            +d
                display: none
        .env
            font-size: 10px
            font-weight: 600
            color: #838383
            &:hover
                color: #1A1D1F
        
.item
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 12px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        background : #272B30
        color : #F4F4F4
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)
    .staging
        background: $staging-bg
        width: 12px
        height: 12px
        border-radius: 50%
    .develop
        background: #9747FF
        width: 12px
        height: 12px
        border-radius: 50%
    .production
        background: $production-bg
        width: 12px
        height: 12px
        border-radius: 50%


.itemContainer
    display: flex
    align-items: center
    gap: 5px
    width: 100%
    padding: 2px 5px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s   
    margin: 5px 0px
    &.disabled
        opacity: 0.5
        pointer-events: none !important
        cursor: not-allowed !important
    &:not(&.disabled)
        &:hover
            background: $grey_hover
    &:not(&.disabled)
        &.active
            background: #33383F
            p
                color: #fff !important