// @import ../../../../styles/helpers

// .addMembersModal
//     .addMemberContainer
//         .memberHeader
//             width: 90%
//             display: flex
//             align-items: center
//             justify-content: space-between
//             margin: -2px 0px
//             .headingContainer
//                 display: flex
//                 align-items: center
//                 gap: 10px
//                 .headingBlock
//                     width: 12px
//                     height: 28px
//                     background: #CABDFF
//                     border-radius: 4px
//                 .headingContent
//                     font-size: 16px
//                     font-weight: 600
//                     line-height: 24px
//             .memberLink
//                 border: 2px solid #EFEFEF
//                 border-radius: 8px
//                 padding: 8px 16px
//                 cursor: pointer
//                 .shareLink
//                     .linkText
//                         margin-left: 5px
//                         font-size: 13px
//                         line-height: 24px
//                         font-weight: 700
//         .memberBody
//             padding: 32px 0px
//             &.active
//                 padding: 10px 0px
//             .inviteBody
//                 .inviteForm
//                     display: flex
//                     align-items: center
//                     justify-content: space-between
//                     .field
//                         width: 85%
//                     span
//                         font-size: 13px
//                         font-weight: 700
//                 .memberLists
//                     padding-top: 16px
//                     .checkAll
//                         display: flex
//                         align-content: center
//                         justify-content: space-between
//                         margin: 12px 0px
//                         .title
//                             font-size: 14px
//                         .apps
//                             font-size: 12px
//                             font-weight: 600
//                             color: #272B30
//                     .appList
//                         min-height: 120px
//                         max-height: 200px
//                         overflow-y: scroll
//                         scrollbar-width: none
//                         .warning
//                             padding: 20px 0px
//                             color: #6F767E
//                             text-align: center
//                         .membersList
//                             margin: 5px 0px
//                             display: flex
//                             align-items: center
//                             justify-content: space-between
//                             .player
//                                 flex: 3
//                                 display: flex
//                                 gap: 8px
//                                 .playerInsigne
//                                     width: 32px
//                                     height: 32px
//                                     overflow: hidden
//                                     .appIcon
//                                         width: 32px
//                                         height: 32px
//                                         border-radius: 8px
//                                 .playerDetails
//                                     .userName
//                                         font-size: $table-content
//                                         line-height: 20px
//                                         color: #33383F
//                                         font-weight: 600
//                                     .playerId
//                                         font-size: $table-grey-content
//                                         line-height: 12px
//                                         color: #6F767E
//                                         font-weight: 500
//                             .memberPermission
//                                 flex : 1
//         .memberFooter
//             display: flex
//             align-items: center
//             gap: 10px
// .seprator
//     width: 100%
//     height: 1px
//     border-radius: 1px
//     background: #EFEFEF

@import ../../../../styles/helpers

// .addMembersModal
//   width: 100%
//   .addMemberContainer
//     background: #fff
//     border-radius: 8px
//     padding: 24px

//     .memberHeader
//       display: flex
//       align-items: center
//       justify-content: space-between
//       margin-bottom: 16px

//       .headingContainer
//         display: flex
//         gap: 10px
//         align-items: center

//         .headingBlock
//           width: 12px
//           height: 28px
//           background: #CABDFF
//           border-radius: 4px

//         .headingContent
//           font-size: 18px
//           font-weight: 600
//           line-height: 24px
//           color: #272B30

//     .memberBody
//       &.active
//         // if you want a different style for 'edit' mode
//         // example: background: #fafafa

//       .inviteBody
//         margin-bottom: 24px
//         .inviteForm
//           display: flex
//           align-items: center
//           gap: 8px

//           .field
//             flex: 1

//           .button
//             // style your button if needed

//       .memberLists
//         .sectionHeader
//           margin-bottom: 8px
//           .sectionTitle
//             font-size: 16px
//             font-weight: 600
//             margin-bottom: 4px
//           .sectionDescription
//             font-size: 14px
//             color: #6F767E

//         .seprator
//           width: 100%
//           height: 1px
//           background: #EFEFEF
//           margin: 16px 0

//         .orgLevelBlock
//           margin-bottom: 16px

//         .appAccessSection
//           background: #fcfcfc
//           border: 1px solid #ddd
//           border-radius: 8px
//           padding: 16px
//           margin-bottom: 16px

//           .checkAll
//             display: flex
//             align-items: center
//             justify-content: space-between
//             .title
//               font-size: 14px
//               font-weight: 600

//           .appList
//             margin-top: 16px
//             .membersList
//               display: flex
//               align-items: center
//               justify-content: space-between
//               margin-bottom: 8px

//               .player
//                 display: flex
//                 gap: 8px
//                 .playerInsigne
//                   width: 40px
//                   height: 40px
//                   img.appIcon
//                     width: 100%
//                     height: 100%
//                     border-radius: 6px

//                 .playerDetails
//                   display: flex
//                   flex-direction: column
//                   justify-content: center
//                   .userName
//                     font-size: 14px
//                     font-weight: 600
//                     color: #33383F
//                   .playerId
//                     font-size: 12px
//                     color: #6F767E
//               .check
//                 margin-left: auto
//                 .checkbox
//                   margin-left: 8px

//           .warning
//             color: #6F767E
//             text-align: center
//             font-size: 14px
//             margin: 16px 0

//         .appDetailsSection
//           border: 1px solid #ddd
//           border-radius: 8px
//           background: #fcfcfc
//           padding: 16px

//           .checkAll
//             display: flex
//             align-items: center
//             justify-content: space-between
//             margin-bottom: 8px
//             .title
//               font-size: 14px
//               font-weight: 600

//           .appDetailsCard
//             margin-bottom: 16px
//             border: 1px solid #ddd
//             border-radius: 8px
//             background: #fff

//             .appDetailsHeader
//               width: 100%
//               display: flex
//               align-items: center
//               padding: 12px
//               background: none
//               border: none
//               text-align: left
//               cursor: pointer
//               &:hover
//                 background: #f7f7f7

//               .player
//                 display: flex
//                 gap: 8px
//                 .playerInsigne
//                   width: 32px
//                   height: 32px
//                   img.appIcon
//                     width: 100%
//                     height: 100%
//                     border-radius: 6px
//                 .playerDetails
//                   .userName
//                     font-size: 14px
//                     font-weight: 600
//                   .playerId
//                     font-size: 12px
//                     color: #6F767E

//               .chevron
//                 margin-left: auto
//                 transition: transform 0.3s ease

//             .appDetailsContent
//               padding: 16px
//               border-top: 1px solid #ddd

//       .memberFooter
//         margin-top: 24px
//         display: flex
//         gap: 8px
//         justify-content: flex-end

//         .button
//           padding: 8px 16px
//           border-radius: 6px
//           font-size: 14px
//           cursor: pointer
//           &.button-white-small
//             background: #fff
//             border: 1px solid #ddd

// // ─────────────────────────────────────────────────────────────
// // Collapsible UI Reusables

// .collapsibleSection
//   margin-bottom: 16px
//   border: 1px solid #ddd
//   border-radius: 8px
//   background: #fff

// .collapsibleHeader
//   width: 100%
//   display: flex
//   align-items: center
//   justify-content: space-between
//   padding: 12px
//   background: none
//   border: none
//   cursor: pointer

//   &:hover
//     background: #f7f7f7

// .collapsibleTitle
//   display: flex
//   align-items: center
//   gap: 8px
//   font-size: 14px
//   font-weight: 600

// .chevron
//   margin-left: auto
//   transition: transform 0.3s ease

// .rotate
//   transform: rotate(180deg)

// .collapsibleContent
//   border-top: 1px solid #ddd
//   padding: 12px

// .innerCollapsible
//   margin-top: 8px
//   background: #fcfcfc
//   border: 1px solid #ececec

// .innermostCollapsible
//   margin-top: 8px
//   border: 1px dashed #ddd
//   background: #fff

// // Permission Checkboxes

// .permissionCheckbox
//   display: inline-flex
//   align-items: center
//   margin-right: 16px
//   margin-bottom: 8px
//   input
//     margin-right: 6px
//   span
//     font-size: 14px

// .checkboxRow
//   display: flex
//   flex-wrap: wrap
//   margin-top: 8px

// .permBlock
//   margin-top: 8px
//   padding: 8px
//   background: #fff
//   border-radius: 4px
//   border: 1px solid #f0f0f0

// // Select All Toggle
// .selectAllToggle
//   display: flex
//   align-items: center
//   justify-content: flex-end
//   margin-bottom: 8px

// .toggleLabel
//   margin-right: 8px
//   font-size: 14px
//   color: #555

// .toggleSwitch
//   position: relative
//   display: inline-block
//   width: 40px
//   height: 20px

//   input
//     opacity: 0
//     width: 0
//     height: 0

//   .slider
//     position: absolute
//     cursor: pointer
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     background-color: #ccc
//     transition: 0.4s
//     border-radius: 20px

//     &:before
//       position: absolute
//       content: ""
//       height: 16px
//       width: 16px
//       left: 2px
//       bottom: 2px
//       background-color: white
//       transition: 0.4s
//       border-radius: 50%

//   input:checked + .slider
//     background-color: #0066cc

//   input:checked + .slider:before
//     transform: translateX(20px)

// @import ../../../../styles/helpers

// .addMembersModal
//   width: 100%

//   .addMemberContainer
//     background: #fff
//     border-radius: 8px
//     padding: 24px

//     .memberHeader
//       display: flex
//       align-items: center
//       justify-content: space-between
//       margin-bottom: 16px

//       .headingContainer
//         display: flex
//         gap: 10px
//         align-items: center

//         .headingBlock
//           width: 12px
//           height: 28px
//           background: #CABDFF
//           border-radius: 4px

//         .headingContent
//           font-size: 18px
//           font-weight: 600
//           line-height: 24px
//           color: #272B30

//     .memberBody
//       &.active
//         // Example: background for "edit" mode
//         // background: #fafafa

//       .inviteBody
//         margin-bottom: 24px

//         .inviteForm
//           display: flex
//           align-items: center
//           gap: 8px

//           .field
//             flex: 1

//           .button
//             // Basic invite button styling
//             font-size: 14px
//             font-weight: 600
//             padding: 8px 16px
//             border-radius: 6px
//             background: #6C5DD3
//             color: #fff
//             cursor: pointer
//             border: none
//             &:hover
//               background: #5845c3

//       .memberLists
//         .sectionHeader
//           margin-bottom: 8px

//           .sectionTitle
//             font-size: 16px
//             font-weight: 600
//             margin-bottom: 4px

//           .sectionDescription
//             font-size: 14px
//             color: #6F767E

//         .seprator
//           width: 100%
//           height: 1px
//           background: #EFEFEF
//           margin: 16px 0

//         .orgLevelBlock
//           margin-bottom: 16px

//         .appAccessSection
//           background: #fcfcfc
//           border: 1px solid #ddd
//           border-radius: 8px
//           padding: 16px
//           margin-bottom: 16px

//           .checkAll
//             display: flex
//             align-items: center
//             justify-content: space-between

//             .title
//               font-size: 14px
//               font-weight: 600

//           .appList
//             margin-top: 16px

//             .membersList
//               display: flex
//               align-items: center
//               justify-content: space-between
//               margin-bottom: 8px

//               .player
//                 display: flex
//                 gap: 8px

//                 .playerInsigne
//                   width: 40px
//                   height: 40px

//                   img.appIcon
//                     width: 100%
//                     height: 100%
//                     border-radius: 6px

//                 .playerDetails
//                   display: flex
//                   flex-direction: column
//                   justify-content: center

//                   .userName
//                     font-size: 14px
//                     font-weight: 600
//                     color: #33383F

//                   .playerId
//                     font-size: 12px
//                     color: #6F767E

//               .check
//                 margin-left: auto

//                 .checkbox
//                   margin-left: 8px

//           .warning
//             color: #6F767E
//             text-align: center
//             font-size: 14px
//             margin: 16px 0

//         .appDetailsSection
//           border: 1px solid #ddd
//           border-radius: 8px
//           background: #fcfcfc
//           padding: 16px

//           .checkAll
//             display: flex
//             align-items: center
//             justify-content: space-between
//             margin-bottom: 8px

//             .title
//               font-size: 14px
//               font-weight: 600

//           .appDetailsCard
//             margin-bottom: 16px
//             border: 1px solid #ddd
//             border-radius: 8px
//             background: #fff

//             .appDetailsHeader
//               width: 100%
//               display: flex
//               align-items: center
//               padding: 12px
//               background: none
//               border: none
//               text-align: left
//               cursor: pointer

//               &:hover
//                 background: #f7f7f7

//               .player
//                 display: flex
//                 gap: 8px

//                 .playerInsigne
//                   width: 32px
//                   height: 32px

//                   img.appIcon
//                     width: 100%
//                     height: 100%
//                     border-radius: 6px

//                 .playerDetails
//                   .userName
//                     font-size: 14px
//                     font-weight: 600
//                   .playerId
//                     font-size: 12px
//                     color: #6F767E

//               .chevron
//                 margin-left: auto
//                 transition: transform 0.3s ease

//             .appDetailsContent
//               padding: 16px
//               border-top: 1px solid #ddd

//       .memberFooter
//         margin-top: 24px
//         display: flex
//         gap: 8px
//         justify-content: flex-end

//         .button
//           padding: 8px 16px
//           border-radius: 6px
//           font-size: 14px
//           cursor: pointer

//           &.button-small
//             // If you have separate "small" button styling
//             font-size: 13px
//             padding: 6px 12px

//           &.button-white-small
//             background: #fff
//             border: 1px solid #ddd

// // ─────────────────────────────────────────────────────────────
// // Collapsible UI Reusables

// .collapsibleSection
//   margin-bottom: 16px
//   border: 1px solid #ddd
//   border-radius: 8px
//   background: #fff

// .collapsibleHeader
//   width: 100%
//   display: flex
//   align-items: center
//   justify-content: space-between
//   padding: 12px
//   background: none
//   border: none
//   cursor: pointer

//   &:hover
//     background: #f7f7f7

// .collapsibleTitle
//   display: flex
//   align-items: center
//   gap: 8px
//   font-size: 14px
//   font-weight: 600

// .chevron
//   margin-left: auto
//   transition: transform 0.3s ease

// .rotate
//   transform: rotate(180deg)

// .collapsibleContent
//   border-top: 1px solid #ddd
//   padding: 12px

// .innerCollapsible
//   margin-top: 8px
//   background: #fcfcfc
//   border: 1px solid #ececec

// .innermostCollapsible
//   margin-top: 8px
//   border: 1px dashed #ddd
//   background: #fff

// // Permission Checkboxes
// .permissionCheckbox
//   display: inline-flex
//   align-items: center
//   margin-right: 16px
//   margin-bottom: 8px

//   input
//     margin-right: 6px

//   span
//     font-size: 14px

// .checkboxRow
//   display: flex
//   flex-wrap: wrap
//   margin-top: 8px

// .permBlock
//   margin-top: 8px
//   padding: 8px
//   background: #fff
//   border-radius: 4px
//   border: 1px solid #f0f0f0

// // Select All Toggle
// .selectAllToggle
//   display: flex
//   align-items: center
//   justify-content: flex-end
//   margin-bottom: 8px

// .toggleLabel
//   margin-right: 8px
//   font-size: 14px
//   color: #555

// .toggleSwitch
//   position: relative
//   display: inline-block
//   width: 40px
//   height: 20px

//   input
//     opacity: 0
//     width: 0
//     height: 0

//   .slider
//     position: absolute
//     cursor: pointer
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     background-color: #ccc
//     transition: 0.4s
//     border-radius: 20px

//     &:before
//       position: absolute
//       content: ""
//       height: 16px
//       width: 16px
//       left: 2px
//       bottom: 2px
//       background-color: white
//       transition: 0.4s
//       border-radius: 50%

//   input:checked + .slider
//     background-color: #0066cc

//   input:checked + .slider:before
//     transform: translateX(20px)



.addMembersModal
  width: 100%
  .addMemberContainer
    border-radius: 8px
    .memberHeader
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 24px
      .headingContainer
        display: flex
        gap: 10px
        align-items: center
        .headingBlock
          width: 12px
          height: 28px
          background: #CABDFF
          border-radius: 4px
        .headingContent
          font-size: 16px
          font-weight: 600
          line-height: 20px
          color: #272B30
    .memberBody
      &.active
        // if you want a different style for 'edit' mode
        // example: background: #fafafa
      .inviteBody
        margin-bottom: 24px
        .inviteForm
          display: flex
          align-items: center
          gap: 8px
          .field
            flex: 1
          .save
            height: 36px
            display: flex
            align-items: center
            background: rgb(42, 133, 255)
            color: #fff
            font-size: 12px
            padding: 0px 16px
            border-radius: 8px

      .memberLists
        .sectionHeader
          .sectionTitle
            font-size: 15px
            font-weight: 600
          .sectionDescription
            font-size: 13px
            color: #6F767E

        .seprator
          width: 100%
          height: 1px
          background: #EFEFEF
          margin: 12px 0

        .orgLevelBlock
          margin-bottom: 16px

        .appAccessSection
          background: #fcfcfc
          border: 1px solid #ddd
          border-radius: 8px
          padding: 16px
          margin-bottom: 16px

          .checkAll
            display: flex
            align-items: center
            justify-content: space-between
            .title
              font-size: 14px
              font-weight: 600

          .appList
            margin-top: 16px
            .membersList
              display: flex
              align-items: center
              // justify-content: space-between
              cursor: pointer
              .player
                display: flex
                align-items: center
                gap: 8px
                .playerInsigne
                  width: 36px
                  height: 36px
                  overflow: hidden
                  img.appIcon
                    width: 100%
                    height: 100%
                    border-radius: 6px
                .playerDetails
                  display: flex
                  flex-direction: column
                  justify-content: center
                  .userName
                    font-size: 13px
                    line-height: 16px
                    font-weight: 600
                    color: #33383F
                  .playerId
                    font-size: 11px
                    color: #6F767E
              .check
                // margin-left: auto
                .checkbox
                  margin-left: 8px
                  margin-right: 16px

          .warning
            color: #6F767E
            text-align: center
            font-size: 14px
            margin: 16px 0

        .appDetailsSection
          border: 1px solid #ddd
          border-radius: 8px
          background: #fcfcfc
          padding: 16px

          .checkAll
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 8px
            .title
              font-size: 14px
              font-weight: 600
          .appDetailsCard
            margin-bottom: 12px
            border: 1px solid #ddd
            border-radius: 8px
            background: #fff

            .appDetailsHeader
              width: 100%
              display: flex
              align-items: center
              padding: 8px 12px
              background: none
              border: none
              text-align: left
              cursor: pointer
              border-radius: 8px
              &:hover
                background: #f7f7f7

              .player
                display: flex
                align-items: center
                gap: 8px
                .playerInsigne
                  width: 36px
                  height: 36px
                  overflow: hidden
                  img.appIcon
                    width: 100%
                    height: 100%
                    border-radius: 6px
                .playerDetails
                  display: flex
                  flex-direction: column
                  justify-content: center
                  .userName
                    font-size: 13px
                    line-height: 16px
                    font-weight: 600
                    color: #33383F
                  .playerId
                    font-size: 11px
                    color: #6F767E

              .chevron
                margin-left: auto
                transition: transform 0.3s ease

            .appDetailsContent
              padding: 16px
              border-top: 1px solid #ddd

      .memberFooter
        margin-top: 24px
        display: flex
        gap: 8px
        justify-content: flex-end

      .button
        padding: 8px 16px
        border-radius: 8px
        font-size: 14px
        cursor: pointer
        min-width: fit-content
        &.button-white-small
          background: #fff
          border: 1px solid #ddd

// ─────────────────────────────────────────────────────────────
// Collapsible UI Reusables

.collapsibleSection
  margin-bottom: 12px
  border: 1px solid #ddd
  border-radius: 8px
  background: #fff

.collapsibleHeader
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  padding: 8px 12px
  background: none
  border: none
  cursor: pointer
  border-radius: 8px
  &:hover
    background: #f7f7f7

.collapsibleTitle
  display: flex
  align-items: center
  gap: 8px
  font-size: 13px
  font-weight: 600

.chevron
  margin-left: auto
  transition: transform 0.3s ease

.rotate
  transform: rotate(180deg)

.collapsibleContent
  border-top: 1px solid #ddd
  padding: 12px

.innerCollapsible
  margin-top: 8px
  background: #fcfcfc
  border: 1px solid #ececec

.innermostCollapsible
  margin-top: 12px
  border: 1px dashed #ddd
  background: #fff

// Permission Checkboxes

.permissionCheckbox
  display: inline-flex
  align-items: center
  margin-right: 16px
  margin-bottom: 8px
  cursor: pointer
  input
    margin-right: 6px
  span
    font-size: 13px

.checkboxRow
  display: grid
  grid-template-columns: repeat(4, 1fr)
  column-gap: 8px
  margin-top: 12px

.permBlock
  padding: 8px
  background: #fff
  border-radius: 4px
  border: 1px solid #f0f0f0

// Select All Toggle
.selectAllToggle
  display: flex
  align-items: center
  justify-content: flex-end
  margin-bottom: 8px

.toggleLabel
  margin-right: 8px
  font-size: 12px
  color: #555

.toggleSwitch
  position: relative
  display: inline-block
  width: 36px
  height: 18px

  input
    opacity: 0
    width: 0
    height: 0

  .slider
    position: absolute
    cursor: pointer
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ccc
    transition: 0.4s
    border-radius: 20px
    &:before
      position: absolute
      content: ""
      height: 14px
      width: 14px
      left: 2px
      bottom: 2px
      background-color: white
      transition: 0.4s
      border-radius: 50%

  input:checked + .slider
    background-color: #0066cc

  input:checked + .slider:before
    transform: translateX(18px)



.itemContainer
    display: flex
    align-items: center
    gap: 5px
    width: 100%
    padding: 2px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s   
    &.disabled
        opacity: 0.5
        pointer-events: none !important
        cursor: not-allowed !important
    &:not(&.disabled)
        &:hover
            background: $grey_hover
    &:not(&.disabled)
        &.active
            background: #33383F
            p
                color: #fff !important

.groupHeading
        margin-bottom: 4px
        .heading
            color: #EFEFEF
            font-weight: 500
            font-size: 12px
            line-height: 12px
.production
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #00D59C
.staging
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #9747FF
.develop
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #FFD944
.global
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: white
.sectionDescription
    font-size: 14px
    color: #6F767E
    display: flex
    justify-content: space-between
    margin-right: 8px