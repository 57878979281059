.controlDiv
    margin: 32px 0px
    .addIcon
        cursor: pointer
        margin-left: 8px
        svg
            width: 20px
            height: 20px

.add
    cursor: pointer
    margin-right: 4px