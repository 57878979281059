@import ../../../../../styles/helpers

.resetPasswordModal
    .resetPassword
        .modalHeading
            display: flex
            gap: 10px
            margin: 0px 0px 32px 0px
            font-weight: 600
            font-size: 20px
            line-height: 32px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 16px 
                height: 32px
                background: #CABDFF
                border-radius: 4px
        .modalBody
            .changePasswordBody
                .changePassword
                    margin: 15px 0px
                    .group
                        display: flex
                        flex-wrap: wrap
                        margin: 0 -6px -12px
                        +m
                            display: block
                            margin: 0
                        .field
                            flex: 0 0 calc(100% - 12px)
                            width: calc(100% - 12px)
                            margin: 0 6px 32px
                            +m
                                width: 100%
                                margin: 15px 0 0
        .modalFooter
            .btnGroup
                display: flex
                align-items: center
                .save
                    text-align: center
                    background: $custom-active-link
                    width: 112px
                    height: 48px
                    padding: 12px 20px
                    border-radius: 12px
                    font-weight: 700
                    color: #FCFCFC
                    font-family: Inter
                    font-size: 15px
                .cancel
                    text-align: center
                    background: #FCFCFC
                    width: 112px
                    height: 48px
                    padding: 12px 20px
                    border-radius: 12px
                    font-weight: 700
                    color: #1A1D1F
                    font-family: Inter
                    font-size: 15px
                    margin: 0px 10px
                    border: 2px solid #EFEFEF
