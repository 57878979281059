@import ../../../../styles/helpers

.addMembersModal
    .addMemberContainer
        .memberHeader
            width: 90%
            display: flex
            align-items: center
            justify-content: space-between
            margin: -2px 0px
            .headingContainer
                display: flex
                align-items: center
                gap: 10px
                .headingBlock
                    width: 16px
                    height: 32px
                    background: #CABDFF
                    border-radius: 4px
                .headingContent
                    font-size: 20px
                    font-weight: 600
                    line-height: 32px
            .memberLink
                border: 2px solid #EFEFEF
                border-radius: 8px
                padding: 8px 16px
                cursor: pointer
                .shareLink
                    .linkText
                        margin-left: 5px
                        font-size: 13px
                        line-height: 24px
                        font-weight: 700
        .memberBody
            padding: 25px 0px
            .inviteBody
                .inviteForm
                    display: flex
                    align-items: center
                    justify-content: space-between
                    .invitedUser
                        background: #2A85FF
                        color: #fff
                        border-radius: 12px
                        height: 40px
                        width: 100px
                        display: flex
                        align-items: center
                        justify-content: center
                        font-size: 13px
                        &:hover
                            background: darken($p1, 10)
                    .field
                        width: 85%
                    span
                        font-size: 13px
                        font-weight: 700
                .memberLists
                    .membersList
                        margin: 30px 0px
                        display: flex
                        align-items: center
                        justify-content: space-between
                        .player
                            display: flex
                            gap : 20px
                            .playerInsigne
                                width: 40px
                                height: 40px
                            .playerDetails
                                .userName
                                    font-size: 14px
                                    line-height: 24px
                                    color: #33383F
                                    font-weight: 600
                                .playerId
                                    font-size: 12px
                                    line-height: 12px
                                    color: #6F767E
                                    font-weight: 500