.container
    width: 100%
    height: 500px
    .wrapper
        width: 100%
        .loader
            display: flex
            gap: 5px
            justify-content: center
            .balls
                width: 7px
                height: 7px
                border-radius: 50%
                background: rgb(42, 133, 255)
                animation: progress 1s infinite
                &:nth-child(1)
                    animation-delay: 0s
                &:nth-child(2)
                    animation-delay: 0.2s
                &:nth-child(3)
                    animation-delay: 0.4s

@keyframes progress
    0%, 100%
        transform: scale(0.2)
        background: rgb(42, 133, 255)
    40%
        transform: scale(1)
        background: rgb(249, 198, 38)
    50%
        transform: scale(1)
        background: rgb(42, 133, 255)