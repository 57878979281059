@import ../../../../../styles/helpers

.fileInformation
    height: 100%
    .container
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        &.type
            justify-content: space-evenly
        .toggle
            display: flex
            align-items: center
            justify-content: left
            gap: 40px
            .options
                width: 100%
                display: block
                .button
                    display: block
                    margin: 0px auto
                    width: 50%
                    margin-top: 24px
                    padding: 8px 0px
                    border: 2px solid #EFEFEF
                    border-radius: 8px
                    text-align: center
                    cursor: pointer
        .notice
            .note
                p
                    color: #6F767E
                    font-size: 12px
                    font-weight: 500
                    line-height: 20px
            .fileSelection
                margin: 48px 0px
                +x
                    margin: 32px 0px
                .title
                    margin-bottom: 24px
                    color: #33383F
                    font-size: 15px
                    font-weight: 600
                    line-height: 24px
                .files
                    display: flex
                    align-items: center
                    justify-content: left
                    gap: 40px
                    .selection
                        display: flex
                        align-items: center
                        gap: 16px
            .typeSelection
                .title
                    margin-bottom: 24px
                    color: #33383F
                    font-size: 15px
                    font-weight: 600
                    line-height: 24px
                .group
                    display: flex
                    flex-wrap: wrap
                    align-items: center
                    margin-top: 12px
                    .files
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 12px 0px 0px
                        padding: 8px 16px 0px 16px
                        .title
                            text-align: center
                            margin-bottom: 24px
                            color: #1A1D1F
                            font-size: 18px
                            font-weight: 600
                            line-height: 32px
                        .info
                            color: #6F767E
                            font-size: 12px
                            font-weight: 500
                            line-height: 20px
                            .list
                                .pointers
                                    list-style-type: disc
                        .options
                            width: 100%
                            display: block
                            .button
                                display: block
                                margin: 0px auto
                                width: 50%
                                margin-top: 24px
                                padding: 8px 0px
                                border: 2px solid #EFEFEF
                                border-radius: 8px
                                text-align: center
                                cursor: pointer