@import ../../../../../../styles/helpers

.edit
    display: flex
    margin-left: auto
    cursor: pointer
    border: 2px solid #EFEFEF
    border-radius: 8px
    padding: 10px
    
.cardDescription
    margin: 16px 0px
    .preview
        display: flex
        margin-bottom: 30px
        align-items: center
        gap: 15px
        input[type="file"]
            display: none !important
        .previewImage
            width: $preview_width
            height: $preview_height
            img
                width: $preview_width
                height: $preview_height
                border-radius: 16px
                object-fit: cover
                border: 2px solid #EFEFEF
        .previewInput
            .button
                display: flex
                align-items: center
                cursor: pointer
                gap: 8px
                font-size: 12px
                padding: 8px 16px
                font-weight: 600
                background: #2A85FF 
                color: #fff
                border-radius: 8px
                height: 32px
                border: 2px solid #2A85FF
                &:hover
                    background: darken($p1, 10)
                .addIcon
                    width: 20px
                    height: 20px
        .previewCancel
            .buttonCancel
                display: inline-block
                font-weight: 600
                background: #FCFCFC 
                font-size: 12px
                color: #1A1D1F
                border-radius: 8px
                width: 90px
                height: 32px
                border: 2px solid #EFEFEF
                &:hover
                    border: 2px solid #1A1D1F

    .previewCancel
        +m
            display : none

    .infoGroup
        width : 100%
        margin : 30px 0px
        .labelHeading
            margin-bottom: 10px
        .selectInput
            margin: 15px 0px
        .groupHeading
            margin : 0px 0px 20px 0px
            span
                margin : 0px 8px 0px 0px
            .addIcon
                cursor : pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -16px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 32px)
                width: calc(50% - 12px)
                margin: 0 16px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 6px
                .variants
                    display: flex
                    flex-direction: row
                    margin: 8px 0px 12px 0px
                    .radio
                        width: 100px
                        margin : 0px 32px 0px 0px
                    .radioConfig
                        margin : 0px 32px 0px 0px
    
    .accessEligibility
        margin : 30px 0px
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -16px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 32px)
                width: calc(50% - 12px)
                margin: 0 16px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 6px
                .variants
                    display: flex
                    flex-direction: row
                    margin: 8px 0px 12px 0px
                    .radio
                        width: 100px
                        margin : 0px 32px 0px 0px
                    .radioConfig
                        width: 130px
                        margin : 0px 32px 0px 0px


    .customData
        .tagInputs
            margin : 25px 0px
            .tagInput
                cursor: text !important
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
                span
                    font-size : 15px
                    color: #33383F
                    font-weight : 700
        .groupHeading
            margin : 0px 0px 20px 0px
            span
                margin : 0px 8px 0px 0px
            .addIcon
                cursor : pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 25px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 12px)
                width: calc(48% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    right : -45px
                    top : 50%
                    transform : translate(-45%, -70%)
                +m
                    display : none

.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px

.ml
    margin-left: 10px