.rdrDefinedRangesWrapper{
    display: none !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
    color: #1A1D1F !important;
    font-weight: 600;
}

.rdrDateDisplay{
    display: none !important;
}

.rdrDateDisplay:nth-child(1){
    display: flex !important;
}