.page
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    background: #fff
    .container
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .warning
            text-align: center
            h3
                font-size: 20px
                color: #1A1D1F
                font-weight: 600
                line-height: 32px
                letter-spacing: -0.4px
            h5
                font-size: 15px
                color: #6F767E
                font-weight: 500
                line-height: 24px
                letter-spacing: -0.15px
                margin-top: 10px
        .button
            margin-top: 10px