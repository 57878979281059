
.wrapper
    .container
        .modalHeader
            display: flex
            align-items: center
            justify-content: left
            gap: 6px
            .block
                background: #FFBC99
                width: 16px
                height: 32px
                border-radius: 4px
            .modalHeading
                font-weight: 600
                font-size: 20px
                line-height: 32px
                color: #1A1D1F
        .modalBody
            .bodyWrapper
                .notice
                    margin: 16px 0px
                    font-size: 12px
                    line-height: 20px
                    font-weight: 500
                    color: #6F767E
                .label
                    display: flex
                    align-items: center
                    justify-content: space-between
                    font-size: 15px
                    font-weight: 500
                    margin-bottom: 10px
                .fileUpload
                    &:first-child
                        margin-bottom: 15px
                    .sample
                        font-size: 15px
                        line-height: 24px
                        font-weight: 500
                        color: #6F767E
                        text-align: center
                        margin: 16px 0px
                        a
                            font-weight: 700
                            color: #000
                            text-decoration: underline
                .warningMessage
                    margin-top: 15px 
                    .danger
                        color: red
                        font-size: 13px
                        font-weight: 500
                .reward
                    margin: 10px 0px
                    .heading
                        margin-bottom: 5px
        .modalFooter
            margin-top: 5px
            display: flex
            justify-content: center