@import ../../../styles/helpers

.scheduleModal
    .scheduleContainer
        .modalHeader
            .modalHeading
                display: flex
                align-items: center
                flex: 1
                gap: 10px
                margin: 0px 0px 20px 0px
                font-weight: 600
                font-size: 16px
                line-height: 24px
                letter-spacing: -0.02em
                color: #1A1D1F
                .timeZone
                    display: block
                    margin: 0px 50px 0px auto
                    width: 250px
                .block
                    width: 12px 
                    height: 24px
                    background: #B1E5FC
                    border-radius: 4px
        .modalBody
            .modalContent
                .label
                    font-size: 14px
                    font-weight: 600
                    margin: 16px 0px 8px 0px
                    &.disabled
                        opacity: 0.6
                .group
                    display: flex
                    flex-wrap: wrap
                    margin: 12px -6px -12px
                    &.disabled
                        opacity: 0.6
                        .field
                            position: relative
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                            +m
                                width: 100%
                                margin: 12px 0 0
                            .label
                                color: #33383F
                                font-size: 14px
                                margin: 0px 0px 15px 6px
                    .field, .fieldRadio
                        position: relative
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
                        .label
                            color: #33383F
                            font-size: 14px
                            margin: 0px 0px 15px 6px
                        .radioLabel
                            color: #33383F
                            font-size: 14px
                            margin : 0px 0px 12px 6px
                        .variants
                            display: flex
                            flex-direction: row
                            margin: 8px 0px 12px 0px
                            .radio
                                margin: 0px 15px 0px 0px
                        .variantsCheckbox
                            display: flex
                            align-items: center
                            cursor: pointer
                            input 
                                cursor: pointer
                            .checkboxLabel
                                color: #33383F
                                font-size: 14px
                                margin-left: 5px
                    
        .modalFooter
            margin: 16px 0px 0px 0px

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px

.notice
    margin-top: 20px
    font-size: 15px
    font-weight: 400
    color: #000
    font-style: italic

.error
    margin-top: 20px
    font-size: 15px
    font-weight: 400
    color: red
    font-style: italic