
  .ReactTags__tags {
    position: relative;
    padding: 0px 0px !important;
    border: 1px solid #EFEFEF !important;
    background-color: #FCFCFC !important;
  }
  
  .ReactTags__clearAll {
    display: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
    height: 36px;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 36px;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    padding: 0px 15px;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag:nth-child(3n){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #FFBC99 !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected span.ReactTags__tag:nth-child(3n+1){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #E4E4E4 !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected span.ReactTags__tag:nth-child(3n+2){
    display: inline-flex;
    align-items: center;
    margin: 5px 5px 0;
    padding: 4px 4px 4px 8px;
    background: #B5E4CA !important;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #33383F;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    width: 20px;
    height: 20px;
    margin: 0 0 0 8px;
    border: none;
    cursor: pointer;
    background: none;
    background: url("../../../../../../media/images/icons/remove_tag.svg") no-repeat 55% 55% !important;
    font-size: 0;
    color: #FFFFFF;
    transition: transform 0.2s;
  }

.icon_down_dir:before {
    content: "" !important;
    display: none !important;
}

.searchWrapper{
  -webkit-border-radius : 12px !important;
  -moz-border-radius : 12px !important;
  -ms-border-radius : 12px !important;
  border-radius: 12px !important;
  border: 1px solid #ccc !important;
  cursor: pointer !important;
  position: relative;
  padding: 8px;
}

.icon_down_dir {
    position: absolute !important;
    right: 2% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 14px !important;
}

.multiSelectContainer{
  height: 48px !important;
}

.multiSelectContainer ul{
    background: #FCFCFC;
    border-radius: 12px !important;
    -webkit-border-radius : 12px !important;
    -moz-border-radius : 12px !important;
    border-radius: 12px !important;
}

.highlightOption{
    background-color: transparent !important;
    color : #000 !important;
}

.multiSelectContainer li:hover {
    background-color: rgb(228, 228, 228) !important;
    color : #000 !important;
}

.displayBlock{
    margin: 5px 0px !important;
}