.app 
    padding: 40px 
    text-align: center 
.container 
    margin: auto 
    width: 50% 
.ReactTags__tags 
    position: relative 
    padding: 0 0 5px 
    background: $n2
    border-radius: 12px 
    +dark-common
        background: $n6
.ReactTags__clearAll 
    cursor: pointer 
    height: 40px 
    padding: 0 10px 
    margin: 10px 
    font-size: 14px 
    border-radius: 12px 
    background: $p3
    color: $n
    border: none 
.ReactTags__tagInput 
    border-radius: 2px 
    display: inline-block 
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus 
    height: 40px 
    margin: 0 
    padding: 0 5px 
    background: none 
    box-shadow: none 
    font-size: 14px 
    font-weight: 600 
    border: none
    min-width: 240px 
    color: $n7
    +dark-common
        color: $n1
.ReactTags__tagInput input.ReactTags__tagInputField
    &::placeholder
        color: $shades1
        +dark-common
            color: $shades2
.ReactTags__editInput 
    border-radius: 1px 
.ReactTags__editTagInput 
    display: inline-flex
.ReactTags__selected 
    span 
        &.ReactTags__tag 
            display: inline-flex 
            align-items: center 
            margin: 5px 5px 0 
            padding: 8px 16px 
            background: $p1
            border-radius: 8px 
            font-size: 13px 
            font-weight: 700 
            color: $n1
.ReactTags__suggestions 
    position: absolute 
    ul 
        list-style-type: none 
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2) 
        background: $n
        width: 200px 
        li 
            &.ReactTags__activeSuggestion 
                background: $s3 
                cursor: pointer 
    li 
        border-bottom: 1px solid $n3
        padding: 5px 10px 
        margin: 0 
        mark 
            text-decoration: underline 
            background: none 
            font-weight: 600 
.ReactTags__remove 
    width: 24px 
    height: 24px 
    margin: 0 0 0 8px 
    border: none 
    cursor: pointer 
    background: none 
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293z' fill='%23fcfcfc'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto 
    font-size: 0 
    color: $n
    transition: transform 0.2s 
    &:hover 
        transform: rotate(90deg) 
