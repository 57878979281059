@import ../../../styles/helpers

.groupDropdown
    width : 100%
    +m
        width: 100% 

.groupByTwoDropdown

    width : 50%
    +m
        width: 100% 

.groupByThreeDropdown
    width : 33.33%
    +m
        width: 100%

.groupByFourDropdown
    width : 25%
    +m
        width: 100%

.label
    margin-bottom: 8px
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1

.groupLabel
    display: flex
    justify-content: space-between
    .error
        font-size: 13px
        color: #f26f5b
        padding-right: 10px
        margin-top: 1px

.head_error
    box-shadow: inset 0 0 0 1px #f26f5b !important

.dropdown
    position: relative
    z-index: 3
    &.active
        z-index: 10
        .head
            box-shadow: inset 0 0 0 2px $shades1
            background: $n
            +dark
                box-shadow: inset 0 0 0 2px $n5
                background: $n8
                color: $n1
            &:before
                transform: rotate(180deg)
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)
    &.small
        .head
            height: 40px
            padding: 0 40px 0 16px
            line-height: 40px
            background: $n
            &:before
                width: 40px
                background-size: 16px auto
        .option
            padding: 2px 16px

.head
    background: $n
    position: relative
    height: $height_36
    padding: 0 36px 0 12px
    border-radius: 8px
    box-shadow: inset 0 0 0 2px $n3
    font-size: $font_12
    font-weight: 600
    line-height: $line_height_36
    color: $n7
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 48px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        transition: transform .2s
    &.disabled
        background:#f4f4f4

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.body
    position: absolute
    max-height: 210px
    overflow-y: scroll
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 8px
    background: $n
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgba($n8, .1)
    &.bodyUp
        top: auto
        bottom: calc(100% + 2px)
    +x
        max-height: 150px

.option
    padding: 3px 12px
    font-size: $font_12
    color: $n4
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &:hover
        background: $grey_hover
        color: $n7
        +dark
            color: $n1
    &.selectioned
        color: $p1
        +dark
            color: $p1

.placeholder
    color:#6F767E
    display: flex
    align-items: center
    .currency
        z-index: 1
        margin: -2px 10px 0px 0px

.locInput
    background:#FCFCFC
    position: absolute
    top : 50%
    transform: translateY(-50%)
    padding-left: 35px
    width: 85%
    height: 44px
    +base2
    color: $n5
    +dark
        color: $n3
    &:disabled
        background: rgb(244, 244, 244, 0.8)

.normalInput
    background:#FCFCFC
    position: absolute
    top : 50%
    transform: translateY(-50%)
    width: 90%
    height: 44px
    +base2
    color: $n5
    +dark
        color: $n3
    &:disabled
        background:rgb(244, 244, 244, 0.8)