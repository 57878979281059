@import '../../../styles/helpers'
.custom
    display: inline-block
    cursor: pointer
    .tooltip 
        position: absolute
        background-color: #272B30
        color: #fff
        padding: 12px 8px 8px 12px
        border-radius: 8px
        white-space: nowrap
        z-index: 999
        opacity: 0.9
        pointer-events: none
        min-width: 150px
        max-width: 300px
        // width: 150px
        overflow-x: hidden
        .groupHeading
            margin-bottom: 4px
            .heading
                color: #EFEFEF
                font-weight: 500
                font-size: 12px
                line-height: 12px
        .Virtual
            display: inline-block
            margin: 0px 0px 5px 0px
            background: $Virtual-badge-bg
            font-weight: 600
            padding: 5px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px
            .currencyContent
                color : $Virtual-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
            .virtualPrice
                color : $Virtual-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
        .RMG
            display: inline-block
            margin: 0px 0px 5px 0px
            background: $Real-badge-bg
            font-weight: 600
            padding: 5px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px
            .itemContent
                color: $Real-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
            .RMGPrice
                color: $Real-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
        .IAP
            display: inline-block
            margin: 0px 0px 5px 0px
            background: $IAP-badge-bg
            font-weight: 600
            padding: 5px 8px
            font-size: 12px
            line-height: 12px
            border-radius: 4px
            .IAPCode
                color: $IAP-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
            .IAPPrice
                color: $IAP-badge-color
                font-weight: 600
                font-size: 12px
                line-height: 12px
                