@import ../../../styles/helpers

.wrapper
    margin-bottom: 30px
    .fileUpload
        margin: 8px 0px
    .filePreview
        .fileWrapper
            display: flex
            flex-wrap: wrap
            .product
                position: relative
                flex: 0 0 calc(25% - 24px)
                width: calc(25% - 24px)
                margin: 0px 12px 0
                +t
                    flex: 0 0 calc(50% - 24px)
                    width: calc(50% - 24px)
                +a
                    width: 100%
                    margin: 0
                    &:not(:last-child)
                        margin-bottom: 32px
    .toggle
        display: flex
        margin-left: auto
        margin-top: 20px
        background: #F4F4F4
        border-radius: 8px
        padding: 5px
        .link
            flex-shrink: 0
            width: 50%
            height: 40px
            border-radius: 8px
            font-weight: bold
            p
                transition: fill .2s

            &.active
                background: $n1
                font-weight: bold
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

            &:not(:last-child)
                margin-right: 0px

.wrapperButton  
    text-align: center 
    margin: 20px 0px 
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px
            color: #fff   
    .Save
        padding : 12px 20px
        font-weight : 700
        background : #2A85FF 
        color : #fff
        border-radius : 12px
        width : 90px
        margin: 0px 10px 0px 0px
        &:disabled
            opacity: 0.5
        &:hover
            background: darken($p1, 10)

    .Cancel
        padding : 12px 20px
        font-weight : 700
        background : #FCFCFC 
        color : #1A1D1F
        border-radius : 12px
        width : 91px
        border: 2px solid #EFEFEF
        margin: 0px 10px 0px 0px
        &:hover
            box-shadow: 0 0 0 2px #1A1D1F inset

.pagination
    display: flex
    align-items: center
    justify-content: center
    gap: 25px
    margin: 10px 0px 0px 0px
    .icons
        .previous, .next
            margin: 0px 5px
            padding: 5px
            border: 2px solid #EFEFEF
            border-radius: 50%
            cursor: pointer

.folderList
    display: flex
    flex-wrap: wrap
    margin: 16px -6px -12px
    .folderGroup
        color: #33383F
        cursor: pointer
        margin: 8px
        background:#F4F4F4
        padding: 5px 12px
        border-radius: 8px
        display: flex
        flex: 0 0 calc(33.33% - 12px)
        width: calc(33.33% - 12px)
        margin: 0 6px 12px
        .folderDetails
            display: flex
            align-items: center
            svg
                width: $icon-width
                height: $icon-height
            .titleName
                color: #33383F
                padding: 4px
                margin-left: 12px
                font-size: $font_12

.filters
    display: flex
    align-items: center
    gap: 15px
    .buttons
        padding: 4px 8px
        border: 2px solid #EFEFEF
        border-radius: 8px
        font-size: 12px
        color: #6F767E
        cursor: pointer
        .icon
            margin-right: 5px
            fill: #6F767E  