@import '../../../../../styles/helpers'

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    // gap: 20px
    margin: 10px -6px 10px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        // border-right: 2px solid #efefef
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px      
    .table
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        // display: flex
        // align-items: center
        // justify-content: left
        // gap: 40px
        .planFeatures
            display: flex
            align-items: stretch
            gap: 16px
            margin: 5px 0px 20px
            .specter
                svg
                    width: 125px
                    height: 125px
            .features
                display: flex
                align-items: center
                flex-wrap: wrap
                width: calc(50% - 12px)
                flex: 1
                position: relative
                .feature
                    display: flex
                    align-items: center
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin-bottom: 12px
                    gap: 4px
                    .title
                        font-size: 14px
                        font-weight: 400
                        color: #808191
                        width: 100%
                        font-family: "Poppins", sans-serif
        .icon
            margin: 5px 0px 20px
            svg
                width: 125px
                height: 125px
        .fields
            .specialOffer
                margin-bottom: 20px
                flex: 1
                border: 2px solid #EFEFEF
                border-radius: 8px
                .offer
                    display: flex
                    justify-content: space-between
                    align-items: center
                    padding: 16px
                    .content
                        .title
                            font-size: 16px
                            font-weight: 600
                            color: #11142D
                        .description
                            font-size: 13px
                            font-weight: 600
                            color: #808191
            .action
                padding: 10px 0px 0px
            .addOnsList
                display: flex
                flex-wrap: wrap
                justify-content: space-between
                align-items: center
                gap: 12px
                padding: 0px
                .addOn
                    cursor: pointer
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    border: 2px solid #E3E6E9
                    border-radius: 8px
                    padding: 10px 12px
                    &.disabled
                        cursor: default
                        opacity: 0.5
                        pointer-events: none
                    .addOnDetail
                        display: flex
                        justify-content: space-between
                        margin-bottom: 8px
                        .detail
                            .name
                                color: #11142D
                                font-size: 14px
                                font-weight: 600
                            .description
                                color: #808191
                                font-size: 12px
                                font-weight: 600
                    .addOnPurchase 
                        display: flex
                        justify-content: space-between
                        align-items: end
                        .price
                            background: #2A85FF
                            color: #fff
                            font-weight: 600
                            font-size: 12px
                            padding: 3px 10px
                            border-radius: 6px
                        .quantity
                            display: flex
                            align-items: center
                            gap: 10px
                            font-size: 13px
                            color: #1A1D1F
                            height: 25px
                            .amount
                                font-size: 12px
                                border: 1px solid #E3E6E9
                                border-radius: 4px
                                padding: 0px 5px
                            .operation
                                display: flex
                                flex-direction: column
                                .add
                                    user-select: none
                                    -webkit-user-select: none
                                    -moz-user-select: none
                                    height: 12px
                                    svg
                                        transform: rotate(-90deg)
                                .remove
                                    user-select: none
                                    -webkit-user-select: none
                                    -moz-user-select: none
                                    svg
                                        transform: rotate(90deg)
            .container
                margin: 8px 0px 16px
                .title
                    margin-bottom: 16px
                    font-size: 14px
                .addOn
                    display: flex
                    align-items: center
                    font-size: $font_12
                    &:nth-child(even)
                        background: #F4F4F4
                    .field
                        width: calc(33.33% - 8px)
                        flex: calc(33.33% - 8px)
                        padding: 8px 16px
            .group
                display: flex
                align-items: center
                font-size: $font_12
                &:nth-child(odd)
                    background: #F4F4F4
                .field
                    width: calc(33.33% - 8px)
                    flex: calc(33.33% - 8px)
                    padding: 8px 16px
                    .underline
                        text-decoration: underline
                        cursor: pointer
                    .action
                        cursor: pointer
                        text-decoration: underline
                        color: #2a85ff