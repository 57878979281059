
.quickAppOverview
    .wrapper
        display: flex
        width: 100%
        align-items: center
        gap: 16px
        .appIcon
            img
                width: 50px
                height: 50px
                border-radius: 8px
        .appName, .appId
            margin-bottom: 8px
        .appInformation
            font-size: 13px
            margin-bottom: 4px
            flex: 2
            .appWrapper
                display: flex
                align-items: start
                justify-content: space-between
                flex-wrap: wrap
                .field
                    line-height: 20px
                    font-weight: 600
                    flex: 0 0 calc(50% - 0px)
                    width: calc(50% - 0px)
                    display: flex
                    // align-items: center
                    gap: 5px
                    margin-bottom: 2px
                    .title
                        color: #1A1D1F
                    .value
                        color: #6F767E
            .id
                color: #6F767E
                line-height: 16px
                font-weight: 600
                .title
                    color: #1A1D1F
                .copyToClipboard
                    margin-left: 8px
                    cursor: pointer
            .appInfo
                display: flex
                align-items: center
                gap: 28px
                .category, .genre, .platform
                    display: flex
                    align-items: center
                    gap: 5px
                    margin-top: 3px
                    svg
                        width: 16px
                        height: 16px
                    .genreNames
                        display: flex
                        align-items: center
                        .genreContainer
                            max-width: 150px
                            overflow: hidden
                            text-overflow: ellipsis
                            white-space: nowrap
                    .categoryName, .genreNames, .platformName
                        color: #6F767E
                        line-height: 16px
                        font-size: 13px
                        font-weight: 600
                        .textBold
                            color: #1A1D1F
                            .genreName
                                text-transform: capitalize
