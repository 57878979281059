@import ../../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 46px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            // .control
            //     visibility: visible
            //     opacity: 1
            input
                border : 1px solid #333
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 56px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:nth-child(2)
        width: 240px
    &:last-child
        padding-right: 164px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
        .prices
            display: block
            margin: 5px 0px
            .count
                color : #6F767E
                font-size: 13px
                font-weight: 500
                line-height: 16px
                margin-left: 5px
            .virtual
                color: $Virtual-badge-color
                background: $Virtual-badge-bg
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                padding: 3px 8px
                letter-spacing: -0.15px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                +dark
                    color: $Virtual-badge-color
                    background: $Virtual-badge-bg
            .real
                color:  $Real-badge-color
                background: $Real-badge-bg
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                letter-spacing: -0.15px
                padding: 3px 8px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                +dark
                    color:  $Real-badge-color
                    background: $Real-badge-bg
            .IAP
                color:  $IAP-badge-color
                background: $IAP-badge-bg 
                font-family: Inter
                font-size: $Badge-font-size
                font-style: normal
                font-weight: $Badge-font-weight
                line-height: $Badge-line-height
                padding: 3px 8px
                letter-spacing: -0.15px
                border-radius : 4px
                text-align : center
                width: 70px
                height: 25px
                +dark
                    color:  $IAP-badge-color
                    background: $IAP-badge-bg 

    .addIcon 
        cursor: pointer
    
    .rewardsInput
        width : 125px
        input 
            display : block
            padding : 0px 10px
            background : #F4F4F4
            height : 28px
            width : 125px
            border-radius : 8px        

.itemName
    color : #1A1D1F
    font-family: Inter
    font-size: $table-content
    font-weight: 600
    line-height: 16px
    letter-spacing: -0.01em
    width: 200px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

.itemId
    color : #808191
    font-family: Inter
    font-size: $table-grey-content
    font-weight: 500
    line-height: 16px
    letter-spacing: -0.01em

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .details,
    .price,
    .product,
    .empty,
    .date
        display: none
    .details,
    .price,
    .empty,
    .product
        +t
            margin : 0px 5px 0px 0px
            text-align : left
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1

.preview
    flex-shrink: 0
    width: $icon-width
    height: $icon-height
    margin-right: 20px
    border-radius: 8px
    // overflow: hidden
    position: relative
    +t
        width: $icon-width
        height: $icon-height
    &.bg
        background: #EFEFEF
    img
        width: $icon-width
        height: $icon-height
        border: 2px solid #EFEFEF
        border-radius: 8px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 44px
        +title2

.link
    +caption1
    color: $shades1
    +t
        display: none

.price,
.empty,
.archive
    flex-shrink: 0
    display: inline-block
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius: 4px
    text-align: center
    width: 70px
    height: 25px
    +dark
        background: #EAFAE4
        color: #83BF6E

.virtual
    color: $Virtual-badge-color
    background: $Virtual-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $Virtual-badge-color
        background: $Virtual-badge-bg

.real
    color:  $Real-badge-color
    background: $Real-badge-bg
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color:  $Real-badge-color
        background: $Real-badge-bg

.IAP
    color:  $IAP-badge-color
    background: $IAP-badge-bg 
    font-family: Inter
    font-size: $Badge-font-size
    font-style: normal
    font-weight: $Badge-font-weight
    line-height: $Badge-line-height
    padding: 3px 8px
    letter-spacing: -0.15px
    border-radius: 4px
    text-align: center
    width: 70px
    height: 25px
    +dark
        color:  $IAP-badge-color
        background: $IAP-badge-bg 

.archive
    color: #FF6A55
    background: #FFBC99
    font-family: Inter
    font-size: $table-content
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $n7

.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    // visibility: hidden
    // opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px