@import ../../../../styles/helpers

.loaderContainer
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex: 1
    background: #fff
    .loaderWrapper
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        flex: 1
        .loaderTitle
            margin-top: 8px
            .title
                font-size: 13px
                line-height: 18px
                text-align: center
                color: grey
                font-weight: 500

.userDetail
    width: 100%
    height: 100vh
    overflow: hidden
    background: #fff
    .userDetail_Container
        .userDetail_Container_Outer
            .logo
                padding: 40px 65px
                position: absolute
                z-index: 2 !important
                display: block
                width: 100%
                +x
                    padding: 20px 65px
            .userDetail_Container_Inner
                display: flex
                .formContainer
                    flex: 4
                    width: 100%
                    height: 100vh
                    position: relative
                    .fillCrossSvg
                        position: absolute
                        top: 50%
                        right: 5%
                        transform: translate(-5%, -50%)
                    .fullCircleSvgForm
                        position: absolute
                        bottom: 5%
                        left: 20%
                        transform: translate(-5%, -20%)
                    .fullHollowCircleSvg
                        position: absolute
                        bottom: -12%
                        right: 20%
                        transform: translate(12%, -20%)
                    .FullCircleSvg
                        position: absolute
                        top: 15%
                        right: 25%
                        transform: translate(-20%, -15%)
                    .HalfElipseSvg
                        position: absolute
                        top: 10%
                        left: -5%
                        transform: translate(5%, -10%)
                .grapicDesign
                    flex : 1
                    width: 100%
                    max-width: 340px
                    height: 100vh
                    // background: rgb(42, 133, 255, 0.8)

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 120
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)
    &.active
        visibility: visible
        opacity: 1