@import '../../../../styles/helpers'

.container
    height: 195px
    .billingContainer
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
        align-items: stretch
        // gap: 20px
        margin: 10px -6px 10px -6px
        .cardHead
            flex: calc(25% - 12px)
            width: calc(25% - 12px)
            margin: 0 6px 12px
            .title
                font-size: 16px
            .info
                color: #6F767E
                font-size: 12px      
        .payment
            flex: calc(75% - 12px)
            width: calc(75% - 12px)
            margin: 0 6px 12px
                