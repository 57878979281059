
.appStatistic
    .wrapper
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
        padding-top: 16px
        .currencyContainer
            flex: 0 0 calc(100% - 80%)
            width: calc(100% - 80%) 
            .title
                font-size: 14px
                color: #1A1D1F
            .countContainer
                width: 40px
                height: 28px
                margin-top: 8px
                background: #FFD88D
                border-radius: 6px
                color: #FF6A55
                font-weight: 700
                position: relative
                .count
                    font-size: 14px
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
        .itemContainer
            flex: 0 0 calc(100% - 80%)
            width: calc(100% - 80%)
            .title
                font-size: 14px
                color: #1A1D1F
            .countContainer
                width: 40px
                height: 28px
                margin-top: 8px
                background: #B1E5FC
                border-radius: 6px
                color: #2A85FF
                font-weight: 700
                position: relative
                .count
                    font-size: 14px
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
        .bundleContainer
            flex: 0 0 calc(100% - 80%)
            width: calc(100% - 80%) 
            .title
                font-size: 14px
                color: #1A1D1F
            .countContainer
                width: 40px
                height: 28px
                margin-top: 8px
                background: #CABDFF
                border-radius: 6px
                color: #8E59FF
                font-weight: 700
                position: relative
                .count
                    font-size: 14px
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
        .storeContainer
            flex: 0 0 calc(100% - 80%)
            width: calc(100% - 80%) 
            .title
                font-size: 14px
                color: #1A1D1F
            .countContainer
                width: 40px
                height: 28px
                margin-top: 8px
                background: #EAFAE4
                border-radius: 6px
                color: #83BF6E
                font-weight: 700
                position: relative
                .count
                    font-size: 14px
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)