@import '../../../../../../styles/helpers'

// .container
//     margin: 50px
//     .specialOffer
//         margin-bottom: 20px
//         flex: 1
//         border: 1px solid #2a85ff
//         border-radius: 8px
//         .offer
//             display: flex
//             justify-content: space-between
//             align-items: center
//             padding: 16px
//             .content
//                 .title
//                     font-size: 16px
//                     font-weight: 600
//                     color: #11142D
//                 .description
//                     font-size: 13px
//                     font-weight: 600
//                     color: #808191
//     .wrapper
//         background: $n1
//         .upgrade
//             padding: 20px
//             .toggle
//                 display: flex
//                 margin: 0px auto
//                 background: #2A85ff
//                 padding: 2px
//                 width: 200px
//                 border-radius: 24px
//                 .link
//                     flex: 1
//                     flex-shrink: 0
//                     width: 25%
//                     height: 28px
//                     border-radius: 24px
//                     p
//                         color: #fff
//                     &:hover
//                         p
//                             font-weight: 600
//                     &.active
//                         background: $n1
//                         font-weight: bold
//                         box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
//                         p
//                             color: #2A85ff
//                     &:not(:last-child)
//                         margin-right: 0px
//             .action
//                 margin-top: 15px


.container
    margin: 50px
    .specialOffer
        margin-bottom: 20px
        flex: 1
        border: 1px solid #2a85ff
        border-radius: 8px
        .offer
            display: flex
            justify-content: space-between
            align-items: center
            padding: 16px
            .content
                .title
                    font-size: 16px
                    font-weight: 600
                    color: #11142D
                .description
                    font-size: 13px
                    font-weight: 600
                    color: #808191

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    margin: 10px -6px 10px -6px
    padding: 15px 0px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px      
    .table
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .action
            margin-top: 15px
        .addOnsList
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            align-items: center
            gap: 12px
            padding: 0px
            .addOn
                cursor: pointer
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                border: 2px solid #E3E6E9
                border-radius: 8px
                padding: 10px 12px
                &.disabled
                    cursor: default
                    opacity: 0.5
                .addOnDetail
                    display: flex
                    justify-content: space-between
                    margin-bottom: 8px
                    .detail
                        .name
                            color: #11142D
                            font-size: 14px
                            font-weight: 600
                        .description
                            color: #808191
                            font-size: 12px
                            font-weight: 600
                .addOnPurchase 
                    display: flex
                    justify-content: space-between
                    align-items: end
                    .price
                        background: #2A85FF
                        color: #fff
                        font-weight: 600
                        font-size: 12px
                        padding: 3px 10px
                        border-radius: 6px
                    .quantity
                        display: flex
                        align-items: center
                        gap: 10px
                        font-size: 13px
                        color: #1A1D1F
                        height: 25px
                        .amount
                            font-size: 12px
                            border: 1px solid #E3E6E9
                            border-radius: 4px
                            padding: 0px 5px
                        .operation
                            display: flex
                            flex-direction: column
                            .add
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                height: 12px
                                svg
                                    transform: rotate(-90deg)
                            .remove
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                svg
                                    transform: rotate(90deg)