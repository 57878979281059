.toast
    .toastSuccess
        display: flex
        align-items: center
        gap: 8px
        .successIcon
            svg, img
                width: 16px
                height: 16px
        .successContainer
            .successMessage
                width: 250px
                color: #4D8F65 
                font-family: Inter
                font-size: 13px
                font-style: normal
                font-weight: 500
                letter-spacing: -0.14px
                line-height: 20px
            .errorMessage
                width: 250px
                color: #B3281D 
                font-family: Inter
                font-size: 13px
                font-style: normal
                font-weight: 500
                letter-spacing: -0.14px
                line-height: 20px
            .warningMessage
                width: 250px
                color: #C28A2F 
                font-family: Inter
                font-size: 13px
                font-style: normal
                font-weight: 500
                letter-spacing: -0.14px
                line-height: 20px
            .infoMessage
                width: 250px
                color: #2150C4 
                font-family: Inter
                font-size: 13px
                font-style: normal
                font-weight: 500
                letter-spacing: -0.14px
                line-height: 20px
