@import ../../../../../../styles/helpers

.control
    .Cancel
        padding: 9px 20px
        border-radius: 8px
        font-weight: 600
        background: #FCFCFC 
        color: #1A1D1F
        width: 170px
        border: 2px solid #EFEFEF
        font-size: $font_12
        &:hover
            border-color: #1A1D1F