@import '../../../../../styles/helpers'

.warning
    margin-top: 20px

.overview
    .group
        display: flex
        flex-wrap: wrap
        align-items: center
        margin: 25px -6px -12px
        +m
            display: block
            margin: 0
        .field
            flex: 0 0 calc(50% - 12px)
            width: calc(50% - 12px)
            margin: 0 6px 12px
            +m
                width: 100%
                margin: 12px 0 0
    .groups
        .pagination
            display : flex
            align-items : center
            justify-content : center
            gap : 25px
            margin : 20px 0px 0px 0px
            .icons
                .previous, .next
                    margin: 5px 5px
                    padding : 5px
                    border : 2px solid #EFEFEF
                    border-radius : 50%
                    cursor : pointer
    .warning
        margin-top: 20px