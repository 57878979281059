@import ../../styles/helpers

.sidebar
    position: fixed
    top: 40px
    left: 0
    bottom: 0
    display: flex
    flex-direction: column
    width: 280px
    min-height: 100vh !important
    padding: 0px
    background: $n1
    z-index: 100
    -webkit-overflowY-scrolling: touch
    -ms-overflowY-style: none
    scrollbar-width: none
    box-shadow: 0px 0px 5px rgba(133, 133, 133, 0.15)
    &::-webkit-scrollbar
        display: none 
    +x
        width: 280px
        padding: 0px
    +d
        // z-index: 14
        width: 96px
        align-items: center
    +m
        width: 100%
        align-items: stretch
    +dark
        background: $n7
    &.progress
        pointer-events: none
        cursor: not-allowed
        opacity: 0.5
    &.wide
        width: 48px
        top: 80px
        box-shadow: none
        background: none
        .navSideBar
            display: none
    &.active
        +d
            align-items: stretch
            width: 300px
            box-shadow: 4px 0 32px rgba($n8, .05)
            .item,
            .link
                width: 100%
                font-size: 15px
                svg
                    margin-right: 12px
            .counter
                display: block
            .toggle
                position: absolute
                top: 16px 
                right: 16px
                svg
                    fill: $n7
                    &:first-child
                        display: none
                    &:nth-child(2)
                        display: inline-block
        +m
            width: 100%
    .mainSidebar
        .mainSidebarContainer
            display: flex
            .appSideBar
                width: 56px
                min-height: 100vh !important
                background: $n1
                box-shadow: 0px 0px 4px rgba(133, 133, 133, 0.45)
                .appSideBarContainer
                    padding: 6px 0px 28px
                    .orgProfiles
                        display: flex
                        align-items: center
                        margin: 5px 0px
                        position: relative
                        .activeSvg
                            position: absolute
                            left: -5px
                            &.active
                                left: 0px  
                        .orgLogo
                            display: block
                            margin: 0px auto
                            width: 36px
                            height: 36px
                        +m
                            margin-left: auto
                    .divider
                        text-align: center
                    .orgApps
                        padding: 0px 0px
                        position: relative
                        max-height: calc( 100vh -  145px)
                        overflow-y: scroll
                        scrollbar-width: none
                        scroll-behavior: smooth
                        .appsList
                            cursor: pointer
                            display: flex
                            align-items: center
                            &.active
                                &:nth-child(2)
                                    margin-top: 5px
                            .activeApps
                                position: absolute
                                left: -5px
                                &.active
                                    left: 0px
                                    transition: 0.25s ease-in-out
                                    svg
                                        path
                                            fill: #000
                            .appIcon
                                width: 28px
                                height: 28px
                                background: #EFEFEF
                                display: block
                                margin: 8px auto
                                border-radius: 8px
                                img
                                    border-radius: 8px
                                    width: 28px
                                    height: 28px 
                                    transition: 0.1s ease-in-out
                                    &:not(&.active) 
                                        &:hover
                                            transform: scale(1.2)
                    .addApps
                        cursor: pointer
                        display: flex
                        align-items: center
                        .appAddIcon
                            width: 28px
                            height: 28px
                            display: block
                            margin: 5px auto
                            border-radius: 8px
                            svg
                                &:hover
                                    animation: rotate 1s ease-in-out 1
                                    animation-timing-function: linear
                            img
                                border-radius: 8px
                                width: 28px
                                height: 28px
                                

            .navSideBar
                width: 95%
                min-height: 100vh !important
                .navBarContainer
                    padding: 6px 0px 32px
                    .menu
                        padding: 10px 8px
                        .menuApp
                            display: flex
                            align-items: center
                            gap: 10px
                            .activeApps
                                display: none
                                &.active
                                    display: block !important     
                            .appIcons
                                width: 40px
                                height: 40px
                                display: block
                                margin: 8px auto
                                border-radius: 8px

@keyframes rotate
    0%
        transform: scale(1)
    25%
        transform: scale(1.1)
    50%
        transform: scale(1.2)rotate(180deg)
    75%
        transform: scale(1.1)rotate(180deg)
    100%
        transform: scale(1)rotate(180deg)

.orgLogo
    box-shadow: inset 1px 0px 0px #F4F4F4, inset 0 -1px 0px #EFEFEF
    .org
        height: 34px
        img
            width: 28px
            height: 28px
            border-radius: 8px
            display: block
            margin: 0px auto
        .backGround
            background: #ffd88d
            border-radius: 8px
            position: relative
            display: block
            margin: 0px auto
            width: 28px
            height: 28px
            .txt
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                font-size: 12px
                font-weight: 700
        .organizationLogo
            width: 28px
            height: 28px
            border-radius: 8px
            img
                height: 28px
                width: 28px
                object-fit: cover
                border-radius: 8px

.organization
    padding: 0px 8px
    height: 34px
    box-shadow: inset 1px 0px 0px #F4F4F4, inset 0 -1px 0px #EFEFEF
    .organizationName
        display: flex
        align-items: center
        flex: 1
        .arrow
            svg
                transform: rotate(90deg)
        .orgText
            flex: 1
            .name
                display: block
                width: 200px
                max-width: 200px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap

.menu
    display: flex
    flex-direction: column
    margin-bottom: auto
    padding: 32px 15px

.item,
.dropdown
    cursor: pointer
    margin-bottom: 8px

.payment
    fill: #6F767E !important
    path
        fill: #6F767E !important

.item,
.link
    cursor: pointer
    display: flex
    align-items: center
    width: 100%
    height: 36px
    padding: 0 6px
    border-radius: 12px
    font-size: 15px
    font-weight: 600
    white-space: nowrap
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    font-size: 14px
    +d
        width: 48px
        font-size: 0
    +m
        width: 100%
        font-size: 15px
    .notify
        svg
            fill: inherit
    svg
        margin-right: 12px
        fill: none
        transition: fill .2s
        +d
            margin-right: 0
        +m
            margin-right: 12px
    &:hover
        background: $grey_hover
        color: $n7
        +dark
            color: #FCFCFC
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
    &.active
        background: $custom-active-background-link
        color: $custom-active-link
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)
    .custom
        margin-right: 12px
        fill: none
        transition: fill .2s
        +d
            margin-right: 0
        +m
            margin-right: 12px
    &.active
        color: $n7
        .notify
            svg
                fill: inherit
        svg
            fill: none
            // fill: $custom-active-link
    &.active
        background : $custom-active-background-link
        color : $custom-active-link

.toggle
    display: none
    width: 48px
    height: 48px
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +d
        display: inline-block
    +m
        display: none
    svg
        fill: $n4
        &:nth-child(2)
            display: none

.close
    display: none
    +m
        display: block
        position: absolute
        top: 16px
        left: 16px
        width: 48px
        height: 48px
        svg
            fill: $n7
            +dark
                fill: $n1

.foot
    margin: 25px 10px
    padding-top: 18px
    border-top: 2px solid $n2
    +d
        margin-top: 12px
        padding-top: 12px
    +dark
        border-color: $n6

.theme
    margin-top: 16px

.counter
    flex-shrink: 0
    min-width: 24px
    margin-left: auto
    border-radius: 6px
    background: $s2
    text-align: center
    line-height: 24px
    color: $n7
    +d
        display: none
    +m
        display: block

.overlay
    +d
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 12
        background: rgba($n2, .8)
        visibility: hidden
        opacity: 0
        transition: all .2s
        +dark
            background: rgba($n6, .9)
        &.active
            visibility: visible
            opacity: 1
    +m
        display: none

.appDetail
    position: relative
    height: 50px
    .appName
        padding: 0px 0px 0px 30px
        position: absolute
        top: 50%
        transform: translateY(-50%)
        font-size: 20px
        font-weight: 600
        

.orgTitle
    cursor: pointer
    .appName
        flex: 2
        .logoText
            width: 160px
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            font-size: 15px
            font-weight: 600
            line-height: 22px
            color: #272B30
            +x
                width: 130px
                font-size: 15px
            +d
                display: none
        .env
            font-size: 10px
            line-height: 22px
            color: #6F767E
            font-weight: 500
    .back
        display: flex
        align-items: center
        gap: 10px
        padding: 15px 0px
        svg
            transform: rotate(180deg)
        .title
            font-size: 16px
    .organisation
        display: flex
        justify-content: left
        align-items: center
        gap: 10px
        height: 44px
        .orgIcon
            width: 40px
            height: 40px
            border-radius: 50%
            img
                width: 40px
                height: 40px
                border-radius: 50%
                object-fit: cover
        .orgName
            width: 160px
            .orgText
                width: 100%
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                padding-left: 0px
                font-size: 16px
                font-weight: 600
                line-height: 24px
                +x
                    font-size : 16px
                +d
                    display : none
    .environment
        position: relative
        min-width: 40px
        min-height: 40px
        background: #272B30
        border-radius: 8px
        cursor: pointer
        .developLogo
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #FFD944 !important
        .stagingLogo
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #9747FF !important
        .productionLogo
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            path
                fill: #00D59C !important
    .appLogo
        width: 48px
        height: 48px
        border-radius: 8px
        background:#EFEFEF


.app
    margin : 0px 0px 5px 0px
    &.active
        border-left : 4px solid #EFEFEF

.headHelp
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid #EFEFEF
    color: #1A1D1F
    font-weight: 500