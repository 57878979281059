@import '../../styles/helpers'

.container
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  width: 100vw
  background: #f9f9f9
  position: relative
  overflow: hidden

// Branding Elements
.branding
  position: absolute
  width: 100%
  height: 100%
  .halfCircle
    position: absolute
    top : 50%
    transform: translateY(-50%)
    z-index: 2 !important
  .crossFilled
    position: absolute
    top : 60%
    left : 8%
    z-index: 2
  .fullCircle
    position: absolute
    top : 25%
    right : 35%
    z-index: 2
  .hollowCircle
    position: absolute
    top : -6%
    left : 45%
    z-index: 2
  .hollowCross
    position: absolute
    top: 40%
    right: -1%
    transform: translateY(-40%)
    z-index: 2
  .filledCross
    position: absolute
    top: 80%
    right: 7%
    z-index: 2

  .smallerFullCircle
    position: absolute
    bottom: 7%
    left: 15%
    z-index: 1

// Main Content
.content
  position: relative
  margin-top: 100px
  z-index: 3
  text-align: center
  padding: 20px
  max-width: 600px

  .title
    font-size: 2.5rem
    font-weight: bold
    color: #333
    margin-bottom: 1rem

  .subtitle
    font-size: 1.2rem
    color: #555
    margin-bottom: 2rem
    line-height: 1.5

  .unauthorizedImage
    max-width: 100%
    height: auto
    margin-top: -100px
    margin-bottom: 2rem

  .goHomeButton
    display: inline-block
    padding: 12px 20px
    font-weight: 700
    background: #2a85ff
    color: #fff
    border-radius: 12px
    text-decoration: none
    transition: background 0.3s
    &:hover
      background: darken(#2a85ff, 10%)
