@import '../../../styles/helpers'

.custom
    position: relative
    cursor: pointer
    width: 100%
    &.xw
        .tooltipContainer
            left: -40px
            top: calc(100% + 0px)
            width: 270px
        .children
            max-width: 280px
    &.wide
        .tooltipContainer
            top: calc(100% + 5px)
            left: 10px
            width: 270px
        .children
            max-width: 280px
    &.active
        .tooltip
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 200px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 12px
        
.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% - 10px)
    left: 0px
    width: 200px
    padding: 8px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-5px)
    transition: all 0
    +x
        top: calc(100% - 10px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    .groupHeading
        margin-bottom: 4px
        .heading
            color: #EFEFEF
            font-weight: 500
            font-size: 12px
            line-height: 12px
    .org
        display: flex
        align-items: center
        justify-content: left
        gap: 10px
        cursor: pointer
        margin: 5px 0px
        padding: 5px
        border-radius: 8px
        &.active
            background: rgba(42, 133, 255, 0.2)
            .title, .location
                color: rgb(42, 133, 255) !important
        &:not(&.active)
            &:hover
                background: $grey_hover
                .title, .location
                    color: #1A1D1F
        .logo
            img
                width: 28px
                height: 28px
                cursor: pointer
                border-radius: 8px
        .title
            font-size: 14px
            line-height: 18px
            width: 95%
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            width: 80%
                