@import '../../styles/helpers'

.custom
    display: inline-block
    position: relative
    // width: 100%
    cursor: pointer
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateX(10px)
            +x
                transform: translateX(10px)
    .children
        max-width: 600px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        
        
.tooltipContainer
    font-size: 12px
    font-weight: 600
    z-index: 1
    position: absolute
    top: -20%
    left: 100%
    min-width: 250px
    padding: 5px 10px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transition: all 0.3s
    transform: translateX(-10px)
    color: #1A1F1D
    +x
        top: -20%
        left: 100%
        border-radius: 12px
    +m
        top: 30%
        left: 100%
        right: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        left: -12px
        bottom: 30%
        width: 20px
        height: 10px
        transform: rotate(270deg)
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        // +x
        //     right: 13px
        // +m
        //     right: 21px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")