@import ../../../../../styles/helpers

.archiveContent
    text-align: center

.warning
    font-weight: 600
    font-size: 18px
    line-height: 32px
    letter-spacing: -0.02em
    color: #1A1D1F

.warningContent
    font-weight: 500
    font-size: 14px
    line-height: 20px
    letter-spacing: -0.02em
    color: #6F767E

.title
    font-weight: bold
    color: #1A1D1F

.close
    width: 36px
    height: 36px
    border-radius: 50%
    background: $n3
    font-size: 0
    svg
        fill: $n5
        transition: transform .2s
    &:hover
        svg
            transform: rotate(90deg)

.btns
    display: flex
    flex: 1
    margin-top: 25px
    gap: 10px
    .fields
        flex: calc(50% -  8px)
        width: calc(50% -  8px)