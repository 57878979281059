@import ../../../../../styles/helpers

.row
    height: 46px
    display: table-row
    transition: background .2s
    font-size: $table-content
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            input
                border: 1px solid #333
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:last-child
        padding-right: 164px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .email
        color: #6F767E
        font-size: 12px
        font-weight: 500
    .pills
        padding: 2px 8px
        text-align: center
        border-radius: 4px
        width: 100px
        &.active
            background: #EAFAE4
            color: #83BF6E
        &.pending
            background: rgba(42, 133, 255, 0.2)
            color: #2A85FF
        &.failed
            background: rgba(255, 106, 85, 0.2)
            color: #FF6A55

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1

.control
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    // visibility: hidden
    // opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top: 10px