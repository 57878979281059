@import ../../../../../styles/helpers

.wrapper
    margin: 0px -12px 24px
    max-height: 580px
    overflow-y: scroll
    scroll-behavior: smooth
    scrollbar-width: none
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    font-size: $table-content
    +t
        display: none

.col
    position: relative
    display: table-cell
    padding: 0 12px 16px
    vertical-align: middle
    +caption1
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 56px
        &:after
            left: 12px
    &:last-child
        padding-right: 164px
        &:after
            right: 12px

.foot
    margin-top: 20px
    text-align: center
    +t
        margin-top: 0
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px

.heading
    background: #FCFCFC
    display: flex
    align-items: center
    justify-content: left
    flex-wrap: wrap
    padding: 10px 20px
    color: #6F767E
    font-size: 13px
    .cardSort
        flex : 0.2
    .cardImage
        flex: 0.5
    .cardName, .cardId, .cardReward
        flex: 0.7
        margin: 0 0 5px
    .cardIcons
        flex: 0.7

.cardContainer
    background: #FCFCFC
    display: flex
    align-items: center
    justify-content: left
    flex-wrap: wrap
    padding: 10px 20px
    border-top: 1px solid #eee
    border-bottom: 1px solid #eee
    position: relative
    &:hover
        background: #eee
        .control
            visibility: visible !important
            opacity: 1 !important
    .cardSort
        flex : 0.2
    .cardImage
        flex: 0.5
        border-radius: 8px
        img
            border: 2px solid #efefef
            border-radius: 8px
            width: 40px
            height: 40px
    .cardName, .cardId, .cardReward
        flex: 0.7
        color: #1a1d1f
        font-family: Inter
        font-size: $table-content
        font-weight: 700
        letter-spacing: -.01em
        line-height: 20px
        margin: 0 0 5px
        .ssNo
            color : #9A9FA5
            font-size: 13px
            font-weight: 600
    .cardIcons
        flex: 0.7
        .control
            position: absolute
            top: 50%
            left: 95%
            transform: translate(-95%, -50%)
            visibility: hidden
            opacity: 0
            transition: all .2s
