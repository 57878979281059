@import ../../styles/helpers

.form
    position: relative
    width: 360pxs
    &.sm
        width: 200px

.input
    width: 100%
    height: 36px
    padding: 0 20px 0 36px
    background: $n2
    border-radius: 8px
    font-size: 12px !important
    font-weight: 500 !important
    +base1-s
    color: $n7
    +dark
        background: $n6
        color: $n1
    &::placeholder
        color: $shades1
    &.sm
        border-radius: 8px
        height: 28px

.inputWhite
    width: 100%
    height: 36px
    padding: 0 20px 0 36px
    background: #fff
    border-radius: 8px
    +base1-s
    color: $n7
    +dark
        background: $n6
        color: $n1
    &::placeholder
        color: $shades1

.result
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 44px
    padding-right: 4px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1