@import ../../../../../../../styles/helpers

.help
    position: fixed
    top: 0
    right: 0
    bottom: 0
    z-index: 15
    display: flex
    flex-direction: column
    width: 410px
    padding: 24px 24px 0px 24px
    background: $n
    overflow: auto
    -webkit-overflow-scrolling: touch
    transform: translateX(100%)
    transition: transform .3s
    &::-webkit-scrollbar 
        width: 0 !important
        -ms-overflow-style: none
        scrollbar-width: none
    +x
        width: 410px
        padding: 24px 24px 0px 24px
    +m
        width: 100%
    +dark
        background: $n8
    &.active
        transform: translateX(0)
        +d
            box-shadow: 4px 0 32px rgba($n8, .05)
    .groups
        margin : 16px 0px
        border-top: 1px solid #EFEFEF
        background: #fff
        position: sticky
        position: -webkit-sticky
        bottom: 0px
        right : 0px
        .btnGroup
            text-align: right
            padding: 10px 0px
            .save
                padding : 12px 20px
                font-weight : 700
                background : #2A85FF 
                color : #fff
                border-radius : 12px
                width : 76px
                margin : 0px 15px
                &:hover
                    background: darken($p1, 10)
            .cancel
                padding : 12px 20px
                font-weight : 700
                background : #FCFCFC 
                color : #1A1D1F
                border-radius : 12px
                width : 91px
                border: 2px solid #EFEFEF
                &:hover
                    box-shadow: 0 0 0 2px #1A1D1F inset

.head
    display: flex
    align-items: center
    margin-bottom: 0px
    padding: 12px 0px 16px 0px
    & > svg
        margin-right: 12px
        fill: $n7
        +dark
            fill: $n1
    .header
        display : flex
        align-items : center
        gap : 15px
        .headingDash
            width : 16px
            height : 32px
            background : #FFBC99
            border-radius : 5px
        .heading
            font-size : 20px
            font-weight : 600

.menu
    .menuContainer
        margin : 0px 0px
        .rewardMenu
            padding : 0px
            .group
                margin : 16px 0px
                .searchField
                    height : 50px !important
                .btnGroup
                    margin : 25px 0px
                    text-align : right
                    .save
                        padding : 12px 20px
                        font-weight : 700
                        background : #2A85FF 
                        color : #fff
                        border-radius : 12px
                        width : 76px
                        margin : 0px 15px
                        &:hover
                            background: darken($p1, 10)
                    .cancel
                        padding : 12px 20px
                        font-weight : 700
                        background : #FCFCFC 
                        color : #1A1D1F
                        border-radius : 12px
                        width : 91px
                        border: 2px solid #EFEFEF
                        &:hover
                            box-shadow: 0 0 0 2px #1A1D1F inset
                .rewards
                    display : flex
                    align-items : center
                    gap : 25px
                    margin : 24px 0px
                    .icon
                        cursor : pointer
                    .rewardsIcon
                        width: 54px
                        height: 54px
                        img
                            width: 54px
                            height: 54px
                            border-radius : 10px
                    .rewardsName
                        width : 100px
                        .name
                            color: #1A1D1F
                            font-size: 15px
                            font-weight: 700
                        .id
                            font-size: 13px
                            color: #9A9FA5
                            font-weight: 600
                    .rewardsInput
                        width : 125px
                        label
                            font-size: 12px
                            font-weight: 700
                            display : block
                            margin : 0px 0px 5px 0px
                        input 
                            display : block
                            padding : 0px 10px
                            background : #F4F4F4
                            height : 28px
                            width : 125px
                            border-radius : 8px
                            
            .groupRewards
                margin : 16px 0px
                height: 300px
                overflow-y : scroll
                .rewards
                    display : flex
                    align-items : center
                    gap : 25px
                    margin : 24px 0px
                    .icon
                        cursor : pointer
                    .rewardsIcon
                        width: 54px
                        height: 54px
                        img
                            width: 54px
                            height: 54px
                            border-radius : 10px
                    .rewardsName
                        width : 100px
                    .rewardsInput
                        width : 125px
                        label
                            display : block
                            margin : 0px 0px 5px 0px
                        input 
                            display : block
                            padding : 0px 10px
                            background : #F4F4F4
                            height : 35px
                            width : 125px
                            border-radius : 8px

            .groupSearch
                margin : 30px 0px 0px 0px
                .inputGroup
                    display : flex
                    justify-content: space-between
                    .filter
                        height: 48px
                        cursor : pointer
                        padding : 11px
                        border-radius: 8px
                        border: 2px solid #EFEFEF
                        background: #FCFCFC
                        


.close
    margin-left: auto
    svg
        fill: $n7
        transition: fill .2s
        +dark
            fill: $n1
    &:hover
        svg
            fill: $p1

.list
    margin-bottom: auto
    padding: 24px 0
    border-top: 1px solid $n3
    +dark
        border-color: rgba($n4, .2)

.menu
    display: flex
    flex-direction: column

.link
    display: flex
    align-items: center
    height: 48px
    padding: 0 12px
    border-radius: 12px
    color: $n4
    transition: all .2s
    svg
        fill: $n4
        transition: fill .2s
    & > svg
        margin-right: 12px
    &:hover
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &:not(:last-child)
        margin-bottom: 8px
        +x
            margin-bottom: 4px

.arrow
    margin-left: auto
    svg
        fill
        
.counter
    flex-shrink: 0
    min-width: 24px
    margin-left: auto
    border-radius: 6px
    background: $s1
    text-align: center
    line-height: 24px
    color: $n7

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 14
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)
    &.active
        visibility: visible
        opacity: 1