@import '../../styles/helpers'

.container
    padding: 10px 32px

.warning
    text-align: center

.activity
    font-size: $table-content
    font-weight: 600
    padding: 5px 12px
    .icon
        margin-right: 15px
    &:nth-child(even)
        background: #F4F4F4
    .blue
        color: #2A85FF
    .timestamp
        font-size: $table-grey-content
        color: grey