@import ../../../../styles/helpers

.otpScreen
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    overflow-x: hidden
    .otp_outer_container
        .otp_inner_container
            display: flex
            flex-direction: row
            height: 100vh
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            // display: flex
            // justify-content: space-evenly
            // align-items: center
            .Branding
                flex: 1
                padding: 20px 0px
                height: 100vh
                position: relative
                .animation
                    position: absolute
                    left : 50%
                    top: 45%
                    transform: translate(-50%, -50%)
                    .lottieAnimation
                        transform: translate3d(100px, 0px, 0px) !important
                .topCircle
                    position: absolute
                    top : 0%
                    z-index: 2 !important
                .crossFilled
                    position: absolute
                    top : 60%
                    left : 15%
                    z-index: 2
                .fullCircle
                    position: absolute
                    top : 90%
                    left : 30%
                    z-index: 2
                .hollowCircle
                    position: absolute
                    top : 8%
                    left : 88%
                    z-index: 2
            .otpWrapper 
                width: 100%
                flex: 1
                padding: 20px 0px
                justify-content: center
                position: relative
                background: #fff
                +m
                    max-width: 100%
                .hollowCross
                    position: absolute
                    top: 40%
                    right: -2%
                    transform: translateY(-40%)
                .wrapper
                    .filledCross
                        position: absolute
                        top: 90%
                        right: 7%
                    .code
                        max-width: 296px
                        position : absolute
                        top : 50%
                        left : 50%
                        transform : translate(-50%, -50%)
                    
                    

            .info
                margin: 32px 0px
                +body2-s

            .fieldset
                margin: 0 -6px

            .field
                padding: 0px 10px !important
                .input
                    margin: 0 5px !important
                    width: 65px !important
                    height: 72px !important
                    padding: 0 5px !important
                    border-radius: 12px !important
                    border: 2px solid transparent !important
                    background: $n3 !important
                    text-align: center !important
                    +inter
                    font-size: 48px !important
                    font-weight: 600 !important
                    color: $n7 !important
                    transition: all .2s !important
                    &:focus
                        border-color: $shades1 !important
                        background: $n !important
                        &:focus
                            background: transparent !important

            .button
                width: 100%
                margin-top: 12px
                .loader
                    display: none
                    margin-right: 16px

            .note
                margin-top: 32px
                +body2-s
                color: $shades1

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button 
                -webkit-appearance: none !important
                margin: 0 !important

            input[type=number]
                -moz-appearance: textfield !important
