@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 48px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 4px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 40px
        border-radius: 8px 0 0 8px
        font-size: 0
        +t
            display: none
        &:after
            left: 12px
    &:last-child
        padding-right: 100px
        border-radius: 0 8px 8px 0
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .Virtual
        display: inline-block
        background: $Virtual-badge-bg
        color: $Virtual-badge-color
        font-size: $table-content
        font-weight: 600
        text-align: center
        border-radius: 4px
        padding: 2px 0px
        width: 80px
    .Real
        display: inline-block
        color: $Real-badge-color
        background: $Real-badge-bg
        font-size: $table-content
        font-weight: 600
        text-align: center
        border-radius: 4px
        padding: 2px 0px
        width: 80px

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .preview
        display: flex
        width: 32px
        height: 32px
        background: #EFEFEF
        margin-right: 4px
        border-radius: 8px
        position: relative
        img
            width: 32px
            height: 32px
            border: 2px solid #EFEFEF
            border-radius: 8px
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
    .playerDetails
        display: inline-block

.itemName
    width: 125px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px