@import '../../styles/helpers'

.warning
    text-align: center

.wrapper
    height: 475px
    overflow: scroll
    scroll-behavior: smooth
    scrollbar-width: none

.activity
    font-size: $table-content
    font-weight: 600
    padding: 10px 12px
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 5px
    &:nth-child(even)
        background: #F4F4F4
    .performedBy
        width: calc(20% - 8px)
        flex: calc(20% - 8px)
    .icon
        width: calc(4% - 8px)
        flex: calc(4% - 8px)
    .action
        width: calc(50% - 8px)
        flex: calc(50% - 8px)
        padding-right: 12px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
    .time
        width: calc(20% - 8px)
        flex: calc(20% - 8px)
    // .icon
    //     margin-right: 15px
    // &:nth-child(even)
    //     background: #F4F4F4
    // .blue
    //     color: #2A85FF
    // .timestamp
    //     font-size: $table-grey-content
    //     color: grey


.item
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 12px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        background : #272B30
        color : #F4F4F4
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)
    .staging
        background: $staging-bg
        width: 12px
        height: 12px
        border-radius: 50%
    .develop
        background: #9747FF
        width: 12px
        height: 12px
        border-radius: 50%
    .production
        background: $production-bg
        width: 12px
        height: 12px
        border-radius: 50%


.production
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #00D59C
.staging
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #9747FF
.develop
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #FFD944
.global
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: white