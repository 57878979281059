@import '../../../styles/helpers'

.custom
    position: relative
    overflow: visible
    width: 100%
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 500px
        width: 100%
        
.tooltipContainer
    z-index: 1
    position: absolute
    width: 400px
    max-width: 400px
    padding: 5px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    &.left
        left: 30px
        bottom: calc(50% - 20px)
        &:before
            transform: rotate(-90deg)
            content: ""
            position: absolute
            left: -10px
            top: 40%
            width: 20px
            height: 10px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    &.normal
        bottom: calc(100% + 18px)
        left: 0px
        &:before
            transform: rotate(180deg)
            content: ""
            position: absolute
            left: 10px
            top: 100%
            width: 20px
            height: 10px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
            +x
                right: 13px
            +m
                right: 21px
    &.invert
        top: calc(100% + 18px)
        left: 0px
        &:before
            content: ""
            position: absolute
            left: 10px
            bottom: 100%
            width: 20px
            height: 10px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto

    .text
        padding: 2px 0px
        cursor: pointer
        color: #6F767E
        font-weight: 700
        font-size: 12px
        line-height: 22px
        ol
            li
                list-style-type: decimal-leading-zero
        