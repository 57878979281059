@import '../../../../../../styles/helpers'

.icon
    display: flex
    width: 100%
    justify-content: right
    .edit
        cursor: pointer

.wrapper
    padding: 0px

.overview
    // margin-top: 5px
    // height: 100%
    // padding: 0px 20px 20px 0px
    // display: flex
    // align-items: center
    // justify-content: space-between
    // gap: 20px
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    margin: 20px -6px 20px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px    
    .cardBody
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        .info
            .group
                display: flex
                flex-wrap: wrap
                align-items: center
                margin: 16px -6px -12px
                &:first-child
                    margin: 0px -6px -12px
                +m
                    display: block
                    margin: 0
                svg
                    fill: #6F767E !important
                .field, .fieldRadio
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin: 0 6px 12px
                    +m
                        width: 100%
                        margin: 12px 0 0
                    .radioLabel
                        padding: 20px 0px 0px 0px
                        font-size: 14px
                        font-weight: 600
                        color : #33383F
                    .variants
                        display: flex
                        flex-direction: row
                        margin: 8px 0px 12px 0px
                        .radio
                            margin: 0px 34px 0px 0px
                .cardAction
                    display: flex
                    flex-direction: row
                    align-items: center
                    gap: 10px
                    margin-left: 6px

.preview
    width: 180px
    display: block
    .previewImage
        margin: 0px auto
        width: 100px
        height: 100px
        border-radius: 50%
        border: 2px solid #EFEFEF
        img
            width: 100px
            height: 100px
            border-radius: 50%
            object-fit: cover
    .actions
        display: flex
        justify-content: center
        gap: 8px
        margin-top: 16px

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important

.buttonCancel
    padding: 12px 20px
    font-weight: 700
    background: #FCFCFC 
    font-size: 15px
    color: #1A1D1F
    border-radius: 12px
    height: 48px
    border: 2px solid #EFEFEF
    &:hover
        border: 2px solid #1A1D1F