@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:nth-child(odd)
        background: $n2
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 8px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 120px
        border-radius: 8px 0 0 8px
        text-align: center
        &:after
            left: 12px
    &:nth-child(2)
        padding-left: 20px
    &:nth-child(3)
        width: 150px
    &:last-child
        width: 300px
        padding-right: 100px
        border-radius: 0 8px 8px 0
        +t
            padding: 0
        &:after
            right: 12px
    .name,.quantity,.amount
        font-size: 13px
        color: #33383F
        font-weight: 600
        line-height: 24px
        width: 300px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    .score
        font-size: 12px
    svg
        width: 28px
        height: 28px
    .id
        font-size: 12px
        color : #6F767E
    .player
        display: flex
        align-items: center
        gap : 20px
        .playerInsigne
            width: 28px
            height: 28px
            img
                width: 28px
                height: 28px
                border-radius: 50%
        .playerDetails
            .userName
                width: 300px
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                font-size: 12px
                line-height: 18px
                color: #33383F
                font-weight: 600
            .playerId
                width: 300px
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
                font-size: 10px
                line-height: 12px
                color: #6F767E
                font-weight: 500



.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .details,
    .price,
    .product,
    .empty,
    .date
        display: none
    .details,
    .price,
    .empty,
    .product
        +t
            margin : 0px 5px 0px 0px
            text-align : left
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1

.preview
    flex-shrink: 0
    width: 90px
    height: 90px
    background : #EFEFEF
    margin-right: 20px
    border-radius: 8px
    // overflow: hidden
    position : relative
    +dark
        background: rgba(167, 170, 247, 0.15)
    +t
        width: 104px
        height: 116px
    img
        width: 90px
        height: 90px
        border: 2px solid #EFEFEF
        border-radius: 8px
        // width : 75px
        // height : auto
        position : absolute
        top : 50%
        left : 50%
        transform : translate(-50%, -50%)

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 44px
        +title2

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px