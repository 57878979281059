@import ../../../styles/helpers

.resourceContainer
    .resource
        .contentHeading
            font-size: 15px
            line-height: 24px
            color: #6F767E
            font-weight: 500
            padding: 24px 0px 0px 0px
        .resourceLinks
            display: flex
            flex-wrap: wrap
            .general, .dashboard, .development
                flex: 0 0 calc(33.33% - 0px)
                width: calc(33.33% - 0px)
                .title
                    padding: 24px 0px 10px 0px
                .navLink
                    text-decoration: underline
                    font-size: 12px
                    font-weight: 500
                    line-height: 24px
                    padding-bottom: 8px
                    .link
                        cursor: pointer
                        a
                            color: #2A85FF
                            text-decoration: underline !important
                            text-decoration-color: #2A85FF !important
                        .tab
                            margin-left: 5px