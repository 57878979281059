.wrapper
    display: flex
    align-items: center
    gap: 50px
    margin: 20px 0px

.loader
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)