.control
    margin: 0px 10px 0px 10px
    font-size: 12px
    font-weight: 600
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    border-radius: 4px
    text-align: center


.import
    padding: 2px 0px
    cursor: pointer
    color: #6F767E
    font-weight: 600
    font-size: 12px
    line-height: 16px
    &:hover
        color: #1A1D1F