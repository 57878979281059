
.wrapper
    .container
        height: 605px
        display: flex
        flex-direction: column
        .progress
            display: flex
            align-items: center
            justify-content: center
            gap: 25px
            margin: 20px 0px
            &.active
                margin: 100px 0px 20px 0px
            .progressContent
                display: flex
                align-items: center
                gap: 5px
                .progressStatus
                    width: 20px
                    height: 20px
                    background: #E4E4E4
                    border-radius: 50%
                .content
                    font-size: 12px
                    font-weight: 500
                .icon
                    margin-bottom: 3px
        .modalHeader
            display: flex
            align-items: center
            justify-content: left
            gap: 6px
            .block
                background: #FFBC99
                width: 16px
                height: 32px
                border-radius: 4px
            .modalHeading
                font-weight: 600
                font-size: 20px
                line-height: 32px
                color: #1A1D1F
        .modalBody
            flex: 1
            .bodyWrapper
                height: 100%
                padding: 16px 0px 12px 0px
        .modalFooter
            display: flex
            gap: 10px
            align-items: center