@import ../../styles/helpers

.header
    position: fixed
    top: 0
    left: 0px
    right: 0
    z-index: 102
    display: flex
    align-items: center
    padding: 6px 32px 6px 10px
    background: #272B30
    &.wide
        left: 0px
    &.progress
        pointer-events: none
        cursor: not-allowed
        opacity: 0.5
    +x
        left: 0px
        padding: 6px 32px 6px 10px
    +d
        left: 0px
    +m
        left: 0
        padding: 6px 32px 6px 10px
    +dark
        background: $n7
        box-shadow: inset 1px 0px 0px $n8, inset 0 -1px 0px $n8

.orgLogo
    .logo
        .pic
            height: 24px

.mainHeader
    display: flex
    width: 100%
    align-items: center
    justify-content: end
    .burger
        display: none
        flex-direction: column
        justify-content: center
        align-items: center
        width: 48px
        height: 48px
        // margin-right: auto
        +m
            display: flex
        &:before,
        &:after
            content: ""
            width: 16px
            height: 2px
            border-radius: 2px
            background: $n4
        &:before
            margin-bottom: 6px
    .mobile
        display : none
        margin : 10px
        +m
            display: block
        .NavDropdown
            .mobileNavDropdow
                padding: 10px 20px
                background: $custom-active-background-link
                color: $custom-active-link
                text-align: center
                font-weight: bold
                border: none
                border-radius: 12px
                appearance: none
                option
                    padding: 10px 20px
                    

.control
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.control .button,
.control > div,
.buttonSearch
    &:not(:last-child)
        margin-right: 24px

.control .button
    +t
        display: none

.buttonSearch
    display: none
    width: 48px
    height: 48px
    +m
        display: inline-block
    svg
        fill: $n4
        transition: fill .2s
    &.active
        svg
            fill: $n7
            +dark
                fill: $n1

.search
    +m
        visibility: hidden
        opacity: 0
        transition: all .2s
        &.visible
            visibility: visible
            opacity: 1

.btns
    display: flex
    align-items: center
    margin-left: auto
    +m
        margin-left: 0

.link
    margin-right: 32px
    +button1
    color: $n7
    transition: color .2s
    +m
        margin-right: 24px
    +dark
        color: $n1
    &:hover
        color: $p1

.subdropdown
    display: flex
    position: absolute
    left: 45%
    transform: translate(-45%)
    +m
        visibility: hidden

.headContent
    display: flex
    align-items: center
    gap: 20px

.btn, .btn2
    display: block
    cursor: pointer
    color: #fff
    padding:4px 12px
    border-radius: 8px
    background: linear-gradient(-45deg , #0538ff, #8930fd, #2a85ff)
    background-position: 100% 20%
    font-size: 12px
    -webkit-animation: anime 30s ease infinite
    animation: anime 30s ease infinite
    background-size: 600%

.info
    display: flex
    align-items: center
    gap: 4px

.btn2
    position: absolute
    margin-top: -70px
    z-index: -1
    filter: blur(30px)
    opacity: 0.8

@keyframes anime
    0%
        background-position: 0% 50%
    50%
        background-position: 100% 50%
    100%
        background-position: 0% 50%


.item,
.link
    cursor: pointer
    display: flex
    align-items: center
    margin: 0px 15px
    width: 100%
    height: 28px
    padding: 0 12px
    border-radius: 8px
    font-size: 12px
    font-weight: 500
    white-space: nowrap
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        //background: $n3
        background : $custom-active-background-link
        color : $custom-active-link
        // box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)

.headHelp
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid #EFEFEF
    color: #FFFFFF
    font-weight: 500
    svg
        fill: #FFFFFF

        