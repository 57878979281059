@import ../../styles/helpers

.loaderContainer
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex: 1
    background: #fff
    .loaderWrapper
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        flex: 1
        .loaderTitle
            margin-top: 8px
            .title
                font-size: 13px
                line-height: 18px
                text-align: center
                color: grey
                font-weight: 500

    
.page
    padding: 80px 0 0 280px
    +x
        padding: 80px 0 0 280px
    +d
        padding-left: 96px
    +t
        overflow: hidden
    +m
        padding-left: 0
    &.wide
        padding: 80px 0px 6px 48px
        .background
            .cross
                right: 0px
                top: 15%
                transform: translate(0px, -15%)
            .outlineCircle
                left: calc(-8% + 8px)
                +w
                    left: calc(-9% + 8px)
                +x
                    left: calc(-10% + 8px)
            .circleBottomRight
                right: calc(-17% + 8px)
                top: 50%
                transform: translate(calc(17% - 8px), -50%)
                +w
                    right: calc(-18% + 8px)
                    top: 50%
                    transform: translate(calc(18% - 8px), -50%)
                +x
                    right: calc(-19% + 8px)
                    top: 50%
                    transform: translate(calc(19% - 6px), -50%)
            .bottomOutLineCross
                bottom: 10%
                right: calc(-3% + 8px)
                transform: translate(calc(3% - 8px), -10%)
                +w
                    right: calc(-3% + 8px)
                    bottom: 10%
                    transform: translate(calc(3% - 8px), -10%)
                +x
                    right: calc(-4% + 8px)
                    bottom: 10%
                    transform: translate(calc(4% - 8px), -10%)
            .circleBottomLeft
                width: 25px
                height: 25px
                z-index: 1
                position: absolute
                left: -3px
                bottom: 7%
        .inner
            padding: 0px

.sidebar
    +m
        transform: translateX(-100%)
        transition: transform .3s
        &.visible
            transform: translateX(0)

.inner
    display: flex
    flex-direction: column
    min-height: calc(100vh - 96px)
    padding: 100px 32px 5px 32px
    position: relative
    overflow-x: hidden
    +x
        padding: 100px 24px 28px 32px
    +m
        padding: 48px 16px 20px 16px
    &.view
        padding: 0px
        +x
            padding: 0px
        +m
            padding: 0px
        .background
            width: calc(100% -  10px)
            height: calc(100vh - 80px)
            .cross
                right: -5px
                top: 15%
                transform: translate(5px, -15%)
            .outlineCircle
                left: calc(-10% + 8px)
                top: 35%
                transform: translate(calc(10% - 8px), -35%)
                +w
                    left: calc(-11% + 8px)
                    top: 35%
                    transform: translate(calc(11% - 8px), -35%)
                +x
                    left: calc(-13% + 8px)
                    top: 35%
                    transform: translate(calc(13% - 8px), -35%)
            .outLineCross
                left: calc(-3% + 8px)
                top: 60%
                transform: translate(calc(3% - 16px), -60%)
                +x
                    left: calc(-3% + 8px)
                    top: 65%
                    transform: translate(calc(3% - 20px), -65%)
            .bottomOutLineCross
                bottom: 10%
                right: calc(-5% + 8px)
                transform: translate(calc(5% - 8px), -10%)
                +w
                    right: calc(-5% + 8px)
                    bottom: 10%
                    transform: translate(calc(5% - 8px), -10%)
                +x
                    right: calc(-5% + 8px)
                    bottom: 10%
                    transform: translate(calc(5% - 8px), -10%)
            .circleBottomRight
                right: calc(-20% + 8px)
                top: 50%
                transform: translate(calc(20% - 8px), -50%)
                +w
                    right: calc(-22% + 8px)
                    top: 50%
                    transform: translate(calc(22% - 8px), -50%)
                +x
                    right: calc(-24% + 8px)
                    top: 50%
                    transform: translate(calc(24% - 6px), -50%)
            .circleBottomLeft
                width: 25px
                height: 25px
                z-index: 1
                position: absolute
                left: -3px
                bottom: 10%
    &.resize
        padding: 60px 32px 5px 32px
        +x
            padding: 60px 24px 28px 32px
        +m
            padding: 36px 16px 20px 16px
    .background
        position: absolute
        top: 0px
        left: 0px
        right: 0px
        bottom: 0px
        .cross
            z-index: 1
            position: absolute
            right: -5px
            top: 15%
            transform: translate(5px, -15%)
        .outlineCircle
            z-index: 1
            position: absolute
            left: calc(-10% + 8px)
            top: 35%
            transform: translate(calc(10% - 8px), -35%)
            +w
                left: calc(-11% + 8px)
                top: 35%
                transform: translate(calc(11% - 8px), -35%)
            +x
                left: calc(-13% + 8px)
                top: 35%
                transform: translate(calc(13% - 8px), -35%)
        .outLineCross
            z-index: 1
            position: absolute
            left: calc(-3% + 8px)
            top: 55%
            transform: translate(calc(3% - 16px), -55%)
            +x
                left: calc(-3% + 8px)
                top: 60%
                transform: translate(calc(3% - 20px), -60%)
        .bottomOutLineCross
            z-index: 1
            position: absolute
            bottom: 10%
            right: calc(-5% + 8px)
            transform: translate(calc(5% - 8px), -10%)
            +w
                right: calc(-5% + 8px)
                bottom: 10%
                transform: translate(calc(5% - 8px), -10%)
            +x
                right: calc(-5% + 8px)
                bottom: 10%
                transform: translate(calc(5% - 8px), -10%)
        .circleBottomRight
            width: 300px
            height: 300px
            z-index: 1
            position: absolute
            right: calc(-20% + 8px)
            top: 50%
            transform: translate(calc(20% - 8px), -50%)
            +w
                right: calc(-22% + 8px)
                top: 50%
                transform: translate(calc(22% - 8px), -50%)
            +x
                right: calc(-24% + 8px)
                top: 50%
                transform: translate(calc(24% - 6px), -50%)
        .circleBottomLeft
            width: 25px
            height: 25px
            z-index: 1
            position: absolute
            left: -2px
            bottom: 20%


.container
    display: flex
    flex-direction: column
    flex-grow: 1
    width: 100%
    // max-width: 1200px
    // margin: 0 auto
    &.wide
        max-width: 100%
    &.view
        .title
            margin: 0px 0px 10px 0px
            

.title
    margin: -12px 0px 20px 0px
    +m
        margin-bottom: -16px 0px 16px 0px

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 120
    background: rgba($n2, .8)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +dark
        background: rgba($n6, .9)
    &.active
        visibility: visible
        opacity: 1