@import  '../../../../styles/helpers'

.loader
    margin: 100px auto

.container
    &.active
        width: 1020px
        margin: 80px auto
        .addOnsList
            padding: 32px 15px  
    .addOns
        border-radius: 8px
        background: #fff
        margin: 0px 24px
        padding: 16px 0px
        .specialOffer
            margin: 32px 16px
            border: 2px solid #EFEFEF
            border-radius: 8px
            .offer
                display: flex
                justify-content: space-between
                align-items: center
                padding: 24px
                .content
                    .heading
                        font-size: 16px
                        font-weight: 600
                        color: #11142D
                        .pill
                            margin-left: 10px
                            background: #2A85FF
                            color: #fff
                            font-weight: 600
                            font-size: 13px
                            padding: 3px 10px
                            border-radius: 6px
                    .description
                        font-size: 13px
                        font-weight: 600
                        color: #808191
        .title
            margin-bottom: 20px
            text-align: center
            font-size: 22px
        .procced
            margin: 20px 0px 0px 16px
            .buyAddOn
                display: flex
                align-items: center
                justify-content: center
        .addOnsList
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            align-items: center
            gap: 16px
            padding: 0px 16px
            .addOn
                cursor: pointer
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                border: 2px solid #E3E6E9
                border-radius: 8px
                padding: 10px 12px
                &.disabled
                    pointer-events: none
                    cursor: default
                    opacity: 0.5
                .addOnDetail
                    display: flex
                    justify-content: space-between
                    margin-bottom: 4px
                    .detail
                        .name
                            color: #11142D
                            font-size: 12px
                            font-weight: 600
                        .description
                            color: #808191
                            font-size: 12px
                            font-weight: 600
                .addOnPurchase 
                    display: flex
                    justify-content: space-between
                    align-items: end
                    height: 25px
                    .price
                        color: #2A85FF
                        font-weight: 600
                        font-size: 12px
                        border-radius: 6px
                    .quantity
                        display: flex
                        align-items: center
                        gap: 10px
                        font-size: 13px
                        color: #1A1D1F
                        height: 25px
                        .amount
                            font-size: 12px
                            border: 1px solid #E3E6E9
                            border-radius: 4px
                            padding: 0px 5px
                        .operation
                            display: flex
                            flex-direction: column
                            .add
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                height: 12px
                                svg
                                    transform: rotate(-90deg)
                            .remove
                                user-select: none
                                -webkit-user-select: none
                                -moz-user-select: none
                                svg
                                    transform: rotate(90deg)