@import ../../styles/helpers
    
.sideMenu
    background : #272B30
    width : 100%
    max-width : 72px
    height: 100vh
    padding: 32px 0px
    .sideMenuContainer
        .orgProfiles
            display: flex
            align-items: center
            margin: 5px 0px
            position: relative
            .activeSvg
                position: absolute
                left: -5px
                &.active
                    left: 0px  
            .orgLogo
                display: block
                margin : 0px auto
                width: 40px
                height: 40px
            +x
                margin-bottom: 4px
            +m
                margin-left: auto
        .divider
            text-align: center
        .orgApps
            padding : 0px 0px
            position: relative
            .appsList
                display : flex
                align-items : center
                .activeApps
                    position: absolute
                    left: -5px
                    &.active
                        left: 0px
                        transition: 0.25s ease-in-out
                .appIcon
                    width : 40px
                    height : 40px
                    display : block
                    margin : 8px auto
                    img
                        border-radius : 8px
                        width : 40px
                        height : 40px   