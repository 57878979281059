.fileInformation
    height: 100%    
    .container
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%
        &.type
            justify-content: space-evenly
        .notice
            .sampleFiles, .process, .note
                padding-bottom: 16px
                p
                    color: #6F767E
                    font-size: 12px
                    font-weight: 500
                    line-height: 20px
        .fileSamples
            .group
                display: flex
                flex: 1
                flex-wrap: wrap
                align-items: center
                margin: 12px 0 4px
                .files
                    background: #F4F4F4
                    display: flex
                    align-items: center
                    flex: 0 0 calc(50% - 12px)
                    width: calc(50% - 12px)
                    margin: 0 12px 4px 0
                    padding: 8px 16px
                    border-radius: 12px
                    .fileInfo
                        flex: 2
                        padding-left: 10px
                        .fileName
                            color: #33383F
                            font-size: 14px
                            line-height: 24px
                            font-weight: 500
                        .fileDetail
                            color: #33383F
                            font-size: 10px
                            line-height: 24px
                            font-weight: 400
                    .downloadIcon
                        background: #FCFCFC
                        width: 24px
                        height: 24px
                        border-radius: 50%
                        position: relative
                        cursor: pointer
                        .icon
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)