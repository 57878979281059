@import '../../styles/helpers'


.custom
    cursor: pointer
    position: relative
    width: 100%
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
        .invertedContainer
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
    .children
        max-width: 100px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 7px

.invertedContainer
    z-index: 1
    position: absolute
    bottom: calc(100% + 18px)
    left: -106px
    width: 150px
    padding: 10px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    +x
        bottom: calc(100% + 18px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    .text
        padding: 4px 0px
        cursor: pointer
        color: #6F767E
        font-weight: 600
        font-size: 16px
        line-height: 30px
        .textBlock
            fill:#6F767E
        &:hover
            color: #1A1D1F
            .textBlock
                border-radius: 8px
                background:#f4f4f4
                fill:#1A1D1F
          
.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% + 18px)
    left: -106px
    width: 150px
    padding: 10px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    +x
        top: calc(100% + 15px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    .text
        padding: 4px 0px
        cursor: pointer
        color: #6F767E
        font-weight: 600
        font-size: 16px
        line-height: 30px
        .textBlock
            fill:#6F767E
        &:hover
            color: #1A1D1F
            .textBlock
                border-radius: 8px
                background:#f4f4f4
                fill:#1A1D1F
                border-radius:8px