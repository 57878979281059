.add
    cursor: pointer
    margin-left: 8px
    svg
        transition: 0.25s ease-in-out
        &:hover
            transform: rotate(180deg)

.tooltip
    svg
        fill : #9A9FA5
        margin-bottom: 5px