@import ../../../../../styles/helpers

.addContentModal
    .addContent
        .modalHeading
            display: flex
            gap: 10px
            margin: 0px 0px 32px 0px
            font-weight: 600
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 12px 
                height: 28px
                background: #CABDFF
                border-radius: 4px
        .modalBody
            .contentBody
                .payout
                    margin: 30px 0px
                    .group
                        display: flex
                        flex-wrap: wrap
                        align-items: center
                        margin: 0 -6px -12px
                        +m
                            display: block
                            margin: 0
                        .editor
                            width: 100%
        .modalFooter
            padding-top: 32px
            .btnGroup
                display: flex
                align-items: center
                gap: 10px