@import '../../../styles/helpers'

.dropdown
    width: 250px

.secondaryNavigation
    width: 100%
    height: 80px
    background: #FCFCFC
    z-index: 1
    .secondaryContainer
        .navigation
            display: flex
            align-items: center
            height: 40px
            padding: 0px 32px 0px 20px
            border-bottom: 2px solid #EFEFEF
            .wrapper
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                .list
                    display: flex
                    align-items: center
                    gap: 15px
                .actionDropdown
                    z-index: 2
                    .eventOptions
                        height: 30px
                        display: flex
                        align-items: center
                        justify-content: left
                        gap: 20px
                        .zoom
                            cursor: pointer
                            font-size: 12px
                            &:hover
                                .zoomIn
                                    transform: scale(1.2)
                                .zoomOut
                                    transform: scale(0.8)
                            svg
                                margin-right: 5px
                            .zoomIn, .zoomOut
                                transition: 0.2s ease-in-out
                        .refresh
                            cursor: pointer
                            font-size: 12px
                            &:hover
                                .icon
                                    transform: rotate(180deg)
                            svg
                                margin-right: 5px
                            .icon
                                transition: 0.2s ease-in-out
                    .addIcon
                        margin-right: 2px

        .options
            height: 40px
            padding: 5px 32px 5px 34px
            border-bottom: 2px solid #EFEFEF
            .wrapper
                display: flex
                align-items: center
                gap: 15px
                .filtersNsort
                    display: flex
                    align-items: center
                    justify-content: left
                    gap: 20px
                .live
                    display: flex
                    align-items: center
                    font-size: $font_12
                    gap: 5px
                    z-index: 2
                    .icon
                        svg
                            size: 16px
                            height: 16px
                    .switch
                        margin-top: 5px
                    .dateRange
                        display: flex
                        align-items: center
                        border: 2px solid #efefef
                        border-radius: 8px
                        padding: 2px 8px
                        cursor: pointer
                        .dateButton
                            display: flex
                            align-items: center
                            gap: 10px
                    .action
                        margin: -2px 0px 0px 5px
                        border: 2px solid #efefef
                        border-radius: 8px
                        padding: 2px 8px
                        cursor: pointer
                        svg
                            width: 16px
                            height: 16px

                .search
                    margin-left: auto
                    .selection
                        display: flex
                        align-items: center
                        gap: 10px

.toggle
    display: flex
    background: #F4F4F4
    border-radius: 8px
    width:72px
    height:28px
    padding: 2px 4px
    .link
        flex: 1
        flex-shrink: 0
        width: 25%
        height: 30px
        border-radius: 4px
        font-weight: bold
        p
            transition: fill .2s
        &.active
            background: #FCFCFC
            font-weight: bold
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
        &:not(:last-child)
            margin-right: 0px

.item
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    margin: 0px 6px
    max-width: 175px
    height: 40px
    padding: 0 8px
    font-size: 13px
    font-weight: 500
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        color: $custom-active-link
        &:after
            content: ''
            position: absolute
            bottom: 0
            left: 8px
            width: calc(100% - 16px)
            height: 2px
            background-color: $custom-active-link
            animation: progress 1s normal forwards ease-in
            animation-timing-function: linear
    .navText
        display: flex
        align-items: center
        .bankName
            max-width: 125px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

.button
    height: 28px
    display: flex
    align-items: center
    background: rgb(42, 133, 255)
    color: #fff
    font-size: 12px
    padding: 0px 12px
    border-radius: 8px
    &:hover
        .addIcon
            transform: rotate(180deg)
    &.dropdown
        padding: 0px 8px
        border-top-left-radius: 8px
        border-bottom-left-radius: 8px
        border-top-right-radius: 0px
        border-bottom-right-radius: 0px
    .add
        margin-bottom: 2px
        .addIcon
            fill: #fff
            transition: 0.25s ease-in-out

@keyframes progress
    0%
        width: 0%
    20%
        width: calc(100% - 110px)
    40%
        width: calc(100% - 75px)
    60%
        width: calc(100% - 40px)
    80%
        width: calc(100% - 16px)
    100%