.control
    display: flex
    align-items: center
    gap: 10px
    .buttons
        height: 28px
        padding: 2px 8px
        border: 2px solid #EFEFEF
        border-radius: 8px
        font-size: 12px
        color: #6F767E
        cursor: pointer
        &:hover
            border-color: #1A1D1F
            color: #1A1D1F
            path
                fill: #1A1D1F
        .sortIcon
            margin-right: 5px
            .up, .down
                path
                    fill: #6F767E
                &.active
                    path
                        fill: #1A1D1F
            .down
                transform: rotate(180deg)
        &.active
            color: #1A1D1F
            font-weight: 600
            border-color: #1A1D1F
            .icon
                fill: #1A1D1F
        .icon
            margin-right: 5px
            fill: #6F767E 
        .countPill     
            display: inline-block
            text-align: center
            margin-left: 8px
            background: #FFBC99
            font-size: 12px
            font-weight: 600
            color: #1A1D1F
            width: 20px
            height: 20px
            border-radius: 6px        
