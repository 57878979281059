@import '../../../../styles/helpers'

.buttons
    padding: 2px 5px
    border: 2px solid #EFEFEF
    border-radius: 8px
    font-size: 12px
    color: #6F767E
    cursor: pointer
    &:hover
        border-color: #1A1D1F
        color: #1A1D1F
    .sortIcon
        margin-right: 5px
        .up, .down
            path
                fill: #6F767E
            &.active
                path
                    fill: #1A1D1F
        .down
            transform: rotate(180deg)
    &.active
        color: #1A1D1F
        font-weight: 600
        border-color: #1A1D1F
        .icon
            fill: #1A1D1F
    .icon
        margin-right: 5px
        fill: #6F767E 