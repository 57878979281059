@import ../../../../../../styles/helpers

.control
    display: flex
    align-items: center

.button
    display : flex
    align-items : center
    justify-content : center
    flex-shrink: 0
    width: 32px
    height: 32px
    padding : 8px
    border-radius: 50%
    +d
        background: $n3
    +dark
        background: $n7
        +d
            background: $n6
    &:not(:last-child)
        margin-right: 24px