@import ../../../styles/helpers

.progressionContainer
    .progression
        text-align: left
        .emptyState
            padding: 130px 0px 95px 0px
            text-align: center
            .emptyFolder
                padding: 0px 0px 10px 0px
        .viewAll
            text-align: center
            .all
                text-decoration: underline
                font-size: 12px
                color: #2A85FF
        .activityFeedsContainer
            min-height: 310px
            max-height: 310px
            padding-top: 5px
            overflow-y: scroll
            scroll-behavior: smooth
            scrollbar-width: none
            .activityFeed
                font-size: 12px
                font-weight: 600
                // line-height: 24px
                color: #1A1D1F
                margin-bottom: 15px
                gap: 12px
                display: flex
                justify-content: left
                align-items: center
                .icon
                    .icons
                        width: 18px
                        height: 18px
                .identifier
                    color: #2A85FF

.activityTable 
    width: 100%
    overflow: hidden
    .title
        padding: 8px
        background: #f4f4f4
        color: #333
        display: inline-block
        border-radius: 8px
        width: 100%
        text-align: center

.activityList 
    list-style: none
    padding: 10px 0px 0px
    margin: 0

.activityItem 
    display: flex
    align-items: center
    padding: 5px
    border-bottom: 1px solid #efefef
    cursor: pointer
    &:last-child
        border-bottom: none
    &:hover
        border-radius: 8px
        background: #F4F4F4

.iconWrapper 
  margin-right: 10px
  svg
    &.create
        fill: #4D8F65
    &.edit
        fill: #2A85FF
    &.delete
        fill: #B3281D

.activityContent 
    flex: 1
    display: flex
    flex-direction: column

.performedBy 
    align-items: center
    font-size: $font_12
    .activity
        .userdetail
            display: flex
            align-items: center
            justify-content: space-between
            .name
                font-style: italic
                font-weight: 500
                color: #6F767E
            .timeStamp
                color: #6f767e
                font-size: 10px

.description 
    font-weight: 400
    font-size: $font_12
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.timeStamp 
    margin-left: auto
    color: #6f767e
    font-size: 10px

.cardSave
    text-align: center
    margin-top: 8px

.save
    font-size: $font_12
    display: block
    margin: 0px auto
    color: #2A85FF
    &:hover
        text-decoration: underline

.item
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 12px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        background : #272B30
        color : #F4F4F4
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)
    .staging
        background: $staging-bg
        width: 12px
        height: 12px
        border-radius: 50%
    .develop
        background: #9747FF
        width: 12px
        height: 12px
        border-radius: 50%
    .production
        background: $production-bg
        width: 12px
        height: 12px
        border-radius: 50%


.production
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #00D59C
.staging
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #9747FF
.develop
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #FFD944
.global
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: white