@import  '../../../../styles/helpers'

.wrapper
    position: relative
    overflow-x: hidden
    height: 100vh
    .inner
        .topLeft
            position: absolute
            left: 0%
        .bottomLeft
            position: absolute
            left: -2%
            bottom: 0%
        .middleRight
            position: absolute
            top: 35%
            right: -3%
            transform: rotate(180deg)
        .crossLeftMiddle
            position: absolute
            top: 40%
            left: 5%
        .crossRightTop
            position: absolute
            top: 10%
            right: 5%
        .crossRightBottom
            position: absolute
            bottom: 20%
            right: 5%
        .fullCircle
            position: absolute
            top: 4%
            left: 30%
        .circleBottom
            position: absolute
            bottom: 1%
            left: 40%
        .intercept
            position: absolute
            left: 70%
            bottom: 0%
            .overlapCircle
                position: absolute
                left: -10%
                top: 50%
        .leftFilledCross
            position: absolute
            top: 50%
            left: 5%
        .rightBottomFilledCross
            position: absolute
            top: 7%
            right: 3%
        .rightTopFilledCross
            position: absolute
            bottom: 15%
            right: 7%
        .bottomOutline
            position: absolute
            bottom: 0%
            left: 25%
    .container
        margin: 80px auto
        width: 1105px
        position: relative
        z-index: 1
        .billings
            border-radius: 8px
            background: #fff
            padding: 32px 0px
            .title
                text-align: center
                font-size: 24px
                font-weight: 600
                color: #1A1D1F
            .billingContainer
                padding: 32px
                .billingDetails
                    .group
                        width: 100%
                        display: flex
                        flex-wrap: wrap
                        margin: 25px -6px -12px
                        .block
                            flex: 0 0 calc(100% - 12px)
                            width: calc(100% - 12px)
                            margin: 0 6px 12px
                        .field
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                        .update
                            display: flex
                            align-items: center
                            justify-content: center

.overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background: rgba($n2, .5)
    visibility: hidden
    opacity: 0
    transition: all .2s
    &.active
        visibility: visible
        opacity: 1