@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $font_12
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 10px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:last-child
        padding-right: 50px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1

    .preview
        display: flex
        width: 40px
        height: 40px
        background : #EFEFEF
        margin-right: 20px
        border-radius: 50%
        position : relative
        +dark
            background: rgba(167, 170, 247, 0.15)
        +t
            width: 20px
            height: 20px
        img
            width: 40px
            height: 40px
            border: 2px solid #EFEFEF
            border-radius: 50%
            position : absolute
            top : 50%
            left : 50%
            transform : translate(-50%, -50%)
    .playerDetails
        display: inline-block

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: visible
    opacity: 1
    transition: all .2s
