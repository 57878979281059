@import ../../../../styles/helpers

.container
    margin: 20px 0px
    .group
        display: flex
        flex-wrap: wrap
        margin: 0 -6px -12px
        +m
            display: block
            margin: 0
        .field
            flex: 0 0 calc(50% - 12px)
            width: calc(50% - 12px)
            margin: 0 6px 12px
            +m
                width: 100%
                margin: 15px 0 0
    .content
        margin-top: 20px
        ul
            list-style-type: disc
            margin-left: 20px !important
        ol
            list-style-type: lower-alpha
            margin-left: 20px !important
    
.cardAction
    display: flex
    align-items: center
    justify-content: left
    gap: 15px
    .save, .cancel
