@import ../../../../../../styles/helpers

.calendar
    display: block
    margin-left: auto
    position: relative !important
    width: 50%
    &.active
        .calendarBody
            visibility: visible
            opacity: 1
            transform: translateY(10px)
            +x
                transform: translateY(0px)
    .calendarBody
        z-index: 1
        position: absolute
        top: calc(100% + 18px)
        right: 0px
        // width: 300px
        padding: 16px 8px
        border-radius: 16px
        border: 1px solid transparent
        box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
        background: $n
        visibility: hidden
        opacity: 0
        transform: translateY(-10px)
        transition: all 0.3s
        z-index: 999999999999999
        +x
            top: calc(100% + 15px)
            right: 0
            border-radius: 12px
        +m
            top: 100%
            right: 16px
            left: 16px
            width: auto
            padding: 12px 16px 20px
        &:before
            content: ""
            position: absolute
            right: 10px
            bottom: 100%
            width: 20px
            height: 10px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
            +x
                right: 13px
            +m
                right: 21px
            +dark
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")