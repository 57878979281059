@import ../../../../styles/helpers

.row
    display: flex
    +t
        display: block
    .col
        flex: 0 0 calc(50% - 8px)
        width: calc(50% - 8px)
        padding-right: 8px

.wrapper
    padding: 20px 32px 0px 32px

.loader
    margin: 30px 0px

.row,
.card
    &:not(:last-child)
        margin-bottom: 8px