@import ../../../styles/helpers
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap')

.signUp
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    overflow-x: hidden
    height: 100vh
    .signUp_container_outer
        .logo
            padding: 40px 65px
            position: absolute
            z-index: 2 !important
            display: block
            width : 100%
        .signUp_container_inner
            display: flex
            flex-direction: row
            height: 100vh
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            .Branding
                flex: 1
                padding: 20px 0px
                height: 100vh
                position: relative
                .animation
                    position: absolute
                    left : 50%
                    top: 45%
                    transform: translate(-50%, -50%)
                    .lottieAnimation
                        transform: translate3d(100px, 0px, 0px) !important
                .halfCircle
                    position: absolute
                    top : 50%
                    transform: translateY(-50%)
                    z-index: 2 !important
                .crossFilled
                    position: absolute
                    top : 60%
                    left : 15%
                    z-index: 2
                .fullCircle
                    position: absolute
                    top : 90%
                    left : 30%
                    z-index: 2
                .hollowCircle
                    position: absolute
                    top : 8%
                    left : 90%
                    z-index: 2
            .form
                width: 100%
                flex: 1
                padding: 20px 0px
                justify-content: center
                position: relative
                .hollowCross
                    position: absolute
                    top: 40%
                    right: -2%
                    transform: translateY(-40%)
                .signUpForm
                    position: absolute
                    top: 50%
                    left : 40%
                    transform: translate(-50%, -40%)
                    .fullCircle
                        position: absolute
                        top : -15%
                    .filledCross
                        position: absolute
                        top: 89%
                        right: -37%
                    .signUpFormContainer
                        width: 320px
                        .formTitle
                            font-weight: 600
                            font-size: 48px
                            line-height: 48px
                            letter-spacing: -0.03em
                            margin : 0px 0px 32px 0px
                        .rules
                            font-size: 13px
                            .passwordRule
                                .ruleGroup
                                    margin-top: 5px
                                    // display: flex
                                    // align-items: center
                                    // justify-content: space-between
                                    // flex-wrap: wrap
                                    .rule
                                        margin-top: 5px
                                        .error
                                            fill: #F44336
                                            margin-right: 5px
                                        .success
                                            margin-right: 5px
                                            fill: #4CAF50
                                    .ruleFull
                                        margin-top: 5px
                                        .error
                                            margin-right: 5px
                                            fill: #F44336
                                        .success
                                            margin-right: 5px
                                            fill: #4CAF50
                        .formFiedls
                            margin : 0px 0px 20px 0px
                            .field
                                backgound : #9A9FA5
                            .variants
                                display : flex
                                margin : 20px 0px 20px 0px
                                .radio
                                    margin : 0px 30px 0px 0px
                        .signUpButton
                            margin : 12px 0px 20px 0px
                            .Save
                                width : 100%
                                padding : 12px 24px
                                font-weight : 700
                                background : #2A85FF 
                                color : #fff
                                border-radius : 12px
                                &:hover
                                    background: darken($p1, 10)
                        .signUpAlt
                            margin : 0px 0px 20px 0px
                            font-size : 14px
                        .signUpGoogle
                            cursor : pointer
                            img
                                width : 90%
                                height: 48px 
                        .message
                            font-weight : 500
                            padding : 20px 0px 0px 0px
                            .links
                                color : #000
                                font-weight : bold

// .signUp
//     font-family: 'Inter', sans-serif
//     background: #FFFFFF
//     overflow-x: hidden
//     width: 100vw
//     height: 100vh
//     .signUp_container_outer
//         .signUp_container_inner
//             display: flex
//             justify-content: space-evenly
//             align-items: center
//             .specterBranding
//                 position: relative
//                 .animation
                    // .lottieAnimation
                    //     transform: translate3d(50px, -50px, 0px) !important
//                 .logo
//                     position: absolute
//                     left : -15%
//                     top : 30px
//                     z-index: 2
//             .halfCircle
                // position: absolute
                // top : 15%
                // left : 0%
                // z-index: 1
//             .crossFilled
//                 position: absolute
//                 bottom : 28%
//                 left : 8%
//                 z-index: 1
//             .fullCircle
//                 position: absolute
//                 bottom : 5%
//                 left : 20%
//                 z-index: 1
//             .hollowCircle
//                 position: absolute
//                 top : -8%
//                 left : 50%
//                 z-index: 1
//             .form
//                 position: relative
//                 .signUpForm
//                     .filledCross
//                         position: absolute
//                         bottom: 1%
//                         right: -30%
//                     .hollowCross
//                         position: absolute
//                         top: 50%
//                         right: -47%
//                     .signUpFormContainer
//                         width: 320px
//                         .formTitle
//                             font-weight: 600
//                             font-size: 48px
//                             line-height: 48px
//                             letter-spacing: -0.03em
//                             margin : 0px 0px 32px 0px
//                         .formFiedls
//                             margin : 0px 0px 20px 0px
//                             .field
//                                 backgound : #9A9FA5
//                             .variants
//                                 display : flex
//                                 margin : 20px 0px 20px 0px
//                                 .radio
//                                     margin : 0px 30px 0px 0px
//                         .signUpButton
//                             margin : 12px 0px 20px 0px
//                             .Save
//                                 width : 100%
//                                 padding : 12px 24px
//                                 font-weight : 700
//                                 background : #2A85FF 
//                                 color : #fff
//                                 border-radius : 12px
//                         .signUpAlt
//                             margin : 0px 0px 20px 0px
//                             font-size : 14px
//                         .signUpGoogle
//                             cursor : pointer
//                             img
//                                 width : 90%
//                                 height: 48px 
//                         .message
//                             font-weight : 500
//                             padding : 20px 0px 0px 0px
//                             .links
//                                 color : #000
//                                 font-weight : bold


// .signUp
//     font-family: 'Inter', sans-serif
//     background-image: url("../../../media/images/SignUp.png")
//     background-repeat: no-repeat
//     background-size : cover
//     background-position : center
//     height : 100vh
//     position : relative
//     .signUp_container_outer
//         position : absolute
//         max-width : 475px
//         bottom : 2%
//         right : 7%
//         transform : translate(-7%, 2%)
//         +x
//             bottom : 0%
//             right : 5%
//             transform : translate(-7%, 0%)
//         +d
//             max-width : 320px
//             bottom : 0%
//             right : 5%
//             transform : translate(0%, 0%)
//         .signUpForm
//             padding : 15px 0px
//             .signUpFormContainer
//                 .formTitle
//                     font-weight: 600
//                     font-size: 42px
//                     line-height: 48px
//                     letter-spacing: -0.03em
//                     margin : 0px 0px 20px 0px
//                 .formFiedls
//                     margin : 0px 0px 20px 0px
//                     .field
//                         backgound : #F4F4F4
//                     .variants
//                         display : flex
//                         margin : 20px 0px 20px 0px
//                         .radio
//                             margin : 0px 30px 0px 0px
//                 .signUpButton
//                     margin : 0px 0px 20px 0px
//                     .Save
//                         width : 100%
//                         padding : 12px 20px
//                         font-weight : 700
//                         background : #2A85FF 
//                         color : #fff
//                         border-radius : 12px
//                 .signUpAlt
//                     margin : 0px 0px 20px 0px
//                     font-size : 14px
//                 .signUpGoogle
//                     cursor : pointer
//                     img
//                         width : 100%    
//                 .message
//                     font-weight : 500
//                     padding : 10px 0px 0px 0px
//                     .links
//                         color : #000
//                         font-weight : bold

// .errors 
//     color : red