@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 10px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 80px
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        padding-right: 30px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(2))
        +t
            display: none
    .source
        width: 100px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    .ids
        width: 60px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
    .currency
        display: inline-block
        background: $Currency-badge-bg
        color: $Currency-badge-color
        font-size: $table-content
        font-weight: 600
        text-align: center
        border-radius: 4px
        width: 80px
        padding: 2px 0px
    .item
        display: inline-block
        background: $Real-badge-bg
        color: $Real-badge-color
        font-size: $table-content
        font-weight: 600
        text-align: center
        border-radius: 4px
        width: 80px
        padding: 2px 0px
    .bundle
        display: inline-block
        background: $IAP-badge-bg
        color: $IAP-badge-color
        font-size: $table-content
        font-weight: 600
        text-align: center
        border-radius: 4px
        width: 80px
        padding: 2px 0px
    .success
        color : #83BF6E
    .warning
        color : #F2C94C
    .danger
         color : #EB5757
    .itemName
        width: 100px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        line-height: 16px


.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1

    .preview
        display: flex
        width: 40px
        height: 40px
        background : #EFEFEF
        margin-right: 20px
        border-radius: 50%
        position : relative
        +dark
            background: rgba(167, 170, 247, 0.15)
        +t
            width: 20px
            height: 20px
        img
            width: 40px
            height: 40px
            border: 2px solid #EFEFEF
            border-radius: 50%
            position : absolute
            top : 50%
            left : 50%
            transform : translate(-50%, -50%)
    .playerDetails
        display: inline-block

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none

.controlDiv
    margin-top : 10px