@import '../../styles/helpers'

.custom
    position: relative
    width: 100%
    &.active
        .tooltipContainer
            visibility: visible
            opacity: 1
            transform: translateY(10px)
            +x
                transform: translateY(0px)
    .children
        max-width: 200px
        width: 100%
        display: flex
        align-items: center
        justify-content: left
        gap: 7px
    
        
.tooltipContainer
    z-index: 1
    position: absolute
    top: calc(100% + 18px)
    left: 0px
    width: 200px
    padding: 12px 12px 5px 12px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all 0.3s
    +x
        top: calc(100% + 15px)
        left: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        left: 10px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +x
            right: 13px
        +m
            right: 21px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")
    .sortingOrder
        display: flex
        align-items: center
        gap: 15px
        .sortBy
            display: flex
            font-size: 10px
            font-weight: 700
            gap: 5px
            cursor: pointer
            color: #6F767E
            .icon
                svg
                    width: 16px
                    height: 16px
                    path
                        fill: #6F767E
            &.active
                color: #1A1D1F
                .icon
                    svg
                        path
                            fill: #1A1D1F !important
    .seperator
        margin: 12px 0px 5px 0px
        width: 100%
        height: 1px
        background: #EFEFEF

.radioText
    margin: 10px 0px