@import ../../../../../../../styles/helpers

.control
    display: flex
    flex-direction: column
    align-items: left
    .status
        padding: 0px 0px 5px 0px
        .grey
            width: 88px
            text-align: center
            background: #EFEFEF
            color: #6F767E
            border-radius: 4px
        .green
            width: 88px
            text-align: center
            background: $Virtual-badge-bg
            color: $Virtual-badge-color
            border-radius: 4px
        .purple
            width: 88px
            text-align: center
            background: $IAP-badge-bg
            color: $IAP-badge-color
            border-radius: 4px
        .blue
            width: 88px
            text-align: center
            background: $Real-badge-bg
            color: $Real-badge-color
            border-radius: 4px
        .red
            width: 88px
            text-align: center
            background: #FCC2C9
            color: #FF0000
            border-radius: 4px
        .yellow
            width: 88px
            text-align: center
            background: #FFEBC5
            color: #FFA800
            border-radius: 4px
        .orange
            width: 88px
            text-align: center
            background: #FFD8D2
            color: #FF6A55
            border-radius: 4px
    .leaderboardActions
        .date
            font-size: 12px
            color: #6F767E
            line-height: 12px
        .iconContainer
            width: 88px
            display: flex
            align-items: center
            justify-content: center
            gap: 16px
            .icons
                cursor: pointer
                &.disabled
                    cursor: default
                    opacity: 0.4