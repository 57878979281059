@import ../../../../styles/helpers

.container
    margin: 50px 30px 0px 30px

.wrapper
    margin-bottom: 10px

.head
    display: flex
    align-items: center
    justify-content: space-between
    .title
        display: flex
        align-items: center
        .block
            width: 12px
            height: 28px
            border-radius: 4px
            background: #FFBC99
        .name
            display: flex
            align-items: center
            margin-left: 8px
            .leaderboardName
                min-width: 75px
                max-width: 200px
                text-overflow: ellipsis
                overflow: hidden
                white-space: nowrap
    .calendar
        flex: 1

.grey
    width: 88px
    text-align: center
    background: #EFEFEF
    color: #6F767E
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.green
    width: 88px
    text-align: center
    background: $Virtual-badge-bg
    color: $Virtual-badge-color
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.purple
    width: 88px
    text-align: center
    background: $IAP-badge-bg
    color: $IAP-badge-color
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.blue
    width: 88px
    text-align: center
    background: $Real-badge-bg
    color: $Real-badge-color
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.red
    width: 88px
    text-align: center
    background: #FCC2C9
    color: #FF0000
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.yellow
    width: 88px
    text-align: center
    background: #FFEBC5
    color: #FFA800
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.orange
    width: 88px
    text-align: center
    background: #FFD8D2
    color: #FF6A55
    border-radius: 4px
    font-size:  12px
    margin-left: 6px

.pagination
    display : flex
    align-items : center
    justify-content : center
    gap : 25px
    margin : 10px 0px 0px 0px
    .icons
        .previous, .next
            margin: 0px 5px
            padding : 5px
            border : 2px solid #EFEFEF
            border-radius : 50%
            cursor : pointer