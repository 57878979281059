@import ../../../../styles/helpers

.loading
    margin: 50px 0px
    .loader
        margin: 0px auto

.getAppWrapper
    padding: 10px 
    overflow-x: hidden
    .profileWrapperOuter
        position: relative
        overflow: hidden !important
        margin-bottom: 20px
        .gap
            padding: 8px 0px
        .createApp
            margin : 0px 0px 30px 0px
        .profileCard
            margin-bottom: 10px
        .profileWrapper
            .circleHalf
                position: absolute
                top: -95%
                left: 35%
                transform: translate(-35%, 0%)
            .crossHalf
                width: 55px
                height: auto
                position: absolute
                bottom: -30%
                right: -2%
                transform: translate(0%, 0%)
            .cross
                position: absolute
                top: 25%
                right: 25%
                transform: translate(-25%, -25%)
                width: 20px !important
                height: 20px !important
            .circle
                position: absolute
                bottom: -30%
                left: 55%
                transform: translate(-55%, 0%)
                width: 40px !important
                height: 40px !important
            .profileOverview
                display: flex
                align-items: center
                justify-content: space-between
                .profile
                    display: flex
                    align-items: center
                    gap : 32px
                    .profileImage
                        background: rgb(249, 198, 38)
                        width: 48px
                        height: 48px
                        border: 2px solid #EFEFEF
                        border-radius: 50%
                        overflow: hidden
                        img
                            width: 44px
                            height: 44px
                            border-radius: 50%
                            object-fit: cover
                    .profileIcon
                        position: relative
                        width: 48px
                        height: 48px
                        border: 2px solid #EFEFEF
                        border-radius: 50%
                        -webkit-tap-highlight-color: rgba(0,0,0,0)
                        font-size: 15px
                        font-weight: bold
                        background: rgb(249, 198, 38)
                        letter-spacing: 1px
                        cursor: pointer
                        .user
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)
                .userName
                    .greeting
                        color: #272B30
                        font-family: Inter
                        font-size: 15px
                        font-style: normal
                        font-weight: 600
                        line-height: 24px
                    .name
                        color: #272B30
                        font-family: Inter
                        font-size: 15px
                        font-style: normal
                        font-weight: 500
                        line-height: 24px
                .manual
                    .save
                        background: #2A85FF
                        border-radius: 12px
                        font-weight: 700
                        font-size: 13px
                        line-height: 24px
                        letter-spacing: -0.01em
                        color: #FCFCFC
                        padding: 12px 20px
                        margin: 0px 10px
                        border: 2px solid transparent
                        &:hover
                            background: darken($p1, 10)
    .emptyState
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        padding: 24px 0px
        gap : 32px
        .emptyStateContent
            font-size: 15px
            font-weight: 700
            line-height: 24px
        .emptyStateButton
            display: flex
            align-items: center
            .cancel
                background: #FCFCFC
                border-radius: 8px
                font-weight: 700
                font-size: 13px
                line-height: 24px
                letter-spacing: -0.01em
                color: #1A1D1F
                padding: 4px 12px
                margin: 0px 10px
                border: 2px solid #000
    .listContainer
        margin: 12px 0px 0px 0px
        .viewAll
            text-align: right
            font-size: 12px
            font-weight: 700
            margin: 12px 12px 0px
            a
                color: #4D4D4D !important
        .list
            display: flex
            justify-content: left
            flex-wrap: wrap
            margin: -14px -32px 0px 0px
            flex-grow : 1
            +a
                display: block
                margin: 0 -8px
        .message
            margin: 25px 12px 0px 

.product
    flex: 0 0 calc(33.33% - 20px)
    width: calc(33.33% - 20px)
    margin: 14px 12px 0px 0px
    +t
        flex: 0 0 calc(50% - 24px)
        width: calc(50% - 24px)
    +a
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px