@import ../../../../../../styles/helpers

.cardDescription
    margin: 16px 0px
    .preview
        display: flex
        margin-bottom: 30px
        align-items: center
        gap : 15px
        input[type="file"]
            display: none !important
        .previewImage
            width: $preview_width
            height: $preview_height
            img
                width: $preview_width
                height: $preview_height
                border-radius: 16px
                object-fit: cover
                border: 2px solid #EFEFEF
        .previewInput
            .button
                display: flex
                align-items: center
                cursor: pointer
                gap: 8px
                font-size: 12px
                padding: 8px 16px
                font-weight: 600
                background: #2A85FF 
                color: #fff
                border-radius: 8px
                height: 32px
                border: 2px solid #2A85FF
                &:hover
                    background: darken($p1, 10)
                .addIcon
                    width: 20px
                    height: 20px
        .previewCancel
            .buttonCancel
                display: inline-block
                font-weight: 600
                background: #FCFCFC 
                font-size: 12px
                color: #1A1D1F
                border-radius: 8px
                width: 90px
                height: 32px
                border: 2px solid #EFEFEF
                &:hover
                    border: 2px solid #1A1D1F

    .previewCancel
        +m
            display : none

    .createItem
        width : 100%
        margin : 30px 0px
        .labelHeading
            font-size: 14px
            margin-bottom: 8px
        .selectInput
            margin: 15px 0px
        .groupHeading
            font-size: 14px
            margin : 0px 0px 20px 0px
            span
                margin : 0px 8px 0px 0px
            .addIcon
                cursor : pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -6px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
            .lockGroup
                width : 50%
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 6px
                .selectField
                    flex: 0 0 calc(100% - 12px)
                    width: calc(100% - 12px)
                    cursor : pointer
                    margin: 0 6px 12px
                    +m
                        width: 100%
                        margin: 15px 0 0
                    option
                        font-size: 14px
                        background-color: #ffffff    
                .danger
                    margin: 0 0 0 12px
                    font-weight: bold
                    color: red
                    font-size: 12px
            .variants
                display: flex
                flex-direction: row
                margin : 0px 0px
                .checkbox
                    flex: 0 0 calc(50% - 16px)
                    width: calc(50% - 16px)
                    margin: 8px 16px 0 0
                    +m
                        flex: 0 0 calc(50% - 16px)
                        width: calc(50% - 16px)
                .radio
                    flex: 0 0 calc(50% - 16px)
                    width: calc(50% - 16px)
                    margin: 16px 64px 0 0
                    +m
                        flex: 0 0 calc(50% - 16px)
                        width: calc(50% - 16px)
        .textArea
            .textAreaField
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 12px 0 0
        
        .groupByThree
            display: flex
            flex-wrap: wrap
            margin: 0 -10px -12px -6px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(32% - 12px)
                width: calc(32% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 15px 0 0 
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(31% - 12px)
                width: calc(31% - 12px)
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    option
                        font-size: 14px
                        background-color: #ffffff                    

            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none
            .variants
                display: flex
                flex-direction: row
                margin: 8px 0px 12px 0px
                .radio
                    margin : 0px 15px 0px 0px
        
        .groupByThrees
            display: flex
            flex-wrap: wrap
            margin: 0 -25px -12px 0px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(33.33% - 12px)
                width: calc(33.33% - 12px)
                margin: 0 6px 12px 0px
                +m
                    width: 100%
                    margin: 15px 0 0
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(31% - 12px)
                width: calc(31% - 12px)
                margin: 0 6px 12px
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .variants
                display: flex
                flex-direction: row
                margin: 8px 0px 12px 0px
                .radio
                    margin: 0px 15px 0px 0px
        
        .groupByFour
            display: flex
            flex-wrap: wrap
            margin: 0 -18px -12px -6px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(25% - 25px)
                width: calc(25% - 25px)
                margin: 0 7px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(25% - 12px)
                width: calc(25% - 12px)
                margin: 0 6px 12px
                +m
                    display: block
                    width: 100%
                    margin: 15px 0 0
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    width: 100%
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none
        
        .groupByFive
            display: flex
            flex-wrap: wrap
            margin: 0 -30px -12px -6px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(20% - 25px)
                width: calc(20% - 25px)
                margin: 0 7px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                
            .currencies
                display : flex
                flex-direction : column
                flex: 0 0 calc(20% - 12px)
                width: calc(20% - 12px)
                margin: 0 6px 12px
                +m
                    display: block
                    width: 100%
                    margin: 15px 0 0
                label
                    margin-bottom: 14px
                    font-size: 14px
                    font-weight: 600
                .selectField
                    cursor : pointer
                    height : 48px
                    padding: 0 10px
                    border-radius: 12px
                    border: 2px solid transparent
                    background: #F4F4F4
                    width: 100%
                    option
                        cursor : pointer
                        font-size: 14px
                        background-color: #ffffff  
            .remove
                margin: 0 16px 12px
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    left : 25%
                    top : 60%
                    transform : translate(-60%)
                +m
                    display : none

        .multiselect
            margin : 25px 0px
            .tagInput
                cursor: text !important
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
                span
                    font-size : 15px
                    color: #33383F
                    font-weight : 700
            .selectInput
                .field
                    flex: 0 0 calc(100% - 12px)
                    width: calc(100% - 12px)
                    margin: 0 0 12px
                    +m
                        width: 100%
                        margin: 12px 0 0
        
        .itemDropdown
            display : flex
            justify-content : right
            padding : 0px 0px 0px 20px
            margin: -35px 0px 0px 0px

        .list
            display: flex
            flex-wrap: wrap
            margin: -4px -16px 32px 0
            +d
                margin-bottom: 24px

        .checkbox
            flex: 0 0 calc(33.33% - 16px)
            width: calc(33.33% - 16px)
            margin: 8px 16px 0 0
            +m
                flex: 0 0 calc(50% - 16px)
                width: calc(50% - 16px)
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 8px)
                width: calc(48% - 8px)
                margin: 0 6px 8px
                font-size: 14px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none
                
.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px