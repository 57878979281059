@import ../../../../../styles/helpers


.preview
    display : flex
    margin-bottom : 30px
    align-items : center
    gap : 15px
    .previewImage
        width : 92px
        height : 92px
    img
        width : 92px
        height : 92px
        border-radius : 32px
        object-fit: cover
        border : 2px solid #EFEFEF
        +dark
            border : 2px solid rgba(167, 170, 247, 0.35)
    .previewInput
        .button
            cursor : pointer
            font-size : 15px
            padding : 13px 20px
            font-weight : 700
            background : #2A85FF 
            color : #fff
            border-radius : 12px
            height : 48px
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            .addIcon
                margin : 0px 8px 2px 0px

.previewCanecl
    +m
        display : none

input[type="file"]
    display: none !important
        
.label-warn
    font-size: 12px
        
.description
    margin : 25px 0px 0px 0px
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px
.check
    margin: 10px 0px
    display: flex
    .checkbox
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
            
.group
    display: flex
    flex-wrap: wrap
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .dropdown
        margin: 0 6px 12px
    .podiumSystem
        margin : 0px 0px 15px 0px
        .podiumGroup
            display : flex
            flex-direction : row
            align-items : center
            gap : 15px
            .rankLabel
                .podiumLabelTxt, .podiumLabelImg
                    cursor : pointer
                    margin : 0px 5px
            .inputField
                width : 56px
                height : 26px
                background : #F4F4F4
                padding : 0px 0px 0px 10px
                border-radius : 4px
    .field, .fieldRadio
        position: relative
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            margin : 0px 0px 12px
        .label
            color: #33383F
            font-size: 14px
            margin: 0px 0px 15px 6px
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px

.groupRanking
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: left
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .fieldBlank
        flex: 0 0 calc(20% - 12px)
        width: calc(20% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRank
        flex: 0 0 calc(30% - 12px)
        width: calc(30% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .field
        flex: 0 0 calc(45% - 12px)
        width: calc(45% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRemove
        flex: 0 0 calc(5% - 12px)
        width: calc(5% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0


.textGroup
    margin: 35px 0px 0px 0px

.buttonCancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    font-size : 15px
    color : #1A1D1F
    border-radius : 12px
    height : 48px
    border: 2px solid #EFEFEF
    &:hover
        border: 2px solid #1A1D1F


.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.cardDescription
    margin: 16px 0px
    .appInformation
        margin : 20px 0px
        .multiselect
            margin : 25px 0px
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
            .tagsInput
                cursor : text !important
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                font-size: 14px
                flex: 0 0 calc(48% - 8px)
                width: calc(48% - 8px)
                margin: 0 6px 8px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none

.pdGroup
    display: flex
    flex-wrap: wrap
    margin: 32px -6px -12px
    +m
        display: block
        margin: 0
    .fields
        flex: 0 0 calc(25% - 12px)
        width: calc(25% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            margin : 0px 0px 12px 
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px


.switch
    margin-left: 12px

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px