@import ../../../../styles/helpers

.otpScreen
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    padding: 0px
    .otp_outer_container
        padding: 0px
        .otp_inner_container
            display: flex
            flex-direction: row
            height: 100%
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            .otpWrapper 
                width: 100%
                flex: 1
                padding: 0px
                justify-content: center
                position: relative
                background: #fff
                min-height: 300px
                .wrapper
                    .code
                        width: 100%
                        max-width: 411px
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                        .body
                            text-align: left
                            .title
                                font-size: 24px
                                line-height: 28px
                                font-weight: 600
                                .blueText
                                    color: #2A85FF
                            .info
                                font-size: 12px
                                line-height: 16px
                                font-weight: 600
                                margin: 10px 0px
                                font-style: italic
                                color: #adadad
            
                    
            .button
                margin-top: 15px
                width: 100%
                .loader
                    display: none
                    margin-right: 16px

.selection
    max-height: 150px
    overflow: scroll
    scrollbar-width: none
    scroll-behavior: smooth
    .loader
        margin-top: 20px
    .workSpace
        display: flex
        align-items: center
        gap: 15px
        cursor: pointer
        padding: 10px
        border-radius: 12px
        &:hover
            background: $grey_hover
        &.active
            background: rgba(42, 133, 255, 0.2)
            .name, .id
                color: rgb(42, 133, 255) !important
        .logo
            img
                width: 40px
                height: 40px
                border-radius: 8px
        .detail
            .name
                font-size: 14px
                font-weight: 600
            .id
                font-size: 10px
                font-weight: 600
                font-style: italic
                color: #adadad