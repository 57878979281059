@import '../../../../styles/helpers'

.buttons
    display: flex 
    align-items: center
    padding: 2px 5px
    border: 2px solid #EFEFEF
    border-radius: 8px
    font-size: 12px
    color: #6F767E
    cursor: pointer
    &:hover
        border-color: #1A1D1F
        color: #1A1D1F
        path
            fill: #1A1D1F
    &.active
        color: #1A1D1F
        font-weight: 600
        border-color: #1A1D1F
        .icon
            fill: #1A1D1F
    .icon
        width: 16px
        height: 16px
        margin-right: 5px
        fill: #6F767E  