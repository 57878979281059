@import ../../styles/helpers

.scheduleModal
    .scheduleContainer
        .modalHeader
            .modalHeading
                display: flex
                align-items: center
                flex: 1
                gap: 10px
                margin: 0px 0px 32px 0px
                font-weight: 600
                font-size: 20px
                line-height: 32px
                letter-spacing: -0.02em
                color: #1A1D1F
                .timeZone
                    display: block
                    margin: 0px 50px 0px auto
                    width: 250px
                .block
                    width: 16px 
                    height: 32px
                    background: #B1E5FC
                    border-radius: 4px
        .modalBody
            .modalContent
                .label
                    font-size: 14px
                    font-weight: 600
                    margin: 20px 0px 12px 0px
                    &.disabled
                        opacity: 0.6
                .group
                    display: flex
                    flex-wrap: wrap
                    margin: 15px -6px -12px
                    &.disabled
                        opacity: 0.6
                        .field
                            position: relative
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                            +m
                                width: 100%
                                margin: 12px 0 0
                            .label
                                color: #33383F
                                font-size: 14px
                                margin: 0px 0px 15px 6px
                    .field, .fieldRadio
                        position: relative
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
                        .label
                            color: #33383F
                            font-size: 14px
                            margin: 0px 0px 15px 6px
                        .radioLabel
                            color: #33383F
                            font-size: 14px
                            margin : 0px 0px 12px 6px
                        .variants
                            display: flex
                            flex-direction: row
                            margin: 8px 0px 12px 0px
                            .radio
                                margin: 0px 15px 0px 0px
                        .variantsCheckbox
                            display: flex
                            align-items: center
                            cursor: pointer
                            input 
                                cursor: pointer
                            .checkboxLabel
                                color: #33383F
                                font-size: 14px
                                margin-left: 5px
                    
        .modalFooter
            margin: 20px 0px 0px 0px

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px

.notice
    margin-top: 20px
    font-size: 15px
    font-weight: 400
    color: #000
    font-style: italic

.error
    margin-top: 20px
    font-size: 15px
    font-weight: 400
    color: red
    font-style: italic



.cardAction
    margin-top: 12px
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    height: 40px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 10px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    height: 40px
    border-radius : 12px
    width : 90px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset
