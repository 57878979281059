@import ../../../../../../styles/helpers

.control
    display: flex
    align-items: center
    z-index: 1
    cursor : pointer

.button
    display : flex
    align-items : center
    justify-content : center
    flex-shrink: 0
    width: 32px
    height: 32px
    padding : 8px
    border-radius: 50%
    background: $n1
    +d
        background: $n3
    +dark
        background: $n7
        +d
            background: $n6
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin-right: 24px