@import '../../../../../styles/helpers'

.warning
    margin-top: 20px

.dropDown
    margin-left: auto
    
.tableWrapper
    .group
        .pagination
            display : flex
            align-items : center
            justify-content : center
            gap : 25px
            margin : 20px 0px 0px 0px
            .icons
                .previous, .next
                    margin: 5px 5px
                    padding : 5px
                    border : 2px solid #EFEFEF
                    border-radius : 50%
                    cursor : pointer
        .warning
            margin-top: 20px