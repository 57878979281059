.wrapper
    height: 100vh
    position: relative
    background: #efefef
    .accept
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        .container
            background: #fff
            width: 350px
            display: flex
            align-items: center
            justify-content: center
            flex-direction: column
            padding: 30px 20px
            border-radius: 12px
            .detail
                margin: 20px 0px
                text-align: center
                font-size: 14px
                font-weight: 500
                .name
                    color: #2a85ff
                    font-size: 22px
                    line-height: 28px
                    font-weight: 600
            .logo
                width: 72px
                height: 72px
                border-radius: 50%
                overflow: hidden
                img
                    width: 72px
                    height: 72px
                    border-radius: 50%