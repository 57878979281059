[class^="button"]
  cursor: pointer
  display: inline-flex
  justify-content: center
  align-items: center
  height: 48px
  padding: 0 20px
  background: $p1
  border-radius: 12px
  +button1
  text-align: center
  color: $n1
  transition: all .2s
  &:hover
    background: darken($p1, 10)
  &.disabled
    opacity: .5
    pointer-events: none
  svg
    fill: $n1
    transition: all .2s
  &:not([class^="button-square"])
    svg
      &:first-child
        margin-right: 8px
    //  &:last-child
    //    margin-left: 8px
   
.button-stroke,
.button-stroke-red
  background: none
  box-shadow: 0 0 0 2px $n3 inset
  +dark-common
    box-shadow: 0 0 0 2px $n6 inset

.button-stroke
  color: $n7
  svg
    fill: $n4
  +dark-common
    color: $n1
  &:hover
    background: none
    box-shadow: 0 0 0 2px $n7 inset
    svg
      fill: $n7
    +dark-common
      box-shadow: 0 0 0 2px $n3 inset
      svg
        fill: $n1
  &.active
    background: $n6
    box-shadow: 0 0 0 2px $n6 inset
    svg
      fill: $n7
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-stroke-red
  background: $p3
  color: $n1
  box-shadow: none
  svg
    fill: $p3
  &:hover,
  &.active
    background: $n1
    box-shadow: 0 0 0 2px $p3 inset
    color: $p3
    svg
      fill: $n1
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed
  
.button-stroke-red-small
  height: 40px
  padding: 0 16px
  border-radius: 8px
  font-size: 13px
  background: $p3
  color: $n1
  box-shadow: none
  svg
    fill: $p3
  &:hover,
  &.active
    background: $n1
    box-shadow: 0 0 0 2px $p3 inset
    color: $p3
    svg
      fill: $n1
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-white
  box-shadow: 0 0 0 2px $n3 inset
  background: $n1
  color: $n7
  svg
    fill: $n7
  &:hover,
  &.active
    box-shadow: 0 0 0 2px $n7 inset
    background: $n
    color: $n7
    svg
      fill: $n7
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

    
.button-white-small
  height: 40px
  padding: 0 16px
  border-radius: 8px
  font-size: 13px
  box-shadow: 0 0 0 2px $n3 inset
  background: $n1
  color: $n7
  &:hover,
  &.active
    box-shadow: 0 0 0 2px $n7 inset
    background: $n
    color: $n7
    svg
      fill: $n7
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-white-lagre
  height: 48px
  padding: 0 24px
  border-radius: 8px
  font-size: 13px
  box-shadow: 0 0 0 2px $n3 inset
  background: $n1 !important
  color: $n7
  &:hover,
  &.active
    box-shadow: 0 0 0 2px $n7 inset
    background: $n
    color: $n7
    svg
      fill: $n7
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-small
  height: 36px
  padding: 0 16px
  border-radius: 8px
  font-size: 13px
  &:hover
    background: darken($p1, 10)
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed


.button-tiny
  height: 28px
  padding: 0px 8px
  border-radius: 6px
  font-size: 12px
  font-weight: 600
  &:hover
    background: darken($p1, 10)
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-cancel-tiny
  background: $n1
  height: 28px
  padding: 0px 8px
  border-radius: 6px
  font-size: 12px
  font-weight: 600
  border: 2px solid #EFEFEF
  color: #1A1D1F
  &:hover
    background: none
    border-color: #1A1D1F
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed


.button-square-stroke
  flex: 0 0 48px
  width: 48px
  height: 48px
  padding: 0
  background: none
  box-shadow: 0 0 0 2px $n3 inset
  transition: all .2s
  svg
      fill: $n4
  +dark-common
    box-shadow: 0 0 0 2px $n6 inset
  &:hover
      background: $p1
      box-shadow: 0 0 0 2px $p1 inset
      svg
          fill: $n1
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-square-stroke.button-small
  flex: 0 0 40px
  width: 40px
  height: 40px

.button-save-small
  height: 36px
  width: 72px
  background: #2A85FF 
  color: #fff
  border-radius: 8px
  font-size: 12px
  padding: 8px 16px
  font-weight: 600
  &:hover
    background: darken($p1, 10)
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed

.button-save-small-full
  height: 36px
  width: 100%
  background: #2A85FF 
  color: #fff
  border-radius: 8px
  font-size: 12px
  padding: 8px 16px
  font-weight: 600
  &:hover
    background: darken($p1, 10)
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed


.button-cancel-small
  height: 36px
  width: 72px
  background: $n1
  color: $n7
  background: #FCFCFC 
  font-size: 12px
  color: #1A1D1F
  border-radius: 8px
  font-weight: 600
  border: 2px solid #EFEFEF
  &:hover
    border-color: #1A1D1F
    background: #FCFCFC
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed


.button-cancel-small-full
  height: 36px
  width: 100%
  background: $n1
  color: $n7
  background: #FCFCFC 
  font-size: 12px
  color: #1A1D1F
  border-radius: 8px
  font-weight: 600
  border: 2px solid #EFEFEF
  &:hover
    border-color: #1A1D1F
    background: #FCFCFC
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed


.button-danger-small-full
  height: 36px
  width: 100%
  background: $n1
  color: $n7
  background: #FF6A55 
  font-size: 12px
  color: #fff
  border-radius: 8px
  font-weight: 600
  border: 2px solid #FF6A55
  &:hover
    border-color: darken(#FF6A55, 10)
    background: darken(#FF6A55, 10)
  &:disabled
    opacity: 0.5
    pointer-events: none
    cursor: not-allowed
