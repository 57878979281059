@import ../../../../../styles/helpers

.preview
    display: flex
    margin-bottom: 30px
    align-items: center
    gap : 15px
    .previewImage
        width: $preview_width
        height: $preview_height
        img
            width: $preview_width
            height: $preview_height
            border-radius: 16px
            object-fit: cover
            border: 2px solid #EFEFEF
    .previewInput
        .button
            display: flex
            align-items: center
            cursor: pointer
            gap: 8px
            font-size: 12px
            padding: 8px 16px
            font-weight: 600
            background: #2A85FF 
            color: #fff
            border-radius: 8px
            height: 32px
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            .addIcon
                width: 20px
                height: 20px
    .previewCancel
        .buttonCancel
            display: inline-block
            font-weight: 600
            background: #FCFCFC 
            font-size: 12px
            color: #1A1D1F
            border-radius: 8px
            width: 90px
            height: 32px
            border: 2px solid #EFEFEF
            &:hover
                border: 2px solid #1A1D1F

.previewCancel
    +m
        display : none

input[type="file"]
    display: none !important
        
.label-warn
    font-size: 12px
        
.description
    margin : 25px 0px 0px 0px
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.group
    display: flex
    flex-wrap: wrap
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .dropdown
        margin: 0 6px 12px
    .podiumSystem
        margin : 0px 0px 15px 0px
        .podiumGroup
            display : flex
            flex-direction : row
            align-items : center
            gap : 15px
            .rankLabel
                .podiumLabelTxt, .podiumLabelImg
                    cursor : pointer
                    margin : 0px 5px
            .inputField
                width : 56px
                height : 26px
                background : #F4F4F4
                padding : 0px 0px 0px 10px
                border-radius : 4px
    .field, .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .radioLabel
            font-size: 14px
            margin : 0px 0px 12px 6px
        .variants
            display: flex
            flex-direction: row
            margin: 8px 0px 12px 0px
            .radio
                margin : 0px 15px 0px 0px

.groupRanking
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: left
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .fieldBlank
        flex: 0 0 calc(20% - 12px)
        width: calc(20% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRank
        flex: 0 0 calc(30% - 12px)
        width: calc(30% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .field
        flex: 0 0 calc(45% - 12px)
        width: calc(45% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
    .fieldRemove
        flex: 0 0 calc(5% - 12px)
        width: calc(5% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0


.textGroup
    margin: 35px 0px 0px 0px


.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.cardDescription
    margin: 16px 0px
    .appInformation
        margin : 20px 0px
        .multiselect
            margin : 25px 0px
            .selectLabel
                margin : 10px 10px 10px 0px
                font-size : 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
            .tagsInput
                cursor : text !important
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 8px)
                font-size: 14px
                width: calc(48% - 8px)
                margin: 0 6px 8px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none


.switch
    margin-left: 12px

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px