@import ../../styles/helpers

.label
    margin-bottom: 8px
    background:#FCFCFC
    +base2
    color: $n5
    +dark
        color: $n3

.tooltip
    position: relative
    top: -1px
    svg
        fill: $shades1


.modalHeading
    display: flex
    gap: 10px
    margin: 0px 0px 32px 0px
    font-weight: 600
    font-size: 20px
    line-height: 32px
    letter-spacing: -0.02em
    color: #1A1D1F
    .block
        width: 12px 
        height: 38px
        background: #CABDFF
        border-radius: 4px


.dropdown
    position: relative
    z-index: 3
    &.active
        z-index: 10
        .head
            box-shadow: inset 0 0 0 2px $shades1
            background: $n
            +dark
                box-shadow: inset 0 0 0 2px $n5
                background: $n8
                color: $n1
            &:before
                transform: rotate(180deg)
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)
    &.small
        .head
            height: 40px
            padding: 0 40px 0 16px
            line-height: 40px
            &:before
                width: 40px
                background-size: 16px auto
        .option
            padding: 2px 16px
    

.head
    position: relative
    height: 40px
    padding: 0 40px 0 8px
    border-radius: 8px
    box-shadow: inset 0 0 0 2px $n3
    font-size: 12px
    font-weight: 600
    line-height: 40px
    color: $n7
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 36px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12.707 15.707l-.094.083a1 1 0 0 1-1.32-.083h0l-6-6-.083-.094a1 1 0 0 1 1.497-1.32h0L12 13.585l5.293-5.292.094-.083a1 1 0 0 1 1.32 1.497h0l-6 6z' fill='%236f767e'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
        transition: transform .2s
    &:hover
        box-shadow: inset 0 0 0 2px $shades1
    +dark
        box-shadow: inset 0 0 0 2px $n6
        background: none
        color: $n4
        &:hover
            box-shadow: inset 0 0 0 2px $n5

.head_error
    box-shadow: inset 0 0 0 1px #f26f5b

.selection
    display: flex
    align-items: center
    .valueContainer
        max-width: 90%
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        .pills
            background: #E4E4E4
            color: #33383F
            padding: 4px 6px
            margin: 0px 12px 0px 0px
            border-radius: 8px
            font-size: 12px
            font-weight: 600
        .remove
            margin-left: 8px

.body
    max-height: 250px
    overflow-y: scroll
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 8px
    background: $n
    box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +dark
        background: $n8
        box-shadow: inset 0 0 0 2px $n5, 0 4px 12px rgba($n8, .1)
    &.bodyUp
        top: auto
        bottom: calc(100% + 2px)
    

.option
    padding: 3px 12px
    font-size: $font_12
    color: $n4
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &:hover
        background: $grey_hover
        color: $n7
        +dark
            color: $n1
    &.selectioned
        color: $p1
        +dark
            color: $p1

.checkbox
    display: block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &.reverse
        .inner
            flex-direction: row-reverse
        .text
            padding: 0 16px 0 0

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0 
    &:checked + .inner 
        .tick
            background: $p1
            border-color: $p1
            &:before
                opacity: 1

.inner
    display: flex
    align-items: center
    &:hover
        .tick
            border-color: $p1
    .optionLabel
        margin-left: 8px  

.tick
    position: relative
    flex-shrink: 0
    width: 16px
    height: 16px
    border-radius: 4px 
    border: 2px solid $shades3
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        width: 14px
        height: 11px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        opacity: 0 
        transition: opacity .2s

.text
    flex-grow: 1
    padding-left: 16px
    font-size: 14px
    line-height: (24/14)
    font-weight: 500
    color: #33383F
    +dark
        color: $n1

.locInput
    background:#FCFCFC
    +base2
    color: $n5
    +dark
        color: $n3

.multigroup
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    // overflow-y: hidden
    .Cancel
        margin: 20px 0 0 6px
        padding: 6px 14px
        text-align:center
        font-weight: 700
        background: #FCFCFC 
        color: #1A1D1F
        border-radius: 12px
        border: 2px solid #EFEFEF
        &:hover
            box-shadow: 0 0 0 2px #1A1D1F inset

    .multiselect
        flex-grow: 1
        margin: 0 0 12px 0px
        .tagInput
            cursor: text !important
            &.disabled
                cursor: default !important
        .selectLabel
            margin: 10px 10px 10px 0px
            font-size: 14px
            display: flex
            justify-content: space-between
            color: #33383F
            +dark
                color: #EFEFEF
            .tagsWarning
                font-size: 12px
                font-weight: 500
                color: #9A9FA5
                .tagBold
                    color: #1A1D1F
                    font-size: 12px
                    font-weight: 500
            // span
            //     font-size: 14px
            //     color: #33383F
            //     font-weight: 500
        .selectInput
            .field
                flex: 0 0 calc(100% - 12px)
                width: calc(100% - 12px)
                margin: 0 0 12px
                +m
                    width: 100%
                    margin: 12px 0 0

.itemDropdown
    display : flex
    justify-content : right
    padding : 0px 0px 0px 20px
    margin: -35px 0px 0px 0px

.list
    display: flex
    flex-wrap: wrap
    margin: -4px -16px 32px 0
    +d
        margin-bottom: 24px

.checkbox
    flex: 0 0 calc(33.33% - 16px)
    width: calc(33.33% - 16px)
    margin: 1px 16px 1px 0px
    +m
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)

.tagGroup
    margin: 0 12px 24px 0
    display: flex
    fex-wrap: wrap
    .inputTag
        width:90%
    .remove
        margin: 10px 0px 10px 24px

.cardAction
    margin:12px 0 0 0 
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding: 12px 20px
    font-weight: 700
    background: #FCFCFC 
    color: #1A1D1F
    border-radius: 12px
    width: 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.manage
    padding-left: 0px
    color: #2A85FF
    font-size: 12px
    &:hover
        text-decoration: underline


.control
    display: flex
    align-items: center

.button
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0
    width: 36px
    height: 36px
    padding: 8px
    border-radius: 50%
    background: $n3
    +d
        background: $n3
    +dark
        background: $n7
        +d
            background: $n6
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1
    &:not(:last-child)
        margin: 0 24px

.scrollable
    max-height: 280px
    overflow-y: scroll