@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: 12px
    height: 48px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            input
                border : 1px solid #333
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 4px 8px
    color: $n7
    min-width: 150px
    max-width: 300px
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none

.item
    width: 100px
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.pills
    font-family: Inter
    font-size: 12px
    font-style: normal
    font-weight: 600
    line-height: 20px
    letter-spacing: -0.15px
    border-radius: 4px
    text-align: center
    width: 70px
    height: 20px
    &.green
        color: #83BF6E
        background: #EAFAE4
    &.red
        color: #FF6A55
        background: #FFBC99
    &.blue
        color: #1d39c4
        background: #adc6ff