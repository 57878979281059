@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 46px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            // .control
            //     visibility: visible
            //     opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        overflow:hidden
        min-width:84px
        max-width:84px
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:nth-child(2)
        max-width: 200px
        min-width: 200px
        overflow:hidden
    &:nth-child(3)
        max-width: 120px
        min-width: 120px
    &:nth-child(4)
        max-width: 160px
        min-width: 160px
    &:nth-child(5)
        max-width: 140px
        min-width: 140px
    &:last-child
        max-width: 64px
        min-width: 64px
        border-radius: 0 8px 8px 0
        color: $n4
        +t
            padding: 0
        &:after
            right: 12px
    &:not(:nth-child(3))
        +t
            display: none
    .id
        font-size: 12px
        color : #6F767E
    .instance
        color: #6F767E
        font-size: 12px
        font-weight: 500
    .infinity
        position: relative
        .icon
            font-size: 16px
            position: absolute
            top: -6px
            left: 3px

.item
    display: flex
    align-items: center
    cursor: pointer
    transition: color .2s
    +m
        align-items: center
    +dark
        color: $n1
    &:hover
        color: $p1
    .details,
    .price,
    .product,
    .empty,
    .date
        display: none
    .details,
    .price,
    .empty,
    .product
        +t
            margin : 0px 5px 0px 0px
            text-align : left
            display: inline-block
    .date
        +t
            display: flex
            align-items: center
            margin-bottom: 8px
            +caption1
            color: $shades1
            svg
                margin-right: 8px
                fill: $shades1
.sourceName
    line-height: 24px
    overflow: hidden
    width: 100px
    text-overflow: ellipsis
    white-space: nowrap

.itemName
    font-weight: 600
    width: 200px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    color: #1A1F1D
    font-size: $table-content
    line-height: 16px

.itemId
    font-size: $table-grey-content
    font-weight: 500
    width: 200px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    color: #808191

.preview
    flex-shrink: 0
    width: $icon-width
    height: $icon-height
    margin-right: 20px
    border-radius: 8px
    position : relative
    +t
        width: $icon-width
        height: $icon-height
    &.bg
        background: #EFEFEF
    img
        width: $icon-width
        height: $icon-height
        border: 2px solid #EFEFEF
        border-radius: 8px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        object-fit: cover

.product
    max-width: 170px
    margin-bottom: 4px
    font-weight: 700
    +t
        max-width: 100%
        margin-bottom: 8px
        padding-right: 44px
        +title2

.link
    +caption1
    color: $shades1
    +t
        display: none

.control
    padding-left: 50px
    transition: all .2s