@import '../../../../../styles/helpers'

.group
    margin: 0px 0px 0px 0px
    .playerInformation
        .preview
            .primaryInfo
                display: flex
                gap : 20px
                align-items: center
                .profile
                    width: 112px
                    height: 112px
                    // background : #EFEFEF
                    // border-radius: 50%
                    // position : relative
                    // +dark
                    //     background: rgba(167, 170, 247, 0.15)
                    +t
                        width: 80px
                        height: 80px
                    img
                        width: 112px
                        height: 112px
                        // border: 2px solid #EFEFEF
                        // border-radius: 50%
                        // position : absolute
                        // top : 50%
                        // left : 50%
                        // transform : translate(-50%, -50%)
                .otherInfo
                    flex-grow: 1
                    .playerName
                        .userName
                            font-size: 32px
                            font-weight: 600
                            line-height: 40px
                            letter-spacing: -0.03em
                            display: flex
                            align-items: center
                            .profileInput
                                width: 200px
                                margin-bottom: 10px
                            .Save
                                margin: 10px
                                padding : 7px 10px
                                font-weight : 700
                                background : #2A85FF 
                                color : #fff
                                border-radius : 8px
                                &:hover
                                    background: darken($p1, 10)
                            .editIcon
                                margin-left: 10px
                                margin-bottom: 10px
                                cursor: pointer
                    .secondaryInfo
                        display: flex
                        justify-content: space-between
                        .playerId
                            flex-grow: 3
                            .ids
                                .label
                                    color : #1A1D1F
                                    font-size: 15px
                                    font-weight: 600
                                    line-height: 24px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color : #6F767E
                                    font-size: 15px
                                    font-weight: 600
                                    line-height: 24px
                                    letter-spacing: -0.01em
                                    .copyToClipboard
                                        cursor: pointer
                                        margin-left: 5px
                        .playerLocation
                            flex-grow: 1
                            .ids
                                .label
                                    color : #1A1D1F
                                    font-size: 15px
                                    font-weight: 600
                                    line-height: 24px
                                    letter-spacing: -0.01em
                                .UIDNO, .UUIDNO
                                    color : #6F767E
                                    font-size: 15px
                                    font-weight: 600
                                    line-height: 24px
                                    letter-spacing: -0.01em
    .toggle
        display: flex
        flex-wrap: wrap
        margin-left: auto
        margin-top: 40px
        background: #FCFCFC
        border-radius: 8px
        padding: 5px
        .link
            font-weight: 600
            color : #6F767E
            flex-shrink: 0
            width: 12%
            height: 40px
            border-radius: 8px
            p
                transition: fill .2s
            &:hover
                p
                    font-weight: 600
            &.active
                color : #1A1D1F
                background: #EFEFEF
                font-weight: 600
                line-height: 24px

            &:not(:last-child)
                margin-right: 0px
