@import ../../../styles/helpers

.header
    position: fixed
    top: 80px
    left: 280px
    right: 0
    z-index: 10
    background: $n1
    // box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    &.progress
        pointer-events: none
        cursor: not-allowed
        opacity: 0.5
    +x
        left: 280px
    +d
        left: 96px

.list
    display: flex
    align-items: center
    gap: 15px
    height: 40px
    padding: 0px 16px
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3

.item
    position: relative
    cursor: pointer
    display: flex
    align-items: center
    margin: 0px 8px
    max-width: 140px
    height: 100%
    padding: 0 8px
    font-size: 13px
    font-weight: 500
    white-space: nowrap
    color: $n4
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        color: $custom-active-link
        &:after
            content: ''
            position: absolute
            bottom: 0
            left: 8px
            width: calc(100% - 16px)
            height: 2px
            background-color: $custom-active-link
            animation: progress 1s normal forwards ease-in
            animation-timing-function: linear

.warning
    display: flex
    align-items: center
    gap: 8px
    height: 40px
    padding: 0px 30px
    font-size: $font_12
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    .label
        .text
            color: #2A85ff
            font-weight: 600
    .icon
        cursor: pointer
        background: #2A85FF
        border-radius: 50%
        text-align: center
        cursor: pointer
        width: 22px
        height: 22px
        svg
            fill: #fff


@keyframes progress
    0%
        width: 0%
    20%
        width: calc(100% - 110px)
    40%
        width: calc(100% - 75px)
    60%
        width: calc(100% - 40px)
    80%
        width: calc(100% - 16px)
    100%