@import ../../../../../styles/helpers

.product
    background : $n
    border-radius : 20px
    cursor : pointer
    &:hover
        .control
            visibility: visible
            opacity: 1
    &.active
        .checkbox
            visibility: visible
            opacity: 1
        .preview
            &:before
                border-color: $p1

.preview:after,
.control,
.checkbox
    opacity: 1
    visibility: visible
    transition: all .2s

.preview
    position: relative
    height: 200px
    margin-bottom: 16px
    border-radius: 12px
    overflow: hidden
    &:after,
    &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        transition: all .2s
    &:after
        background: rgba($n8, .8)
    &:before
        z-index: 2
        border: 2px solid transparent
        border-radius: 12px
        pointer-events: none
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
    .checkbox
        position: absolute
        top: 12px
        left: 12px
        z-index: 3
        +t
            display: none 
        .checkboxTick
            border-color: $p1

.control
    visibility: hidden


.line
    display: flex
    align-items: flex-start

.title
    margin-right: auto

.price,
.empty
    flex-shrink: 0
    display: inline-block
    margin-left: 32px
    padding: 0 8px
    border-radius: 6px
    line-height: 32px
    font-weight: 700

.price
    color: #83BF6E
    background: #EAFAE4
    font-family: Inter
    font-size: 15px
    font-style: normal
    font-weight: 600
    line-height: 24px
    letter-spacing: -0.15px
    border-radius : 4px
    text-align : center
    width: 70px
    height: 25px
    +dark
        color: $n7

.empty
    background: $n3
    +dark
        background: $n6
        color: $n1

.date
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    color: $n4
    +m
        margin-top: 2px
    svg
        margin-right: 8px
        fill: $n4

.rating
    display: flex
    align-items: center
    margin-top: 8px
    +base2
    +m
        margin-top: 4px
    svg
        margin-right: 8px
        fill: #FFC554
    span
        margin-left: 4px
        color: $n4

.ratingEmpty
    color: $n4
    svg
        fill: $n4

.card
    border-radius : 20px
    padding : 16px

.cardContainer
    display : flex
    justify-content : left
    align-items : top
    gap : 20px
    .cardItemImage
        width : 100px
        height : 100px
        img
            width : 100%
            height : 100%
            border : 2px solid #EFEFEF
            border-radius : 8px 
            background : #EFEFEF   
    

.itemContent
    margin : 0px 0px 15px 0px

.itemName 
    font-family: Inter
    font-size: 20px
    font-style: normal
    font-weight: 600
    line-height: 32px
    letter-spacing: -0.4px
    margin : -5px 0px 5px 0px

.itemId
    font-family: Inter
    font-size: 13px
    font-style: normal
    font-weight: 600
    line-height: 16px
    letter-spacing: -0.13px
    margin : 5px 0px
    color : #9A9FA5

.itemType
    font-style: normal
    font-weight: 600
    font-size: 15px
    line-height: 24px
    margin : 5px 0px