@import ../../../../styles/helpers

.filterModal
    padding: 0px 0px
    .filterContainer
        .filterHead
            .modalHeading
                display: flex
                flex: 1
                gap: 10px
                font-weight: 600
                font-size: 16px
                line-height: 24px
                letter-spacing: -0.02em
                color: #1A1D1F
                .block
                    width: 12px 
                    height: 28px
                    background: #CABDFF
                    border-radius: 4px
        .filterBody
            padding: 20px 0px
            min-height: 320px
            .seperator
                margin: 0px 0px 20px 0px
                width: 100%
                height: 1px
                background: #EFEFEF
            .bodyContainer
                display: flex !important
                .filterList
                    flex: 0 0 calc(35% - 12px)
                    width: calc(35% - 12px)
                    border-right: 0.1em solid #EFEFEF
                    .filterItem
                        margin: 16px 0px 0px 0px
                        padding-right: 12px
                        cursor: pointer
                        display: flex
                        flex: 1
                        align-items: center
                        justify-content: space-between
                        &:first-child
                            margin-top: 0px
                        &.active
                        .filterText
                            color: #1A1D1F
                            padding-left: 8px
                            font-size: 14px
                            color: #6F767E
                            line-height: 20px
                            font-weight: 500
                            .countPill
                                display: inline-block
                                text-align: center
                                margin-left: 20px
                                background: #FFBC99
                                font-size: 12px
                                font-weight: 600
                                color: #1A1D1F
                                width: 20px
                                height: 22px
                                border-radius: 4px
                        .filterIcon
                            transform: rotate(270deg)
                .filterElement
                    flex: 0 0 calc(65% - 12px)
                    width: calc(65% - 12px)
                    padding-left: 24px
                    .filterContent
                        .filterCheckbox
                            margin-top: 16px
                            height: 200px
                            overflow-y: scroll
                            scroll-behavior: smooth
                            scrollbar-width: none
                            .checkbox
                                margin-bottom: 8px
                        .fieldRadio
                            .variants
                                display: flex
                                flex-direction: row
                                margin: 8px 0px 12px 0px
                                .radio
                                    margin: 0px 15px 0px 0px
                        .group
                            display: flex
                            flex-wrap: wrap
                            margin: 12px -6px -12px 0px
                            .textInput
                                width: 100%
                                margin-bottom: 20px
                            .field
                                position: relative
                                flex: 0 0 calc(100% - 12px)
                                width: calc(100% - 12px)
                                margin: 0 6px 12px
                                +m
                                    width: 100%
                                    margin: 12px 0 0
                                .label
                                    color: #33383F
                                    font-size: 14px
                                    margin: 0px 0px 15px 6px