@import ../../styles/helpers

.card
    position: relative
    padding: 24px
    margin: 0px 0px 20px 0px
    background: $n1
    border-radius: 8px
    box-shadow: 0px 0px 3px rgba(133, 133, 133, 0.3)
    &.disabled
        opacity: 0.5
        cursor: not-allowed
        pointer-events: none
    +m
        padding: 16px
    +dark
        background: $n7

.getCard
    position: relative
    padding: 16px 24px
    margin: 0px 0px 10px 0px
    background: $n1
    border-radius: 8px
    +m
        padding: 16px
    +dark
        background: $n7

.head
    display: flex
    align-items: center
    min-height: 40px
    +m
        margin-bottom: 24px

.title
    margin-right: 8px
    font-size: 16px
    +m
        line-height: 40px
