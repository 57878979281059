@import '../../../styles/helpers'

.custom
    cursor: pointer
    position: relative
    width: 100%
    &.active
        .tooltip
            visibility: visible
            opacity: 1
            transform: translateY(0px)
            +x
                transform: translateY(0px)
        
.tooltip
    width: 100%
    z-index: 5
    position: absolute
    top: calc(100% + 5px)
    left: 0px
    padding: 12px
    border-radius: 8px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.2), 0px 0px 14px -4px rgba(0, 0, 0, 0.2), 0px 32px 48px -8px rgba(0, 0, 0, 0.2)
    background: #191919
    visibility: hidden
    opacity: 0
    transform: translateY(-5px)
    transition: all 0.3s
    +x
        top: calc(100% + 5px)
        left: 0
    .result
        margin-top: 15px
        color: #cbcbcb
        background: #252525
        padding: 12px
        border-radius: 8px
        font-size: 13px
        .content
            font-weight: bold
            font-size: 13px
        .answer
            margin-top: 5px
            .copy
                margin-left: 5px
                svg
                    fill: #cbcbcb
            .action
                display: flex
                justify-content: center
                align-items: center
                gap: 10px
                margin-top: 10px
                .save
                    color: #fff
                    background: $p1
                    height: 28px
                    padding: 0px 12px
                    border-radius: 8px
                    font-size: 12px
                    font-weight: 600
                    &:hover
                        background: darken($p1, 10)
                .cancel
                    height: 28px
                    padding: 0px 12px
                    border-radius: 6px
                    font-size: 12px
                    font-weight: 600
                    border: 2px solid #EFEFEF
                    color: #EFEFEF
                    &:hover
                        background: #EFEFEF
                        color: #1A1D1F
                        border-color: #1A1D1F
                    &:disabled
                        opacity: 0.5
                        pointer-events: none
                        cursor: not-allowed
    .prompt
        background: #252525
        padding: 8px
        .title
            font-weight: bold
            font-size: 13px
            color: #cbcbcb
        .promptInput
            display: flex
            align-items: center
            margin-top: 4px
            width: 100%
            .field
                color: #fff
                background: #252525
                padding: 0px 4px
                width: 100%
                height: 24px
            .icon
                width: 24px
                height: 24px
                border-radius: 50%
                background: darken(#252525, 10)
                display: flex
                align-items: center
                justify-content: center
                &.active
                    background: #cbcbcb
                    svg
                        fill: #252525
                svg
                    fill: #cbcbcb