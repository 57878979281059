@import ../../../styles/helpers

.toggle
    width: 100%
    display: flex
    align-items: center
    justify-content: end
    
.eventContainer
    .event
        text-align: center
        .emptyState
            padding: 40px 0px
            .emptyFolder
                padding: 0px 0px 10px 0px
    .toggle
        display: flex
        margin-left: auto
        margin-top: 20px
        background: #F4F4F4
        border-radius: 8px
        padding: 5px
        .link
            flex-shrink: 0
            width: 50%
            height: 40px
            border-radius: 8px
            font-weight: bold
            p
                transition: fill .2s

            &.active
                background: $n1
                font-weight: bold
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

            &:not(:last-child)
                margin-right: 0px
    .wrapper
        margin: 10px -12px
        min-height: 220px
        max-height: 220px
        padding-top: 5px
        overflow-y: scroll
        scroll-behavior: smooth
        scrollbar-width: none
        +t
            margin: 0
        .table
            display: table
            width: 100%
            +t
                display: block
            .row
                display: table-row
                +t
                    display: none
                .col
                    position: relative
                    display: table-cell
                    padding: 0 5px 5px
                    vertical-align: middle
                    font-size: 8px !important
                    color: #6F767E
                    font-weight: 500
                    &:after
                        content: ""
                        position: absolute
                        left: 0
                        bottom: 0
                        right: 0
                        height: 1px
                        background: $n3
                        +dark
                            background: $n6
                    &:first-child
                        width: 0px !important
                        &:after
                            left: 12px
            .tableRow
                display: table-row
                transition: background .2s
                &:nth-child(even)
                    background: #F4F4F480    
                +t
                    position: relative
                    display: block
                    margin-bottom: 24px
                    padding-bottom: 24px
                .tableCol
                    position: relative
                    display: table-cell
                    vertical-align: middle
                    padding: 10px 5px
                    color: #33383F
                    font-size: 12px
                    +t
                        display: block
                        position: static
                        padding: 0   
                    .overflowText
                        width: 100%
                        max-width: 230px
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                    img
                        width: 24px
                        height: 24px
                        border-radius: 5px
                        
    .viewAll
        text-align: center
        .all
            text-decoration: underline
            font-size: 12px
            color: #2A85FF