@import ../../../styles/helpers
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap')

.signUp
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    overflow-x: hidden
    .signUp_container_outer
        .logo
            padding: 40px 65px
            position: absolute
            z-index: 2 !important
            display: block
            width : 100%
        .signUp_container_inner
            display: flex
            flex-direction: row
            height: 100vh
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            .Branding
                flex: 1
                padding: 20px 0px
                height: 100vh
                position: relative
                .animation
                    position: absolute
                    left : 50%
                    top: 45%
                    transform: translate(-50%, -50%)
                    .lottieAnimation
                        transform: translate3d(100px, 0px, 0px) !important
                .halfCircle
                    position: absolute
                    top : 50%
                    transform: translateY(-50%)
                    z-index: 2 !important
                .crossFilled
                    position: absolute
                    top : 60%
                    left : 15%
                    z-index: 2
                .fullCircle
                    position: absolute
                    top : 90%
                    left : 30%
                    z-index: 2
                .hollowCircle
                    position: absolute
                    top : 8%
                    left : 90%
                    z-index: 2
            .form
                width: 100%
                flex: 1
                padding: 20px 0px
                justify-content: center
                position: relative
                .hollowCross
                    position: absolute
                    top: 40%
                    right: -2%
                    transform: translateY(-40%)
                .signInForm
                    position: absolute
                    top: 50%
                    left : 40%
                    transform: translate(-40%, -50%)
                    .fullCircle
                        position: absolute
                        top : -20%
                    .filledCross
                        position: absolute
                        top: 89%
                        right: -37%
                    .signInFormContainer
                        width: 380px
                        .formTitle
                            .title
                                font-size: 48px
                                line-height: 48px
                                font-weight: 600
                                padding-bottom: 20px
                                .blueText
                                    color : #2A85FF !important
                            .content
                                font-size: 18px
                                color: #6F767E
                                font-weight: 600
                                line-height: 24px
                                padding-bottom: 32px
                        .formFiedls
                            margin: 0px 0px 16px 0px
                            .field
                                backgound: #9A9FA5
                            .variants
                                display: flex
                                margin: 20px 0px 20px 0px
                                .radio
                                    margin: 0px 30px 0px 0px
                        .signInButton
                            margin: 24px 0px 20px 0px
                            .Save
                                width: 100%
                                font-size: $font_12
                                padding: 8px 12px
                                font-weight: 700
                                background: #2A85FF 
                                color: #fff
                                border-radius: 8px
                                &:hover
                                    background: darken($p1, 10)
                        .signInAlt
                            margin : 0px 0px 20px 0px
                            font-size : 14px
                        // .signInGoogle
                        //     display: flex
                        //     cursor: pointer
                        //     height: 40px
                        //     justify-content: center
                        //     align-items: center
                        //     border: 2px solid #EFEFEF
                        //     padding: 12px 20px
                        //     border-radius: 12px
                        //     .icon
                        //         font-size: 14px
                        //         font-weight: 700
                        //         padding: 0px 4px 0px 0px
                        //     .text
                        //         padding: 0px 4px 0px 0px
                        .forgotPassword
                            padding : 0px 0px 16px 0px
                            color : #2A85FF
                            .links
                                color : #2A85FF
                        .logInOption
                            display : flex
                            align-items : center
                            gap : 20px
                            margin-bottom : 16px
                            .sep
                                flex-grow : 2
                                width : 80%
                                height : 1px
                                background : #EFEFEF
                            .text
                                flex-grow : 1
                        .message
                            font-size: 16px
                            color : #6F767E
                            font-weight : 500
                            padding : 20px 0px 0px 0px
                            .links
                                color : #2A85FF
                                font-weight : bold

// .signUp
//     font-family: 'Inter', sans-serif
//     background: #FFFFFF
//     overflow-x: hidden
//     width: 100vw
//     height: 100vh
//     .signUp_container_outer
//         .signUp_container_inner
//             display: flex
//             justify-content: space-evenly
//             align-items: center
//             .specterBranding
//                 position: relative
//                 .animation
                    // .lottieAnimation
                    //     transform: translate3d(50px, -50px, 0px) !important
//                 .logo
//                     position: absolute
//                     left : -15%
//                     top : 30px
//                     z-index: 2
//             .halfCircle
                // position: absolute
                // top : 15%
                // left : 0%
                // z-index: 1
//             .crossFilled
//                 position: absolute
//                 bottom : 28%
//                 left : 8%
//                 z-index: 1
//             .fullCircle
//                 position: absolute
//                 bottom : 5%
//                 left : 20%
//                 z-index: 1
//             .hollowCircle
//                 position: absolute
//                 top : -8%
//                 left : 50%
//                 z-index: 1
//             .form
//                 position: relative
//                 .signUpForm
//                     .filledCross
//                         position: absolute
//                         bottom: 1%
//                         right: -30%
//                     .hollowCross
//                         position: absolute
//                         top: 50%
//                         right: -47%
//                     .signUpFormContainer
//                         width: 320px
//                         .formTitle
//                             font-weight: 600
//                             font-size: 48px
//                             line-height: 48px
//                             letter-spacing: -0.03em
//                             margin : 0px 0px 32px 0px
//                         .formFiedls
//                             margin : 0px 0px 20px 0px
//                             .field
//                                 backgound : #9A9FA5
//                             .variants
//                                 display : flex
//                                 margin : 20px 0px 20px 0px
//                                 .radio
//                                     margin : 0px 30px 0px 0px
//                         .signUpButton
//                             margin : 12px 0px 20px 0px
//                             .Save
//                                 width : 100%
//                                 padding : 12px 24px
//                                 font-weight : 700
//                                 background : #2A85FF 
//                                 color : #fff
//                                 border-radius : 12px
//                         .signUpAlt
//                             margin : 0px 0px 20px 0px
//                             font-size : 14px
//                         .signUpGoogle
//                             cursor : pointer
//                             img
//                                 width : 90%
//                                 height: 48px 
//                         .message
//                             font-weight : 500
//                             padding : 20px 0px 0px 0px
//                             .links
//                                 color : #000
//                                 font-weight : bold


// .signUp
//     font-family: 'Inter', sans-serif
//     background-image: url("../../../media/images/SignUp.png")
//     background-repeat: no-repeat
//     background-size : cover
//     background-position : center
//     height : 100vh
//     position : relative
//     .signUp_container_outer
//         position : absolute
//         max-width : 475px
//         bottom : 2%
//         right : 7%
//         transform : translate(-7%, 2%)
//         +x
//             bottom : 0%
//             right : 5%
//             transform : translate(-7%, 0%)
//         +d
//             max-width : 320px
//             bottom : 0%
//             right : 5%
//             transform : translate(0%, 0%)
//         .signUpForm
//             padding : 15px 0px
//             .signUpFormContainer
//                 .formTitle
//                     font-weight: 600
//                     font-size: 42px
//                     line-height: 48px
//                     letter-spacing: -0.03em
//                     margin : 0px 0px 20px 0px
//                 .formFiedls
//                     margin : 0px 0px 20px 0px
//                     .field
//                         backgound : #F4F4F4
//                     .variants
//                         display : flex
//                         margin : 20px 0px 20px 0px
//                         .radio
//                             margin : 0px 30px 0px 0px
//                 .signUpButton
//                     margin : 0px 0px 20px 0px
//                     .Save
//                         width : 100%
//                         padding : 12px 20px
//                         font-weight : 700
//                         background : #2A85FF 
//                         color : #fff
//                         border-radius : 12px
//                 .signUpAlt
//                     margin : 0px 0px 20px 0px
//                     font-size : 14px
//                 .signUpGoogle
//                     cursor : pointer
//                     img
//                         width : 100%    
//                 .message
//                     font-weight : 500
//                     padding : 10px 0px 0px 0px
//                     .links
//                         color : #000
//                         font-weight : bold

// .errors 
//     color : red

// @import ../../../styles/helpers
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap')

// .signIn
//     font-family: 'Inter', sans-serif
//     background-image: url("../../../media/images/SignUp.png")
//     background-repeat: no-repeat
//     background-size : cover
//     background-position : center
//     height : 100vh
//     position : relative
//     .signIn_container_outer
//         position : absolute
//         max-width : 475px
//         bottom : 10%
//         right : 7%
//         transform : translate(-7%, 2%)
//         +x
//             bottom : 0%
//             right : 5%
//             transform : translate(-7%, 0%)
//         +d
//             max-width : 320px
//             bottom : 0%
//             right : 5%
//             transform : translate(0%, 0%)
//         .signInForm
//             padding : 15px 0px
//             .signInFormContainer
//                 .formTitle
//                     font-weight: 600
//                     font-size: 42px
//                     line-height: 48px
//                     letter-spacing: -0.03em
//                     margin : 0px 0px 20px 0px
//                 .formFiedls
//                     margin : 20px 0px
//                     .field
//                         backgound : #F4F4F4
//                     .variants
//                         display : flex
//                         margin : 20px 0px 20px 0px
//                         .radio
//                             margin : 0px 30px 0px 0px
//                 .signInButton
//                     margin : 0px 0px 20px 0px
//                     .Save
//                         width : 100%
//                         padding : 12px 20px
//                         font-weight : 700
//                         background : #2A85FF 
//                         color : #fff
//                         border-radius : 12px
//                 .signInAlt
//                     margin : 0px 0px 20px 0px
//                     font-size : 14px
//                 .signInGoogle
//                     cursor : pointer
//                     img
//                         width : 100%    
//                 .message
//                     font-weight : 500
//                     padding : 10px 0px 0px 0px
//                     .links
//                         color : #000
//                         font-weight : bold