@import ../../styles/helpers

.radio
    display: block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    .inner
        display: flex
        align-items: center
        &:hover
            .tick
                border-color: $p1
    &.disabled
        cursor: not-allowed
        .text
            color: rgba(26, 29, 31, 0.4)
            +dark
                color: $n1
        .inner
            &:hover
                .tick
                    border: 2px solid $shades3
        .input
            &:checked + .inner 
                .tick
                    border: none !important
                    background: rgba(42, 133, 255, 0.7)
                    border-color: rgba(42, 133, 255, 0.7)
                    &:before
                        opacity: 1
    &.small
        .tick
            width: 16px
            height: 16px
            &:before
                width: 8px
                height: 8px
        .text
            font-size: 12px
            padding-left: 6px
            line-height: 16px
            font-weight: 400


.input
    position: absolute
    top: 0
    left: 0
    opacity: 0 
    &:checked + .inner .tick
        background: $p1
        border-color: $p1
        &:before
            transform: translate(-50%,-50%) scale(1)


.tick
    position: relative
    flex-shrink: 0
    width: 16px
    height: 16px
    border-radius: 50% 
    border: 2px solid $shades3
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%) scale(0)
        width: 10px
        height: 10px
        border-radius: 50%
        background: $n1
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25), inset 0px -1px 2px #DEDEDE
        transition: transform .2s


.text
    flex-grow: 1
    font-size: 13px
    font-weight: 400
    padding-left: 8px
    line-height: (24/14)
    color: $n7
    +dark
        color: $n1