@import ../../../../../styles/helpers

.configure
    padding: 0px 0px
    .configureContainer
        .configHeading
            display: flex
            align-items: center
            gap: 15px
            margin: 0px 0px 16px 0px
            .headingDash
                width: 12px
                height: 28px
                background: #FFBC99
                border-radius: 5px
            .heading
                font-size: 16px
                font-weight: 600
        .configGroup
            margin: 16px 0px 0px 0px

.warning
    .warningContent
        text-align: center
        color: #1A1D1F
        margin: 10px 0px 15px
        .heading
            font-size: 18px
            font-weight: 600
        .content
            font-size: 15px
            font-weight: 600
    .btnGroup
        display: flex
        align-items: center
        justify-content: center
        gap: 10px