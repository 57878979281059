@import ../../../../../styles/helpers

.hr
    height: 1px
    width: 100%
    background: #F4F4F4
    margin: 12px 6px

.container
    .deposit
        .modalHeading
            display: flex
            flex: 1
            gap: 10px
            margin: 0px 0px 32px 0px
            font-weight: 600
            font-size: 16px
            line-height: 24px
            letter-spacing: -0.02em
            color: #1A1D1F
            .block
                width: 12px 
                height: 28px
                background: #FFBC99
                border-radius: 4px
        .modalBody
            .toggle
                display: flex
                flex-wrap: wrap
                margin-left: auto
                margin-top: 12px
                background: #F4F4F4
                border-radius: 8px
                padding: 4px
                // &.deposit
                //     background: #e3ecfb
                //     color: #091f5e
                //     .link
                //         &.active
                //             background: #091f5e
                //             color: #e3ecfb
                // &.winning
                //     background: #FFBC99
                //     color: #5a0e28
                //     .link
                //         &.active
                //             background: #5a0e28
                //             color: #FFBC99
                .link
                    font-size: 14px
                    flex-shrink: 0
                    width: 20%
                    height: 32px
                    border-radius: 8px
                    padding: 0px 4px
                    &.grid
                        width: 25%
                    p
                        transition: fill .2s
                    &:hover
                        p
                            font-weight: bold
                    &.active
                        font-weight: bold
                        background: $n1
                        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)
                    &.disabled
                        opacity: 0.5
                        pointer-events: none
                        cursor: not-allowed
                    &:not(:last-child)
                        margin-right: 0px
            .container
                min-height: 400px
                max-height: 400px
                overflow-y: scroll
                scroll-behavior: smooth
                scrollbar-width: none
                .wrapper
                    margin: 16px 4px
                    // box-shadow: 0px 0px 3px rgba(133, 133, 133, 0.3)
                    border: 1px solid rgba(133, 133, 133, 0.3)
                    border-radius: 8px
                    padding: 12px 12px 20px 12px
                    .group
                        display: flex
                        flex-wrap: wrap
                        align-items: flex-end
                        margin: 16px -6px -12px
                        .checkbox
                            margin-right: 6px
                        .checkboxLabel
                        .switch
                            margin-left: 12px
                        .info
                            margin-bottom: 5px
                            font-size: 14px
                            color: #33383f
                            font-weight: 600
                        .switchField
                            display: flex
                            align-items: center
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                        .field, .fieldRadio
                            flex: 0 0 calc(50% - 12px)
                            width: calc(50% - 12px)
                            margin: 0 6px 12px
                            .radioLabel
                                font-size: 14px
                                font-weight: 500
                                .tooltip
                                    fill: #9A9FA5
                            .variants
                                display: flex
                                flex-direction: row
                                margin: 0px 0px
                                .radio
                                    margin: 16px 64px 0 0

.modalFooter
    display: flex
    gap: 12px