@import ../../../styles/helpers

.user
    display: flex
    align-items: center
    position: relative
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    width: 28px
    height: 28px
    border: 2px solid #EFEFEF
    border-radius: 50%
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 12px
    font-weight: bold
    // background: #B1E5FC
    background: rgb(249, 198, 38)
    letter-spacing: 1px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.headHelp
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid #EFEFEF
    color: #fff
    font-weight: 500

.body
    position: absolute
    top: calc(100% + 10px)
    right: -10px
    width: 100px
    padding: 8px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(-10px)
    transition: all .2s
    &.wide
        width: 200px
    +x
        top: calc(100% + 10px)
        right: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6

.menu
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 8px
            padding-bottom: 8px
        +dark
            border-color: $n6
    
.item
    display: flex
    align-items: center
    width: 100%
    height: 28px
    padding: 0 8px
    border-radius: 8px
    font-size: 13px
    color: $n4
    transition: all .2s
    font-weight: 500
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        background: rgba(249, 198, 38, 0.7)
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n2
        +dark
            background: transparent
    &.color
        color: $p4
        svg
            fill: $p4
        +dark
            color: $p4
            svg
                fill: $p4
        &:hover
            opacity: .8