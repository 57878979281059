@import ../../styles/helpers

.header
    height: 40px
    position: fixed
    top: 40px
    left: 280px
    right: 0
    display: flex
    flex: 1
    // width: 100%
    align-items: center
    padding: 0px 32px 0px 0px
    background: $n1
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    z-index: 101
    &.wide
        left: 0px
    &.progress
        pointer-events: none
        cursor: not-allowed
        opacity: 0.5
    +x
        left: 280px
        padding: 0px 32px 0px 0px
    +d
        left: 0px
    +m
        left: 0
        padding: 0px 32px 0px 10px
    +dark
        background: $n7
        box-shadow: inset 1px 0px 0px $n8, inset 0 -1px 0px $n8

.breadcrumb
    display: flex
    align-items: center
    gap: 4px
    cursor: pointer
    margin-left: 8px
    flex: 1
    font-size: 12px
    color: #6F767E
    &.active
        .subLink
            transform: translateX(-20px)
            visibility: hidden
        .icon
            svg
                visibility: hidden
    .mainLink
        padding: 4px
        &:hover
            border-radius: 8px
            box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.15), inset -4px -4px 10px rgba(255, 255, 255, 0.7)
            transform: scale(0.98)
            transition: all 0.2s ease
        a
            cursor: pointer
            color: #6F767E
    .subLink
        color: #2a85ff
        visibility: visible
        transition: transform 0.2s
    .icon
        svg
            visibility: visible
            fill: #6F767E


.breadcrumbs
    display: flex
    align-items: center
    gap: 5px
    font-size: 13px
    color: #6F767E
    font-weight: 500
    padding-left: 20px
    .title
        margin-top: 5px
    .icon
        svg
            width: 20px
            height: 20px

.environmentWrapper
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding-left: 24px
    .options
        display: flex
        align-items: center
        gap: 20px
        .setting
            cursor: pointer
            border: 2px solid #EFEFEF
            border-radius: 50%
            width: 28px
            height: 28px
            display: flex
            align-items: center
            justify-content: center
            &:hover
                .settingIcon
                    transform: rotate(180deg)
            .settingIcon
                transition: 0.5s ease-in-out

@keyframes round 
    100%
        transform: rotate(360deg)

// @keyframes clock 
//     100%
//         transform: rotate(-360deg)

.environment
    position: relative
    min-width: 28px
    min-height: 28px
    background: #272B30
    border-radius: 8px
    cursor: pointer
    font-size: 10px
    .developLogo
        width: 16px
        height: 16px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #FFD944 !important
    .stagingLogo
        width: 16px
        height: 16px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #9747FF !important
    .productionLogo
        width: 16px
        height: 16px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #00D59C !important

.appDetail
    .appName
        font-size: 13px
        font-weight: 600
        margin-bottom: -2px
        max-width: 220px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        +x
            font-size: 12px
    .env
        color: #6F767E
        font-weight: 600
        font-size: 11px
        +x
            font-size: 10px

.organization
    display: flex
    width: 290px
    flex: 0 0 290px
    align-items: center
    gap: 15px
    padding: 6px 10px 6px 10px
    border-right: 2px solid rgba(0, 0, 0, 0.05)
    .backGround
        width: 28px
        height: 28px
        background: #ffd88d
        border-radius: 8px
        position: relative
        .txt
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            font-size: 12px
            font-weight: 700
    .organizationLogo
        width: 28px
        height: 28px
        border-radius: 8px
        img
            height: 28px
            width: 28px
            object-fit: cover
            border-radius: 8px
    .organizationName
        display: flex
        align-items: center
        flex: 1
        .arrow
            svg
                transform: rotate(90deg)
        .orgText
            flex: 1
            .name
                display: block
                width: 200px
                max-width: 200px
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap

// .organization
//     width: 290px
//     flex: 0 0 290px
//     display: flex
//     align-items: center
//     gap: 15px
//     height: 40px
//     padding: 10px 10px 10px 13px
//     box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.05)
//     +x
//         width: 290px
//         flex: 0 0 290px 
//     .backGround
//         width: 28px
//         height: 28px
//         background: #ffd88d
//         border-radius: 8px
//         position: relative
//         .txt
//             position: absolute
//             top: 50%
//             left: 50%
//             transform: translate(-50%, -50%)
//             font-size: 12px
//             font-weight: 700
//     .organizationLogo
//         width: 28px
//         height: 28px
//         border-radius: 8px
//         img
//             height: 28px
//             width: 28px
//             object-fit: cover
//             border-radius: 8px
//     .organizationName
//         position: relative
//         height: 40px
//         display: flex
//         align-items: center
//         justify-content: space-between
//         width: 100%
//         .arrow
//             height: 20px
//             position: absolute
//             top: 7px
//             right: 0
//             svg
//                 transform: rotate(90deg)
//         .orgText
//             height: 28px
//             display: flex
//             align-items: center
//             font-size: 16px
//             font-weight: 600
//             line-height: 16px
//             flex: 1
//             .name
//                 width: calc( 100% - 15%)
//                 overflow: hidden
//                 text-overflow: ellipsis
//                 white-space: nowrap
//                 font-size: 16px
//                 line-height: 28px
//         .arrow
//             margin-left: 5px


.appSetting
    display: flex
    align-items: center
    justify-content: space-between
    flex: 1
    padding-left: 24px
    .app
        display: flex
        align-items: center
        font-size: 13px
            
    .back
        display: flex
        align-items: center
        margin-left: auto
        cursor: pointer
        .title
            font-size: $font_12
        svg
            transform: rotate(180deg)
            path
                margin: 2px 0px 0px 5px