@import ../../styles/helpers

.checkbox
    display: block
    position: relative
    user-select: none 
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &.reverse
        .inner
            flex-direction: row-reverse
        .text
            padding: 0 16px 0 0
    &.disabled
        cursor: not-allowed
        .inner
            &:hover
                .tick
                    border: 2px solid $shades3
        .text
            color: rgba(26, 29, 31, 0.4)
            +dark
                color: $n1
        .input
            &:checked + .inner 
                .tick
                    border: none !important
                    background: rgba(42, 133, 255, 0.7)
                    border-color: rgba(42, 133, 255, 0.7)
                    &:before
                        opacity: 1
    &.toggle
        .text
            font-weight: 500
            color: #6F767E
        &.active
            .text
                font-weight: 500
                color: #11142D

.input
    position: absolute
    top: 0
    left: 0
    opacity: 0 
    &:checked + .inner 
        .tick, .tick_small
            background: $p1
            border-color: $p1
            &:before
                opacity: 1

.inner
    display: flex
    align-items: center
    &:hover
        .tick, .tick_small
            border-color: $p1

.tick
    position: relative
    flex-shrink: 0
    width: 16px
    height: 16px
    border-radius: 4px 
    border: 2px solid $shades3
    transition: all .2s
    &:before
        content: ""
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        width: 14px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        opacity: 0 
        transition: opacity .2s
    &.small
        width: 16px
        height: 16px
        border-radius: 4px
        &:before
            content: ""
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 12px
            height: 10px
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='12' viewBox='0 0 17 12'%3E%3Cpath d='M16.707.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0L.293 6.707A1 1 0 0 1 .735 5.02a1 1 0 0 1 .973.273l3.586 3.586a1 1 0 0 0 1.414 0L15.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfc' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
            opacity: 0 
            transition: opacity .2s

.text
    flex-grow: 1
    padding-left: 8px
    font-size: 14px
    line-height: (24/14)
    font-weight: 600
    color: $n7
    +dark
        color: $n1
