@import ../../../styles/helpers

.gamesContainer
    .games
        text-align: center
        .emptyState
            padding: 70px 0px 100px 0px
            .emptyFolder
                padding: 0px 0px 10px 0px

    .wrapper
        margin: 10px -4px 10px -12px
        +t
            margin: 0
        .table
            display: table
            width: 100%
            +t
                display: block
            .tableRow
                display: table-row
                transition: background .2s
                &:nth-child(even)
                    background: #F4F4F480    
                +t
                    position: relative
                    display: block
                    margin-bottom: 24px
                    padding-bottom: 24px
                .tableCol
                    position: relative
                    display: table-cell
                    vertical-align: middle
                    padding: 10px 5px
                    color: #33383F
                    font-size: 12px
                    &:nth-child(2)
                        width: 110px
                    +t
                        display: block
                        position: static
                        padding: 0   
                    .overflowText
                        width: 110px
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis
                    img
                        width: 24px !important
                        height: 24px !important
                        border-radius: 5px
                    .createdAt
                        text-align: right
                        color: #6F767E
                        font-size: 8px
                        font-weight: 500
                    .date
                        text-align: right
                        color: #33383F
                        font-size: 10px
                        font-weight: 600
                        
    .viewAll
        text-align: center
        margin-top: 10px
        .all
            text-decoration: underline
            font-size: 12px
            color: #2A85FF

.toggle
    display: flex
    justify-content: end
    align-items: center
    padding: 0px 0px 0px 5px
    background: #fff
    flex: 1
    .link
        flex-shrink: 0
        width: 35%
        height: 32px
        padding: 5px
        transition: fill .2s
        font-size: $font_12
        &.active
            border-bottom: 2px solid rgb(42, 133, 255)
            color: rgb(42, 133, 255)
        &:not(:last-child)
            margin-right: 0px