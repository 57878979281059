@import ../../styles/helpers

.otpScreen
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    padding: 25px 0px
    .otp_outer_container
        padding: 25px 0px
        .otp_inner_container
            display: flex
            flex-direction: row
            height: 100%
            max-height: 410px
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            .otpWrapper 
                width: 100%
                height: 310px
                flex: 1
                padding: 20px 0px
                justify-content: center
                position: relative
                background: #fff
                +m
                    max-width: 100%
                .wrapper
                    .code
                        width: 100%
                        max-width: 411px
                        position : absolute
                        top : 50%
                        left : 50%
                        transform : translate(-50%, -50%)
                        .body
                            text-align: center
                            .title
                                font-size: 40px
                                line-height: 40px
                                font-weight: 600
                                .blueText
                                    color: #2A85FF
                            .info
                                font-size: 14px
                                line-height: 24px
                                font-weight: 600
                                margin: 32px 0px
                        .resendLink
                            text-align: center
                            padding-top: 15px
                            font-size: 10px
                            line-height: 24px
                            font-weight: 600
                            .blueText
                                color: #2A85FF
                                cursor: pointer

            .fieldset
                margin: 0 -6px
                padding-bottom: 20px
                justify-content: center
                .field
                    padding: 0px 10px !important
                    .input
                        margin: 0 5px !important
                        width: 65px !important
                        height: 72px !important
                        padding: 0 5px !important
                        border-radius: 12px !important
                        border: 2px solid transparent !important
                        background: $n3 !important
                        text-align: center !important
                        +inter
                        font-size: 48px !important
                        font-weight: 600 !important
                        color: $n7 !important
                        transition: all .2s !important
                        &:focus
                            border-color: $shades1 !important
                            background: $n !important
                            &:focus
                                background: transparent !important
            .verifyAccount
                margin: 0px auto
                width: 100%
                max-width: 380px
            .socialLogin
                display: flex
                align-items: center
                justify-content: center
                gap: 20px
                margin-bottom: 12px
                margin-top: -12px
                cursor: pointer
                .socialContainer
                    display: flex
                    align-items: center
                    justify-content: space-around
                    border: 2px solid #EFEFEF
                    border-radius: 8px
                    padding: 8px 16px
                    gap : 8px
                    .socialContent
                        color: #1A1D1F
                        font-size: 13px
                        font-weight: 600
                        line-height: 24px

            .button
                width: 100%
                .loader
                    display: none
                    margin-right: 16px

            .note
                margin-top: 32px
                +body2-s
                color: $shades1

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button 
                -webkit-appearance: none !important
                margin: 0 !important

            input[type=number]
                -moz-appearance: textfield !important
