@import ../../../../styles/helpers

.personalInfo
    .formContainer
        .form
            position: absolute
            left: 20%
            top: 50%
            transform: translate(-20%, -50%)
            width: 100%
            max-width: 520px
            +x
                left: 20%
                top: 65%
                transform: translate(-20%, -65%)
            .formHeader
                .formTitle
                    font-size: 48px
                    line-height: 48px
                    font-weight: 600
                    color: #1A1D1F
                    .blueText
                        color: #2A85FF
                .formContent
                    font-size: 18px
                    line-height: 24px
                    font-weight: 600
                    color: #6F767E
                    padding: 20px 0px 40px 0px
                    +x
                        padding: 20px 0px
            .formBody
                .group
                    display: flex
                    flex-wrap: wrap
                    align-items: center
                    margin: 25px -6px -12px
                    +m
                        display: block
                        margin: 0
                    .fields
                        flex: 0 0 calc(50% - 12px)
                        width: calc(50% - 12px)
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
                    .radioLabel
                        margin: 0px 5px 0px
                        font-size: 14px
                    .variants
                        display: flex
                        flex-direction: row
                        margin: 0px 0px
                        .radio
                            margin: 12px 24px 10px 6px
                .single
                    margin: 25px -6px 0px
                    .fields
                        margin: 0 6px 12px
                        +m
                            width: 100%
                            margin: 12px 0 0
            .formFooter
                text-align: right
                margin-top: 30px
                .Next
                    padding: 10px 24px
                    font-weight: 700
                    background: #2A85FF
                    border: 2px solid #2A85FF
                    color: #fff
                    border-radius: 8px
                    &:hover
                        background: darken($p1, 10)
                .Back
                    padding: 10px 24px
                    font-weight: 700
                    background: #FCFCFC 
                    color: #fff
                    border: 2px solid #EFEFEF
                    border-radius: 8px
                    color: #1A1D1F
                    margin-right: 10px