.custom
    position: relative
    display: inline-block
    cursor: pointer
    .tooltip 
        width: 100%
        .dateContainer
            box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
            position: absolute
            z-index: 10 !important
            cursor: pointer !important
            // background-color: #272B30
            // color: #fff
            background: #FFFFFF
            color: #000
            padding: 12px 8px 12px 8px
            border-radius: 8px
            white-space: nowrap
            z-index: 9 !important
            top: 100%
            left: 50%
            transform: translateX(-50%)
            opacity: 1
            min-width: 220px
            max-width: 450px
            .timeZone
                display: flex
                justify-content: left
                align-items: center
                .greyText
                    color: #6F767E
                .internetIcon
                    height: 24px
                    svg
                        width: 16px
                        height: 16px
                        fill: #6F767E
                        path
                            fill: #6F767E
                .timeZoneTitle
                    margin-left: 4px
                    font-size: 12px
                    line-height: 12px
            .detail
                display: flex
                align-items: center
                justify-content: space-evenly
                padding: 4px 0px 0px 2px
                .date
                    padding: 2px 8px 2px 0px
                    &:last-child
                        padding: 2px 0px 2px 8px
                        border-left: 1px solid #6F767E
                    .dateTitle
                        font-size: 12px
                        line-height: 12px
                        color: #6F767E
                        font-weight: 500
                        margin-bottom: 6px
                    .dateValue
                        font-size: 12px
                        line-height: 12px
                        // color: #FCFCFC
                        font-weight: 500           