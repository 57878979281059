@import ../../../styles/helpers

.card
    width: 100%
    border-radius: 8px
    background: #F4F4F4
    padding: 5px 12px
    .cardWrapper
        display: flex
        align-content: center
        .folder
            svg
                width: 40px
                height: 40px
        .cardBody
            min-width: 100px
            max-width: calc( 100% - 180px )
            display: block
            margin: auto auto auto 0px
            .content
                display: flex
                justify-content: space-between
                align-items: center
                .label
                    width: 100%
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                    color: #1A1D1F
                    font-family: Inter
                    font-size: $font_12
                    font-style: normal
                    font-weight: 600
                    line-height: 16px
                    letter-spacing: -0.15px
                    padding: 0px 5px 0px 0px
                .copy
                    display: flex
                    cursor: pointer
                    padding: 4px 8px
            .fileContent
                margin-top: 4px
                display: flex
                align-items: center
                gap: 8px
                .fileSize
                    font-size: $font_12
                    font-weight: 600
                    color: #6F767E
                    padding-top: 2px
                .preview
                    cursor: pointer


.progressBar 
    position: relative
    height: 4px
    width: 100%
    border-radius: 8px
    background-color: #E4E4E4
    margin-top: 10px


.progress 
    height: 100%
    border-radius: 8px
    background-color: #2A85FF
    transition: width 0.4s ease

.controlIcon
    vertical-align: unset
    margin: 5px

.product
    max-width: calc(50% - 8px)