@import ../../../../../../../../styles/helpers

.cardDescription
    margin: 16px 0px
    .preview
        display : flex
        margin : 30px 0px 15px 0px
        align-items : center
        gap : 15px
        .previewImage
            width : 92px
            height : 92px
            img
                width : 92px
                height : 92px
                border-radius : 32px
                object-fit: cover
                border : 2px solid #EFEFEF
        .previewInput
            .button
                cursor : pointer
                font-size : 15px
                padding : 13px 20px
                font-weight : 700
                background : #2A85FF 
                color : #fff
                border-radius : 12px
                height : 48px
                border: 2px solid #2A85FF
                &:hover
                    background: darken($p1, 10)
                .addIcon
                    margin : 0px 8px 0px 0px
            input[type="file"]
                display: none !important
        .previewCanecl
            +m
                display : none
            .buttonCancel
                font-weight : 700
                background : #FCFCFC 
                font-size : 15px
                color : #1A1D1F
                border-radius : 12px
                width : 91px
                height : 48px
                border: 2px solid #EFEFEF
                &:hover
                    border: 2px solid #1A1D1F

    .infoGroup
        width : 100%
        margin : 30px 0px
        .labelHeading
            margin-bottom: 10px
        .selectInput
            margin: 15px 0px
        .groupHeading
            margin : 0px 0px 20px 0px
            span
                margin : 0px 8px 0px 0px
            .addIcon
                cursor : pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -16px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 32px)
                width: calc(50% - 12px)
                margin: 0 16px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
    
    .customData
        margin: 10px 0px 20px 0px
        .groupHeading
            margin: 0px 0px 20px 0px
            span
                margin: 0px 8px 0px 0px
            .addIcon
                cursor: pointer
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 25px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(48% - 12px)
                width: calc(48% - 12px)
                margin: 0 6px 12px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position : relative
                .icon
                    cursor : pointer
                    position : absolute
                    right : -45px
                    top : 50%
                    transform : translate(-45%, -70%)
                +m
                    display : none
    
    .accessEligibility
        margin : 30px 0px
        .group
            display: flex
            flex-wrap: wrap
            margin: 0 -16px -12px
            +m
                display: block
                margin: 0
            .field, .fieldRadio
                flex: 0 0 calc(50% - 32px)
                width: calc(50% - 12px)
                margin: 0 16px 12px
                +m
                    width: 100%
                    margin: 15px 0 0
                .radioLabel
                    font-size: 14px
                    margin : 0px 0px 12px 6px
                .variants
                    display: flex
                    flex-direction: row
                    margin: 8px 0px 12px 0px
                    .radio
                        width: 100px
                        margin : 0px 32px 0px 0px
                    .radioConfig
                        width: 130px
                        margin : 0px 32px 0px 0px

        .addContentModal
            .addContent
                .modalBody
                    display: flex
                    flex-wrap: wrap
                    justify-content: space-between
                    .modalContent
                        flex: 0 0 calc(100% - 5px)
                        width: calc(100% - 5px)
                        margin: 0 6px 12px
                        .modalSearch
                            .form
                                width: 100%
                        .contentBody
                            .wrap
                                overflow: hidden
                                .pagination
                                    display: flex
                                    align-items: center
                                    justify-content: center
                                    gap: 25px
                                    margin: 20px 0px 0px 0px
                                    .icons
                                        .previous, .next
                                            margin: 0px 5px
                                            padding : 5px
                                            border : 2px solid #EFEFEF
                                            border-radius : 50%
                                            cursor : pointer
                            .toggle
                                display: flex
                                flex-wrap: wrap
                                margin-left: auto
                                margin-top: 20px
                                background: #F4F4F4
                                border-radius: 8px
                                padding: 5px
                                .link
                                    flex-shrink: 0
                                    width: 25%
                                    height: 40px
                                    border-radius: 8px
                                    p
                                        transition: fill .2s
                                    &:hover
                                        p
                                            font-weight: bold

                                    &.active
                                        background: $n1
                                        font-weight: bold
                                        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

                                    &:not(:last-child)
                                        margin-right: 0px


                    .modalFilter
                        flex: 0 0 calc(25% - 5px)
                        width: calc(25% - 5px)
                        margin: 0 6px 12px
                        .filterContent
                            .filter
                                .filterHead
                                    color: var(--neutral-07, #1A1D1F)
                                    font-family: Inter
                                    font-size: 15px
                                    font-style: normal
                                    font-weight: 600
                                    line-height: 24px
                                    letter-spacing: -0.15px
                            .filterType, .filterSubType, .filterTags
                                margin: 15px 0px

    .configure
        margin: 15px 0px
        .params
            .addGroup
                margin-left: 15px
                cursor: pointer



.cardAction
    display : flex
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    font-weight : 700
    background : #2A85FF 
    color : #fff
    border-radius : 12px
    width : 76px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    font-weight : 700
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 12px
    width : 91px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left : 6px

.ml
    margin-left: 10px