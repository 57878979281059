@import '../../../../../styles/helpers'

.overview
    .tableWrapper
        .group
            display: flex
            flex-wrap: wrap
            align-items: center
            margin: 25px -6px -12px
            +m
                display: block
                margin: 0
            .field
                flex: 0 0 calc(22% - 12px)
                width: calc(22% - 12px)
                margin: 0 7px 6px
                +m
                    width: 100%
                    margin: 12px 0 0
            .fields
                flex: 0 0 calc(50% - 12px)
                width: calc(50% - 12px)
                margin: 0 7px 6px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                margin-top: -15px
                cursor: pointer
            .cardAction
                display : flex
                flex-direction: row
                align-items : center
                gap : 10px
                .Save
                    padding : 12px 20px
                    font-weight : 700
                    background : #2A85FF 
                    color : #fff
                    border-radius : 12px
                    width : 76px
                    &:disabled
                        opacity: 0.5
                    &:hover
                        background: darken($p1, 10)
                .Cancel
                    padding : 12px 20px
                    font-weight : 700
                    background : #FCFCFC 
                    color : #1A1D1F
                    border-radius : 12px
                    width : 91px
                    border: 2px solid #EFEFEF
                    &:hover
                        box-shadow: 0 0 0 2px #1A1D1F inset
            .playerData
                .playerDataIcon
                    cursor: pointer
                    margin: 0px 0px 5px 5px
                    svg
                        width: 20px
                        height: 20px