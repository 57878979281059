@import ../../../../../styles/helpers

.row
    display: table-row
    transition: background .2s
    font-size: $table-content
    height: 46px
    +t
        position: relative
        display: block
        margin-bottom: 24px
        padding-bottom: 24px
        border-bottom: 1px solid $n3
        +dark
            border-color: $n6
    &:hover
        +rmin(1260)
            background: $n2
            +dark
                background: $n6
            .control
                visibility: visible
                opacity: 1
    
.col
    position: relative
    display: table-cell
    vertical-align: middle
    padding: 5px 12px
    color: $n7
    +dark
        color: #EFEFEF
    +t
        display: block
        position: static
        padding: 0
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
        +t
            display: none
    &:first-child
        width: 280px
        border-radius: 8px 0 0 8px
        &:after
            left: 12px
    &:last-child
        width : 150px
        // padding-right: 200px
        border-radius: 0 8px 8px 0
        +t
            padding: 0
        &:after
            right: 12px
    // &:not(:nth-child(2))
    //     +t
    //         display: none
    .id
        font-size: $table-grey-content
        color: #6F767E
    .player
        display: flex
        gap: 20px
        .memberIcon
            width: 32px
            height: 32px
            img
                width: 32px
                height: 32px
        .playerDetails
            .userName
                font-size: $table-content
                line-height: 16px
                color: #33383F
                font-weight: 600
            .playerId
                font-size: $table-grey-content
                line-height: 12px
                color: #6F767E
                font-weight: 500
    .price
        color: #83BF6E
        background: #EAFAE4
        font-family: Inter
        font-size: $table-content
        font-style: normal
        font-weight: 600
        line-height: 24px
        letter-spacing: -0.15px
        border-radius: 4px
        text-align: center
        width: 70px
        height: 25px

.control
    position: absolute
    top: 50%
    left: 10px
    transform: translateY(-50%)
    visibility: hidden
    opacity: 0
    transition: all .2s
    +d
        visibility: visible
        opacity: 1
    +t
        display: none

.actions
    display: none
    +t
        display: block
        position: absolute
        top: 0
        right: 0
        z-index: 2
    .actionsHead
        +dark
            background: none