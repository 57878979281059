@import ../../../../../../../styles/helpers

.wrapper
    margin-top: 45px

.progressBar
    .progress
        .counts
            display: flex
            align-items: center
            justify-content: space-between
            flex-wrap: wrap
            .stepsWrapper
                flex: 0 0 calc(35% - 0px)
                width: calc(35% - 0px)
                &:last-child
                    flex: 0 0 calc(25% - 0px)
                    width: calc(25% - 0px)
                .steps
                    display: flex
                    align-items: center
                    font-size: 12px
                    .check
                        margin-right: 4px
                        display: none
                        &.active
                            display: block
                            svg
                                fill: #2A85FF
                    .stepDetails
                        flex: 0 0 calc(65% - 0px)
                        width: calc(65% - 0px)
                        &:last-child
                            flex: 0 0 calc(80% - 0px)
                            width: calc(80% - 0px)
                    .step
                        margin-right: 4px
                        background: #9A9FA5
                        color: #fff
                        width: 20px
                        height: 20px
                        border-radius: 50%
                        &.active
                            background: #2A85FF
                            color: #fff
                        &.disabled
                            display: none
                        .number
                            height: 100%
                            display: flex
                            align-items: center
                            justify-content: center
                    .line
                        flex: 0 0 calc(25% - 0px)
                        width: calc(25% - 0px)
                        height: 1px
                        background: #e0e0e0
                        &.active
                            background: #2A85FF
            .count
                text-align: center
                font-size: 12px
                position: relative
                background-color: #e0e0e0
                color: #2A85FF
                &.active
                    background: #2A85FF
                    color: #fff
                &.completed
                    background: darken(#2A85FF, 30)
                    color: #fff
                &:not(:last-child)
                    &:after,
                    &:before
                        content: ''
                        display: inline-block
                        height: 2px
                        width: 17px
                        position: absolute
                        right: -7px
                        background-color: #fff     
                &:before
                    z-index: 1
                    top: 2px
                    -moz-transform: rotate(60deg)
                    -ms-transform: rotate(60deg)
                    -o-transform: rotate(60deg)
                    -webkit-transform: rotate(60deg)
                    transform: rotate(60deg)
                &:after
                    z-index: 1
                    bottom: 2px
                    -moz-transform: rotate(120deg)
                    -ms-transform: rotate(120deg)
                    -o-transform: rotate(120deg)
                    -webkit-transform: rotate(120deg)
                    transform: rotate(120deg)
                &.two
                    flex: 0 0 calc(50% - 0px)
                    width: calc(50% - 0px)
                    margin: 0
                &.three
                    flex: 0 0 calc(33.33% - 0px)
                    width: calc(33.33% - 0px)
                    margin: 0
                &.four
                    flex: 0 0 calc(25% - 0px)
                    width: calc(25% - 0px)
                    margin: 0
                &.five
                    flex: 0 0 calc(20% - 0px)
                    width: calc(20% - 0px)
                    margin: 0

// .progressBar
//     position: relative
//     height: 6px
//     background-color: #e0e0e0
//     border-radius: 8px
//     .progress
//         border-radius: 8px
//         height: 100%
//         background-color: #2A85FF
//         transition: width 0.5s ease
//     .counts
//         width: 100%
//         position: absolute
//         top: 50%
//         transform: translateY(-50%)
//         display: flex
//         align-items: center
//         text-align: center
//         .count
//             &.two
//                 flex: 0 0 calc(50% - 0px)
//                 width: calc(50% - 0px)
//                 margin: 0
//             &.three
//                 flex: 0 0 calc(33.33% - 0px)
//                 width: calc(33.33% - 0px)
//                 margin: 0
//             &.four
//                 flex: 0 0 calc(25% - 0px)
//                 width: calc(25% - 0px)
//                 margin: 0
//             &.five
//                 flex: 0 0 calc(20% - 0px)
//                 width: calc(20% - 0px)
//                 margin: 0
//             .steps
//                 margin-left: auto
//                 width: 20px
//                 height: 20px
//                 font-size: 10px
//                 line-height: 20px
//                 background: #fff
//                 color: #2885ff
//                 border-radius: 50%
//                 border: 1px solid #2885ff
//                 position: relative
//                 &:before
//                     content: ""
//                     position: absolute
//                     left: 0%
//                     width: 100%
//                     height: 100%
//                     border-radius: 50%
//                     background: #2885ff
//                     z-index: 0
//                     visibility: hidden 
//                     opacity: 0
//                     transition: opacity 0.75s ease-in
//                 &.active
//                     color: #fff
//                     &:before
//                         opacity: 1
//                         visibility: visible
//                         left: 0 
//                 .step
//                     position: relative
//                     z-index: 1
//                     display: block
//                     margin: 0px auto

.preview
    display: flex
    margin-bottom: 30px
    align-items: center
    gap : 15px
    .previewImage
        width: $preview_width
        height: $preview_height
        img
            width: $preview_width
            height: $preview_height
            border-radius: 16px
            object-fit: cover
            border: 2px solid #EFEFEF
    .previewInput
        .button
            display: flex
            align-items: center
            cursor: pointer
            gap: 8px
            font-size: 12px
            padding: 8px 16px
            font-weight: 600
            background: #2A85FF 
            color: #fff
            border-radius: 8px
            height: 32px
            border: 2px solid #2A85FF
            &:hover
                background: darken($p1, 10)
            .addIcon
                width: 20px
                height: 20px
    .previewCancel
        .buttonCancel
            display: inline-block
            font-weight: 600
            background: #FCFCFC 
            font-size: 12px
            color: #1A1D1F
            border-radius: 8px
            width: 90px
            height: 32px
            border: 2px solid #EFEFEF
            &:hover
                border: 2px solid #1A1D1F

.previewCancel
    +m
        display : none

input[type="file"]
    display: none !important
        
.label-warn
    font-size: 12px
        
.description
    margin : 25px 0px 0px 0px
    & > .field
        &:not(:last-child)
            margin-bottom: 32px
            +d
                margin-bottom: 24px

.priority
    margin-top: 16px
    .title
        font-size: 14px

.editor
    &:not(:last-child)
        margin-bottom: 32px
        +d
            margin-bottom: 24px

.table
    border: 2px solid #efefef
    border-radius: 8px
    border-collapse: collapse
    margin: 12px 0px 0px 0px
    .container
        .cardContainer
            padding: 12px 0px
            font-size: 13px
            font-weight: 500
            border-bottom: 1px solid #efefef
            .name
                margin: 0px 0px 0px 16px

.wallet
    margin: 16px 0px

.group
    display: flex
    flex-wrap: wrap
    align-items: flex-end
    margin: 25px -6px -12px
    +m
        display: block
        margin: 0
    .field, .fieldRadio
        flex: 0 0 calc(50% - 12px)
        width: calc(50% - 12px)
        margin: 0 6px 12px
        +m
            width: 100%
            margin: 12px 0 0
        .variants
            display: flex
            flex-direction: row
            margin: 0px 0px
            .radio
                flex: 0 0 calc(50% - 16px)
                width: calc(50% - 16px)
                margin: 16px 64px 0 0


.cardAction
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px

.Save
    padding: 8px 10px
    font-weight: 700
    background: #2A85FF 
    color: #fff
    border-radius: 8px
    width: 72px
    border: 2px solid #2A85FF
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding: 8px 10px
    font-weight: 700
    background: #FCFCFC 
    color: #1A1D1F
    border-radius: 8px
    width: 84px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset

.cardDescription
    margin: 16px 0px
    .createCurrency
        margin: 12px 0px
        .multiselect
            margin : 25px 0px
            .tagsInput
                cursor: text !important
            .selectLabel
                margin: 10px 10px 10px 0px
                font-size: 14px
                display: flex
                justify-content: space-between
                color: #000
                +dark
                    color: #EFEFEF
                .tagsWarning
                    font-size: 13px
                    color : #9A9FA5
                    .tagBold
                        color: #1A1D1F
                        font-weight: bold
        .groupHeading
            font-size: 14px
            .addIcon
                cursor: pointer
                margin: 0px 0px 0px 10px
                svg
                    transition: 0.25s ease-in-out
                    &:hover
                        transform: rotate(180deg)
        .groupMeta
            display: flex
            flex-wrap: wrap
            margin: 12px -6px -12px
            +m
                display: block
                margin: 0
            .field
                font-size: 14px
                flex: 0 0 calc(48% - 8px)
                width: calc(48% - 8px)
                margin: 0 6px 8px
                +m
                    width: 100%
                    margin: 12px 0 0
            .remove
                position: relative
                .icon
                    cursor: pointer
                    position: absolute
                    right: -45px
                    top: 50%
                    transform: translate(-45%, -70%)
                +m
                    display: none

.tooltip
    position: relative
    top: -1px
    fill: #9A9FA5
    margin-left: 6px