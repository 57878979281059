@import ../../../styles/helpers
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap')

.forgotPassword
    font-family: 'Inter', sans-serif
    background: #FFFFFF
    overflow-x: hidden
    .forgotPassword_container_outer
        .logo
            padding: 40px 65px
            position: absolute
            z-index: 2 !important
            display: block
            width : 100%
        .forgotPassword_container_inner
            display: flex
            flex-direction: row
            height: 100vh
            +m
                flex-direction: column 
            +a
                flex-direction: column 
            +s
                flex-direction: column 
            .Branding
                flex: 1
                padding: 20px 0px
                height: 100vh
                position: relative
                .animation
                    position: absolute
                    left : 50%
                    top: 45%
                    transform: translate(-50%, -50%)
                    .lottieAnimation
                        transform: translate3d(100px, 0px, 0px) !important
                .halfCircle
                    position: absolute
                    top : 50%
                    transform: translateY(-50%)
                    z-index: 2 !important
                .crossFilled
                    position: absolute
                    top : 60%
                    left : 15%
                    z-index: 2
                .fullCircle
                    position: absolute
                    top : 90%
                    left : 30%
                    z-index: 2
                .hollowCircle
                    position: absolute
                    top : 8%
                    left : 90%
                    z-index: 2
            .form
                width: 100%
                flex: 1
                padding: 20px 0px
                justify-content: center
                position: relative
                .hollowCross
                    position: absolute
                    top: 40%
                    right: -2%
                    transform: translateY(-40%)
                .formForgotPassword
                    position: absolute
                    top: 50%
                    left : 40%
                    transform: translate(-40%, -50%)
                    +x
                        top: 50%
                        left : 40%
                        transform: translate(-40%, -50%)
                    .fullCircle
                        position: absolute
                        top : -20%
                    .filledCross
                        position: absolute
                        top: 89%
                        right: -37%
                    .forgotPasswordForm
                        width : 100%
                        max-width: 560px
                        .formTitle
                            .title
                                font-size : 48px
                                line-height : 48px
                                font-weight : 600
                                padding-bottom : 20px
                                .blueText
                                    color : #2A85FF !important
                            .content
                                font-size : 16px
                                color : #6F767E
                                font-weight : 600
                                line-height : 24px
                                padding-bottom : 40px
                        .formFiedls
                            margin : 0px 0px 28px 0px
                            .field
                                backgound : #9A9FA5
                            .variants
                                display : flex
                                margin: 8px 0px 12px 0px
                                .radio
                                    margin : 0px 30px 0px 0px
                        .forgotPassword
                            margin : 12px 0px 16px 0px
                            .Save
                                width : 100%
                                padding : 12px 24px
                                font-weight : 700
                                background : #2A85FF 
                                color : #fff
                                border-radius : 12px
                                &:hover
                                    background: darken($p1, 10)
                        .logInOption
                            display : flex
                            align-items : center
                            gap : 20px
                            margin-bottom : 16px
                            .sep
                                flex-grow : 2
                                width : 80%
                                height : 1px
                                background : #EFEFEF
                            .text
                                flex-grow : 1
                        .signInAlt
                            margin : 0px 0px 20px 0px
                            font-size : 14px
                        .signInGoogle
                            display : flex
                            cursor : pointer
                            justify-content : center
                            align-items : center
                            border : 2px solid #EFEFEF
                            padding : 12px 20px
                            border-radius : 12px
                            .icon
                                font-size : 14px
                                font-weight : 700
                                padding : 0px 4px 0px 0px
                            .text
                                padding : 0px 4px 0px 0px
                        .policy
                            padding : 28px 0px 0px 0px
                            font-size : 13px
                            .links
                                color : #2A85FF
                                font-weight : bold
                        .message
                            font-size: 16px
                            color : #6F767E
                            font-weight : 500
                            padding : 20px 0px 0px 0px
                            .links
                                color : #2A85FF
                                font-weight : bold