@import '../../../../../styles/helpers'

.wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    align-items: stretch
    // gap: 20px
    margin: 10px -6px 20px -6px
    .cardHead
        flex: calc(25% - 12px)
        width: calc(25% - 12px)
        // border-right: 2px solid #efefef
        margin: 0 6px 12px
        .title
            font-size: 16px
        .info
            color: #6F767E
            font-size: 12px    
    .list
        flex: calc(75% - 12px)
        width: calc(75% - 12px)
        margin: 0 6px 12px
        display: flex
        align-items: stretch
        justify-content: space-between      
        .cards
            flex: calc(50% - 12px)
            width: calc(50% - 12px)
            position: relative
            +xl
                flex: calc(40% - 12px)
                width: calc(40% - 12px)
            +l
                flex: calc(40% - 12px)
                width: calc(40% - 12px)
            +w
                flex: calc(50% - 12px)
                width: calc(50% - 12px)
            +x
                flex: calc(50% - 12px)
                width: calc(50% - 12px)
            .select
                position: absolute
                top: 0px
                right: 0px
            .control
                .prev
                    width: 25px
                    height: 25px
                    border-radius: 50%
                    background: #2A85FF
                    position: absolute
                    top: 50%
                    left: 20px
                    font-size: 20px
                    transform: translate(0px, -50%)rotate(90deg)
                    &.disabled
                        opacity: 0.5
                        pointer-events: none
                    svg
                        fill: #fff
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
                .next
                    width: 25px
                    height: 25px
                    border-radius: 50%
                    background: #2A85FF
                    position: absolute
                    top: 50%
                    right: 20px
                    font-size: 20px
                    transform: translate(0px, -50%)rotate(-90deg)
                    &.disabled
                        opacity: 0.5
                        pointer-events: none
                    svg
                        fill: #fff
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate(-50%, -50%)
            .card
                width: 65%
                padding: 10px 20px
                border-radius: 24px
                height: 180px
                display: block
                margin: 0px auto
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                cursor: pointer
                +xl
                    width: 48%
                +l
                    width: 65%
                +w
                    width: 65%
                +x
                    width: 75%
                &.active
                    border: 2px solid #000
                    border-style: inset
                &.dummy
                    // border: 1px solid #000
                    background-image: linear-gradient(120deg, #c4c4c4,  #e8e8e8,  #c7c7c7)
                &.rupay
                    background-color: #4158D0
                    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)
                &.visa
                    background-color: #0093E9
                    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)
                    .type, .details
                        color: #000
                &.master
                    background-color: #FAD961
                    background-image: linear-gradient(90deg, #FAD961 0%, #F76B1C 100%)
                .action
                    display: flex
                    align-items: center
                    justify-content: center
                    gap: 5px
                    height: 100%
                    cursor: pointer
                    .icon
                        width: 20px
                        height: 20px
                        font-size: 12px
                        line-height: 16px
                        font-weight: 600
                        cursor: pointer
                        background: #000
                        border-radius: 50%
                        position: relative
                        .sign
                            fill: #fff
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)
                    .text
                        font-size: 14px
                        font-weight: 600
                .type
                    text-align: right
                    img
                        width: 35px
                        height: 35px
                .chip
                    margin-top: 5px
                    img
                        width: 35px
                        height: 35px
                .details
                    width: 100%
                    .cardNo
                        text-align: center
                        margin-top: 5px
                        word-spacing: 20px
                        font-size: 20px
                    .cardDetail
                        margin-top: 8px
                        display: flex
                        align-items: center
                        justify-content: space-between
                        .name
                            word-spacing: 8px
                            text-transform: uppercase
                        .expiry
                            font-size: 10px
                            .date
                                font-style: italic
        .info
            flex: calc(50% - 12px)
            width: calc(50% - 12px)
            border-left: 2px solid #efefef
            padding: 0px 24px
            .detailInfo
                .heading
                    font-size: 16px
                    margin-bottom: 10px
                .body
                    .action
                        text-align: right
                        margin-top: 15px
                    .name, .number
                        margin-bottom: 10px
                        .label
                            font-size: 12px
                            color: grey
                            margin-bottom: 2px
                        .value
                            font-size: 16px
                            border-bottom: 1px solid #dcdcdc
                            word-spacing: 10px
                    .type
                        .group
                            display: flex
                            align-items: center
                            .field
                                flex: calc(50% - 12px)
                                width: calc(50% - 12px)
                                &:first-child
                                    margin-right: 30px
                                .label
                                    font-size: 12px
                                    color: grey
                                .value
                                    font-size: 16px
                                    border-bottom: 1px solid #dcdcdc