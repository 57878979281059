@import ../../../styles/helpers

.user
    display: flex
    align-items: center
    position: relative
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.head
    width: 48px
    height: 48px
    border: 2px solid #EFEFEF
    border-radius: 50%
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    font-size: 15px
    font-weight: bold
    background: #B1E5FC
    letter-spacing: 1px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.headActivity
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid #EFEFEF
    color: #FFFFFF
    font-weight: 500
    &:hover
        .activityIcon
            animation: beat 0.25s normal ease-in-out 5
            animation-timing-function: linear

@keyframes beat
    50%
        transform: scale(1.2)
    100%
        transform: scale(1)

.body
    position: absolute
    top: calc(100% + 10px)
    right: -20px
    width: 375px
    padding: 16px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(5px)
    transition: all .2s
    +x
        top: calc(100% + 5px)
        right: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px

.menu
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 8px
            padding-bottom: 8px
        +dark
            border-color: $n6
    
.item
    display: flex
    align-items: center
    width: 100%
    height: 48px
    padding: 0 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n2
        +dark
            background: transparent
    &.color
        color: $p4
        svg
            fill: $p4
        +dark
            color: $p4
            svg
                fill: $p4
        &:hover
            opacity: .8

.activityTable 
    width: 100%
    overflow: hidden
    .title
        padding: 8px
        background: #f4f4f4
        color: #333
        display: inline-block
        border-radius: 8px
        width: 100%
        text-align: center

.activityList 
    max-height: 260px
    list-style: none
    padding: 10px 0px 0px
    margin: 0
    overflow-y: scroll
    scroll-behavior: smooth
    scrollbar-width: none

.activityItem 
    display: flex
    align-items: center
    padding: 5px
    border-bottom: 1px solid #efefef
    cursor: pointer
    &:last-child
        border-bottom: none
    &:hover
        border-radius: 8px
        background: #F4F4F4

.iconWrapper 
  margin-right: 10px
  svg
    &.create
        fill: #4D8F65
    &.edit
        fill: #2A85FF
    &.delete
        fill: #B3281D

.activityContent 
    flex: 1
    display: flex
    flex-direction: column

.performedBy 
    align-items: center
    font-size: $font_12
    .activity
        .userdetail
            display: flex
            align-items: center
            justify-content: space-between
            .name
                font-style: italic
                font-weight: 500
                color: #6F767E
            .timeStamp
                color: #6f767e
                font-size: 10px

.description 
    font-weight: 400
    font-size: $font_12
    width: 300px
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap

.timeStamp 
    margin-left: auto
    color: #6f767e
    font-size: 10px

.cardSave
    text-align: center
    margin-top: 8px

.save
    font-size: $font_12
    display: block
    margin: 0px auto
    color: #2A85FF
    &:hover
        text-decoration: underline


.item
    display: flex
    align-items: center
    gap: 10px
    width: 100%
    padding: 2px 12px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s
    &.active
        background : #272B30
        color : #F4F4F4
        +dark
            background: $n6
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11)
    .staging
        background: $staging-bg
        width: 12px
        height: 12px
        border-radius: 50%
    .develop
        background: #9747FF
        width: 12px
        height: 12px
        border-radius: 50%
    .production
        background: $production-bg
        width: 12px
        height: 12px
        border-radius: 50%


.itemContainer
    display: flex
    align-items: center
    gap: 5px
    width: 100%
    padding: 2px
    border-radius: 8px
    font-size: 15px
    font-weight: 600
    color: $n4
    transition: background .2s, box-shadow .2s, color .2s   
    &.disabled
        opacity: 0.5
        pointer-events: none !important
        cursor: not-allowed !important
    &:not(&.disabled)
        &:hover
            background: $grey_hover
    &:not(&.disabled)
        &.active
            background: #33383F
            p
                color: #fff !important

.groupHeading
        margin-bottom: 4px
        .heading
            color: #EFEFEF
            font-weight: 500
            font-size: 12px
            line-height: 12px
.production
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #00D59C
.staging
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #9747FF
.develop
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: #FFD944
.global
    position: relative
    width: 20px
    height: 20px
    background: #33383F
    border-radius: 4px
    .logo
        width: 12px
        width: 12px
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        path
            fill: white
.appName
    cursor: pointer
    flex: 2
    .logoText
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        font-size: 15px
        font-weight: 600
        line-height: 24px
        color: #272B30
        +x
            font-size: 14px
        +d
            display: none
    .env
        font-size: 10px
        font-weight: 600
        color: #838383
        &:hover
            color: #1A1D1F