@import ../../../styles/helpers

.wrapper
    margin-bottom: 30px
    .fileUpload
        margin: 8px 0px
    .filePreview
        .fileWrapper
            display: flex
            flex-wrap: wrap
            .product
                position: relative
                flex: 0 0 calc(25% - 24px)
                width: calc(25% - 24px)
                margin: 0px 12px 0
                +t
                    flex: 0 0 calc(50% - 24px)
                    width: calc(50% - 24px)
                +a
                    width: 100%
                    margin: 0
                    &:not(:last-child)
                        margin-bottom: 32px
    .toggle
        display: flex
        margin-left: auto
        margin-top: 20px
        background: #F4F4F4
        border-radius: 8px
        padding: 5px
        .link
            flex-shrink: 0
            width: 50%
            height: 40px
            border-radius: 8px
            font-weight: bold
            p
                transition: fill .2s

            &.active
                background: $n1
                font-weight: bold
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)

            &:not(:last-child)
                margin-right: 0px

.wrapperButton  
    text-align: center 
    margin: 20px 0px 
    .button
        .loader
            transform: scale(.8)
            margin: 0 18px 0 8px
            color: #fff   
    .Save
        padding : 12px 20px
        font-weight : 700
        background : #2A85FF 
        color : #fff
        border-radius : 12px
        width : 90px
        margin: 0px 10px 0px 0px
        &:disabled
            opacity: 0.5
        &:hover
            background: darken($p1, 10)

    .Cancel
        padding : 12px 20px
        font-weight : 700
        background : #FCFCFC 
        color : #1A1D1F
        border-radius : 12px
        width : 91px
        border: 2px solid #EFEFEF
        margin: 0px 10px 0px 0px
        &:hover
            box-shadow: 0 0 0 2px #1A1D1F inset

.pagination
    display: flex
    align-items: center
    justify-content: center
    gap: 25px
    margin: 10px 0px 0px 0px
    .icons
        .previous, .next
            margin: 0px 5px
            padding: 5px
            border: 2px solid #EFEFEF
            border-radius: 50%
            cursor: pointer

.folderList
    display: flex
    flex-wrap: wrap
    margin:16px -8px -8px -8px
    .folderGroup
        color: #33383F
        cursor: pointer
        margin: 8px
        background:#F4F4F4
        padding:12px 18px
        border-radius:8px
        min-width:31.50%
        height:80px
        display:flex
        .folderDetails
            display: flex
            .titleName
                color: #33383F
                padding:4px
                margin-left:12px

.filters
    display: flex
    align-items: center
    gap: 15px
    .buttons
        padding: 4px 8px
        border: 2px solid #EFEFEF
        border-radius: 8px
        font-size: 12px
        color: #6F767E
        cursor: pointer
        .icon
            margin-right: 5px
            fill: #6F767E  

.cardAction
    display : flex
    margin-left: auto
    flex-direction: row
    align-items : center
    gap : 10px

.Save
    padding : 12px 20px
    display: flex
    align-items: center
    font-weight : 600
    background : #2A85FF 
    color : #fff
    border-radius : 8px
    width : 108px
    height: 32px
    &:disabled
        opacity: 0.5
    &:hover
        background: darken($p1, 10)

.Cancel
    padding : 12px 20px
    display: flex
    align-items: center
    font-weight : 600
    background : #FCFCFC 
    color : #1A1D1F
    border-radius : 8px
    width : 96px
    height: 32px
    border: 2px solid #EFEFEF
    &:hover
        box-shadow: 0 0 0 2px #1A1D1F inset














.cardWrapper
    padding: 10px 12px
    border: 2px solid #f4f4f4
    border-radius: 8px
    .cardHeader
        width: 300px
        height: 100px
        .progressBar 
            position: relative
            height: 4px
            width: 100%
            border-radius: 8px
            background-color: #E4E4E4
            margin-top: 10px


        .progress 
            height: 100%
            border-radius: 8px
            background-color: #2A85FF
            transition: width 0.4s ease
        .overlay
            position: absolute
            top: 0px
            left: 0px
            background: rgba(0, 0, 0, 0.7)
            visibility: hidden
            display: flex
            align-items: center
            justify-content: center
            gap: 10px
            width: 100%
            height: 100px
            border-radius: 8px
            .icon
                width: 30px
                height: 30px
                border: 2px solid #f4f4f4
                border-radius: 50%
                text-align: center
                svg
                    fill: #f4f4f4
        .cardImage
            overflow: hidden
            background: #F4F4F4
            border-radius: 8px
            width: 100%
            height: 100px
            img, video
                object-fit: contain
                border-radius: 8px
                width: 100%
                height: 100%
                object-fit: cover
    &:hover
        .overlay
            visibility: visible
    .cardBody
        display: block
        margin: 8px 0px 0px 0px
        .content
            display: flex
            justify-content: space-between
            align-items: center
            .label
                width: 80%
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
                color: #1A1D1F
                font-family: Inter
                font-size: $font_12
                font-style: normal
                font-weight: 600
                line-height: 18px
                letter-spacing: -0.15px
                padding: 0px 5px 0px 0px
            .copy
                display: flex
                cursor: pointer
                padding: 4px 8px
        .fileContent
            display: flex
            align-items: center
            gap: 5px
            .fileSize
                font-size: 10px
                font-weight: 600
                color: #6F767E
                padding-top: 2px
            .copy
                margin-left: auto
                font-size: 10px
                svg
                    margin-left: 5px
                    fill: #6F767E
            .preview
                cursor: pointer